export class AppConfig {
   
    url: string = 'https://apiteja2.bitgob.com/api/';
    criptographicEvidenceURL: string = 'https://apiteja2.bitgob.com/firmarArchivo';
    CJFCatalogosURL='https://apiteja2.bitgob.com/cjf'; 
    Noapi='https://apiteja2.bitgob.com/cjf'; 
   // url:string ='http://tjagro.test/api/';
   // criptographicEvidenceURL: string = 'http://tjagro.test/firmarArchivo';
   // CJFCatalogosURL='http://tjagro.test/cjf'; 



}