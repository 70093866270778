import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { deterNotification, ResourcesProvider, UsersNotify } from 'app/providers/resources.provider';
import { ExpedientProvider } from 'app/providers/expedient.provider';
import { UsersProvider } from "app/providers/users.provider";
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { FileAnexo, TypeFile, OrganoJurisdiccional } from 'app/main/promotions/demanda-form/options.data.form';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { startWith, map, timeout } from 'rxjs/operators';
//MODULOS FIRMA ELECTRONICA
import {SelectFileSign,message,SelectPfx,OpenPfx,PKCS7creado,extFileUpload} from 'app/main/sign/common/functions/clienteArray';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TopicsService } from 'app/main/topics/topics.service';
import { DocumentProvider } from 'app/providers/documents.register.provider';
import { Todo } from 'app/main/agreements/todo/todo.model';
import { 
    TypeDoc,
    TypeDoc2,
    typeinci

} from './options.data.form';
import { count } from 'console';
import {PartsService  } from 'app/main/parts/parts.service';
import { result } from 'lodash';
@Component({
    selector     : 'mail-compose',
    templateUrl  : './compose.component.html',
    styleUrls    : ['./compose.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MailComposeDialogComponent
{
    isLoading    :boolean = false;
    isAcuerdoNotification: boolean = false;
    isFisicNotification: boolean = false;
    isFisicNotificationAcue: boolean = false;
    isFisicNotificationEstrados: boolean = false;
    isNotificationSala: boolean = false;
    showExtraToFields: boolean;
    resolutionForm: FormGroup;
    isShown: boolean = false ; 
    expedientForm: FormGroup;
    transferForm: FormGroup;
    boletaForm: FormGroup;
    usersForm ; FormGroup;
    departments = [];
    allDepartaments = OrganoJurisdiccional.filter(dep => dep.nume < 14);
    users = [];
    autorities = [];
    employees  = [];
    usersp = [];
    autoritiesp = [];
    employeesp  = [];

    parttercero=[];
    partdema=[];
    acotor=[];
    backgroundColorToggle='accent';
    colorTogglee='accent';
    demandado=[];
    ra=[];
    rl=[];
    tercero=[];

    formGroup: FormGroup;
    formGroupra:FormGroup;
    formGrouprl:FormGroup;
    formGrouptercero:FormGroup;
    formGroupactor:FormGroup;
    formGroupautoridad:FormGroup;
    formGroupasesor:FormGroup;
    arr: FormArray;

    currentUser = { empe: '' }
    txtErrorNoti = '';
    agreementData: any;
    tipo:any;
    agreementDataLoaded: boolean = true;
    inSearching = false;
    employeeDepa: any;
    //txtTitleModal = 'Nueva notificación'
    txtTitleModal = '';
    //txtTitleUser: string = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
    txtTitleUser: string = '';
    //txtDeterminacion = 'A NOTIFICAR';
    txtDeterminacion = '';
    //txtTitleDocs: string = 'Adjuntar PDF de determinación *';
    txtTitleDocs: string = '';
    txtErrorFile    :String = 'Debes adjuntar este documento es obligatorio'
    txtErrorFileadju:String = this.txtErrorFile;
    //files 
    typeFile = TypeFile;
    anexosArray = ArrayFiles;
    anexoMinutaNoti;
    anexoMinutaList;
    anexoBoletin;
    toUploadOk = false;
    currentFile: any;
    showMoreFileBtn = false;
    //date
    minDate: Date;
    maxDate: Date;
    minDateNoti: Date;
    //@ALAN VARIABLES FILTROS
    deterNotifiArray:any = [];
    deterNotifi: Observable<string[]>;
    //@END ALAN VARIABLES FILTROS
    //VARIABLES FIRMA ELECTRONICA
    message:string;
    pkcs7:string;
    activarBoton:boolean=false;
    filePFXselct:any;
    password: FormControl;
    extArchivoCargado:string='';
    anexosArrayPK:any = [];
    typeModal: string;
    usNotify: any = UsersNotify;
    //END VARIABLES FIRMA ELECTRONICA
    @ViewChild('adju') adju:ElementRef;
    @ViewChild('stepper') stepper:MatStepper;
    promotions: any[];
    documentsPromotion: Array<[]> = [];

    //firma minuta
    fileLaravel:any;
    documentofirma:any;
    firstFormGroup: FormGroup = this._formBuilder.group({firstCtrl: ['']});
    secondFormGroup: FormGroup = this._formBuilder.group({secondCtrl: ['']});
    //sentencia
    docu:any;

    doctypes = TypeDoc;
    doctypes2 = TypeDoc2;
    incisos=typeinci;
    title = 'Nested FormArray Example Add Form Fields Dynamically';
 
  empForm:FormGroup;



  filteredOptionsActor: Observable<string[]>;
  filteredOptionsDemandado: Observable<string[]>;
  filteredOptionsTercero: Observable<string[]>;
 TypeCaracter = [
    //{value:"parte", to_user:"autoridad", label: "Actor (juicio de lesividad)"},
    {value: 'actor', label:'Actor'},
    {value:"parte", label: "Actor (juicio de lesividad)"},
    {value:"recurrente", label: "Recurrente (Denunciante)"},
    {value:"demandado", label: "Demandado"},
    {value:"tercero", label: "Tercero perjudicado"},
    //{value:"apoderado legal", label: "Apoderado legal"},
    {value:"representante legal", label: "Representante legal"},
    {value:"representante autorizado", label: "Representante autorizado"},
]

typePassword:String = 'password';
txt_tooltip_pass:String = 'mostrar contraseña'
showPass:boolean = false;
    /**
     * Constructor
     *
     * @param {MatDialogRef<MailComposeDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<MailComposeDialogComponent>,
        private _formBuilder: FormBuilder, 
        private cd: ChangeDetectorRef,
        private _expedientServices: ExpedientProvider,
        private _usersService: UsersProvider,
        private _resourcesService: ResourcesProvider,
        private _settings: AppConfigService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matSnackBar: MatSnackBar,
        private _topicService: TopicsService,
        private _documentService: DocumentProvider,private fb: FormBuilder,
        private _partsService: PartsService
    )
    {
        //data to dialog
        this.setVariablesModal();
        // Set the defaults
        if(this._data.acuerdo){
        this.expedientForm = this.createExpedientForm();
        this.docu=this._data.acuerdo.docu;

        }else{
            this.expedientForm = this.createExpedientForm2();
            console.log('this._data',this._data);
            let sentenciaFile=this._data.sentence.link;
            if (this._data.sentence.sentencia_votos) {
                sentenciaFile=this._data.sentence.sentencia_votos;
            }
            this.docu=sentenciaFile;
        }
        this.resolutionForm = this.createresolutionForm();
        this.usersForm = this.createUsersForm();
        this.showExtraToFields = true;
        //set date minimum
        const currentDate = moment();
        this.minDate = new Date(currentDate.year() , currentDate.month(), currentDate.date());
        this.maxDate = new Date(currentDate.year() , currentDate.month(), currentDate.date() + 4);
        this.deterNotifiArray = deterNotification;
        this.password = new FormControl('');
        this.password.disable();
        this.empForm=this.fb.group({
            employees: this.fb.array([]) ,
          })
    }

    ngOnInit() {
        
        this.anexosArray = [new FileAnexo('anexoFile0')];
        this.anexoMinutaNoti = new FileAnexo('anexoFile');
        this.toUploadOk = false;
        this.currentFile = undefined;
        this.showMoreFileBtn = false;
        this.filteredOptionsActor = this.expedientForm.get('part').valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );
          this.filteredOptionsDemandado = this.expedientForm.get('dema').valueChanges.pipe(
            startWith(''),
            map(value => this._filterDemandado(value || '')),
          );
          this.filteredOptionsTercero = this.expedientForm.get('terc').valueChanges.pipe(
            startWith(''),
            map(value => this._filterTercero(value || '')),
          );
          
        this.formGroup = this.fb.group({
            arr: this.fb.array([])
          });
          this.formGroupra = this.fb.group({
            arr: this.fb.array([])
          });
          this.formGrouprl = this.fb.group({
            arr: this.fb.array([])
          });
          this.formGrouptercero = this.fb.group({
            arr: this.fb.array([])
          });
          this.formGroupactor = this.fb.group({
            arr: this.fb.array([])
          });
          this.formGroupautoridad = this.fb.group({
            arr: this.fb.array([])
          });
          this.formGroupasesor = this.fb.group({
            arr: this.fb.array([])
          });
        if(sessionStorage.getItem('usr') !== undefined) {
            this.currentUser = this._settings.getCurrentUser();
        }
        this.employeeDepa = this._settings.getDepaEmployee()
        this._getAllDepartments();
        console.log("agreementdata", this.agreementData);
        if((this.isAcuerdoNotification && this.agreementData) || this.isFisicNotificationAcue){
            this.setInfoAgreement();
            if(this._data.acuerdo){

                this._getUsersAgreement(this.agreementData.nume);
    
                }else{
 //aqui saca el agreement
 const agbys = new FormData();
 agbys.append('une', this.agreementData.une);
 this._usersService.getAgreementbySentence(agbys).subscribe(
    (result) => {
        console.log("entra a buscar acuerdo",result);
        this._getUsersAgreement(result);

    },
    (error) => console.log('error get users', error)
);                }        }

        if(this.isFisicNotificationEstrados) {
            this.fepu.setValidators([Validators.required]);
            this.fepu.updateValueAndValidity();
        }
        this._startChangesFilterDeterminacionNotificadas();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.users.filter(option => option.nomb.toLowerCase().includes(filterValue));
      }
      private _filterDemandado(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.demandado.filter(option => option.nomb.toLowerCase().includes(filterValue));
      }  
      private _filterTercero(value: string): string[] {
        const filterValue = value.toLowerCase();
    
        return this.tercero.filter(option => option.nomb.toLowerCase().includes(filterValue));
      }  
    setVariablesModal(){
        const _config = this._data;
        this.boletaForm = null;
        this.typeModal = _config.typeNotification;
        console.log("tipo de notificacion", _config)
        if(_config.typeNotification === 'electronicaenlinea'){
            this.anexoMinutaList = new FileAnexo('anexoFileList');
            this.anexoBoletin = new FileAnexo('anexoBoletin');

            this.isAcuerdoNotification = true;
            if(_config.acuerdo){
            this.agreementData = _config.acuerdo;
            }else{
                this.agreementData = _config.sentence;

            }

            //acuerdo o sentencia
            this.tipo=_config.tipo;
            this.txtTitleModal = 'Notificación electrónica en juicio en línea';
            this.txtTitleUser = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
            this.txtDeterminacion = 'A NOTIFICAR';
            this.boletaForm = this.createBoletaForm();
            //Obtener documentos de la promocion
            this.promotions = [];
            if(_config.acuerdo){
                this._documentService.getPromotionsByAgreement(_config.acuerdo.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }else{
                //sentencia
                this._documentService.getPromotionsBySentence(_config.sentence.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }
        }else if(_config.typeNotification == 'boletinenlinea' || _config.typeNotification == 'boletintradicional'){
            this.isAcuerdoNotification = true;
            if(_config.acuerdo){
                this.agreementData = _config.acuerdo;
                }else{
                    this.agreementData = _config.sentence;
    
                }            this.txtTitleModal = 'Notificación por boletín';
            this.txtTitleUser = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
            this.txtDeterminacion = 'A NOTIFICAR';
            this.boletaForm = this.createBoletaForm();
            if(_config.acuerdo){
                this._documentService.getPromotionsByAgreement(_config.acuerdo.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }else{
                 //sentencia
                 this._documentService.getPromotionsBySentence(_config.sentence.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }
        }else if(_config.typeNotification == 'electronicatradicional'){
            this.isAcuerdoNotification = true;
            if(_config.acuerdo){
                this.agreementData = _config.acuerdo;
                }else{
                    this.agreementData = _config.sentence;
    
                }            this.txtTitleModal = 'Notificación electrónica en juicio tradicional';
            this.txtTitleUser = 'USUARIOS A  NOTIFICAR POR VÍA ELECTRÓNICA';
            if(_config.acuerdo){
                this._documentService.getPromotionsByAgreement(_config.acuerdo.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }
        }else if(_config.typeNotification === 'fisicatradicional') {
            this.isFisicNotification = true;
            this.txtTitleModal = 'Nueva notificación física';
            this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
            this.txtDeterminacion = 'NOTIFICADA';
            this.txtTitleDocs = 'Adjuntar constancia de notificación *'
        }
       /* if(_config.typeNotification === 'acuerdo' || _config.typeNotification === 'acue-ofic'){
            this.txtTitleModal = 'Nueva notificación electrónica';
            this.isAcuerdoNotification = true;
            this.agreementData = _config.acuerdo;
        }else if(_config.typeNotification === 'fisica') {
            this.isFisicNotification = true;
            this.txtTitleModal = 'Nueva notificación física';
            this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
            this.txtDeterminacion = 'NOTIFICADA';
            this.txtTitleDocs = 'Adjuntar constancia de notificación *'
        }else if(_config.typeNotification === 'estrados') {
            this.isFisicNotificationEstrados = true;
            this.txtTitleModal = 'Nueva notificación de acuerdo por estrados';
            this.isAcuerdoNotification = true;
            this.txtTitleUser = 'FECHA A NOTIFICAR POR ESTRADOS';
            this.agreementData = _config.acuerdo;
        }else if(_config.typeNotification === 'acue-fisi') {
            this.txtTitleModal = 'Nueva notificación de acuerdo física';
            this.isFisicNotificationAcue = true;
            this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
            this.txtDeterminacion = 'NOTIFICADA';
            this.txtTitleDocs = 'Adjuntar constancia de notificación *'
            this.agreementData = _config.acuerdo;
        }else if(_config.typeNotification === 'acue-sala') {
            this.isNotificationSala = true;
            this.agreementData = _config.acuerdo;
            this.transferForm = this.createTransferForm();
        }*/
    }

    reloadVariablesModal(data){
        if(this._data.typeNotification === 'electronicaenlinea'){
            this.anexoMinutaList = new FileAnexo('anexoFileList');
            this.isAcuerdoNotification = true;
            this.agreementData = data;
            this.txtTitleModal = 'Notificación electrónica en juicio en línea';
            this.txtTitleUser = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
            this.txtDeterminacion = 'A NOTIFICAR';
            this.boletaForm = this.createBoletaForm();
            //Obtener documentos de la promocion
            this.promotions = [];
            if(data){
                this._documentService.getPromotionsByAgreement(data.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }
            if(this._data.acuerdo){
            this.expedientForm = this.createExpedientForm();
        }else{
            this.expedientForm = this.createExpedientForm2();

        }
            this.resolutionForm = this.createresolutionForm();
            this.usersForm = this.createUsersForm();
        }else if(this._data.typeNotification == 'boletinenlinea' || this._data.typeNotification == 'boletintradicional'){
            this.isAcuerdoNotification = true;
            this.agreementData = data;
            this.txtTitleModal = 'Notificación por boletín';
            this.txtTitleUser = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
            this.txtDeterminacion = 'A NOTIFICAR';
            this.boletaForm = this.createBoletaForm();
            if(data){
                this._documentService.getPromotionsByAgreement(data.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }
            if(this._data.acuerdo){
                this.expedientForm = this.createExpedientForm();
            }else{
                this.expedientForm = this.createExpedientForm2();
    
            }            this.resolutionForm = this.createresolutionForm();
            this.usersForm = this.createUsersForm();
        }else if(this._data.typeNotification == 'electronicatradicional'){
            this.isAcuerdoNotification = true;
            this.agreementData = data;
            this.txtTitleModal = 'Notificación electrónica en juicio tradicional';
            this.txtTitleUser = 'USUARIOS A  NOTIFICAR POR VÍA ELECTRÓNICA';
            if(data){
                this._documentService.getPromotionsByAgreement(data.id).subscribe(
                    (result) => {
                        this.promotions = result["data"];
                        if(this.promotions.length > 0){
                            this._documentService
                            .getDocumentsUploaded(this.promotions[0].nume)
                            .subscribe(
                                (result) => {
                                    this.documentsPromotion = result["data"];
                                    console.log(
                                        "documentos de la promocion",
                                        this.documentsPromotion
                                    );
                                },
                                (error) => console.log("error get documents", error)
                            );
                        }
                    },
                    (error) => console.log("error get documents", error)
                );
            }
        }
        if(this._data.acuerdo){
            this.expedientForm = this.createExpedientForm();
        }else{
            this.expedientForm = this.createExpedientForm2();

        }        this.resolutionForm = this.createresolutionForm();
        this.usersForm = this.createUsersForm();
        this._getAllDepartments();
        this._getUsers('')
        if((this.isAcuerdoNotification && this.agreementData) || this.isFisicNotificationAcue){
            this.setInfoAgreement();
            if(this._data.acuerdo){

            this._getUsersAgreement(this.agreementData.nume);

            }else{
                //aqui saca el agreement
                this._usersService.getAgreementbySentence(this.agreementData.une).subscribe(
                    (result) => {
                        this._getUsersAgreement(result);
                        this.setInfoAgreement();

                    },
                    (error) => console.log('error get users', error)
                );
            }

        }

        if(this.isFisicNotificationEstrados) {
            this.fepu.setValidators([Validators.required]);
            this.fepu.updateValueAndValidity();
        }
    }

    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createresolutionForm(): FormGroup
    {
        this.anexosArray = [new FileAnexo('anexoFile0')];
        return this._formBuilder.group({
            tipo  : [''],
            fracc :[''],
            inci  : [''],
            fecd  : [''],
            adju  : ['', Validators.required],
            fecn  : [''],
            time  : [''],
            anexos: [false],
            docuExpe: [[]],
            docuOfic: [''],
        });
    }

    createTransferForm(): FormGroup
    {
        const nume = this.agreementData.nume || '';
        this.anexosArray = [new FileAnexo('anexoFile0')];
        return this._formBuilder.group({
            nume  : [nume, Validators.required],
            depa  : ['', Validators.required],
            anexos: [false]
        });
    }
add(user,ind){
    this.formGroup.markAllAsTouched();
    console.log(this.formGroup.value.arr[ind]);
}

get f() {
    return this.formGroup.controls;
  }

  createItem(user) {
    if(this._data.acuerdo){
        return this.fb.group({
            fracc: [''],
            articu:[''],
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            idu  : user,
            inci: [''],
        });
    }else{
            let tyse:any='';
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            return this.fb.group({
                fracc: [''],
                articu:[''],
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                idu  : user,
                inci: [''],
            });
        }
   
  }

  addItem(nume,user) {
    console.log('this.nume',nume);
    this.arr = this.f['arr'] as FormArray;
      this.arr.push(this.createItem(user));
    console.log('this.arr',this.arr);
  }
  removeItemvalidator(nume){
    this.f?.arr['controls'][nume]?.controls?.fracc?.clearValidators();        
    this.f?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.f?.arr['controls'][nume]?.controls?.articu?.clearValidators();        
    this.f?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity();
  }
  addItemvalidator(nume){
    this.f?.arr['controls'][nume]?.controls?.fracc?.setValidators([Validators.required]);
    this.f?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.f?.arr['controls'][nume]?.controls?.articu?.setValidators([Validators.required]);
    this.f?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
  }
  removeItem(idx: number): void {
    (this.f['arr'] as FormArray).removeAt(idx);
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    console.log(this.formGroup.value);
  }

  //rautorizado

  addra(user,ind){
    this.formGroupra.markAllAsTouched();
    console.log(this.formGroupra.value.arr[ind]);
}

get fra() {
    return this.formGroupra.controls;
  }

  createItemra() {
    if(this._data.acuerdo){
        return this.fb.group({
            fracc: [''],
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            inci: [''],
        });
    }else{
            let tyse:string='';
            console.log('sentido debug',this.agreementData.tipo);
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            return this.fb.group({
                fracc: [''],
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                inci: [''],
            });
        }
   
  }

  addItemra(nume,user) {
    console.log('this.nume',nume);
    this.arr = this.fra['arr'] as FormArray;
      this.arr.push(this.createItem(user));
    console.log('this.arr',this.arr);
  }
  addItemvalidatorra(nume){
    this.fra?.arr['controls'][nume]?.controls?.fracc?.setValidators([Validators.required]);
    this.fra?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.fra?.arr['controls'][nume]?.controls?.articu?.setValidators([Validators.required]);
    this.fra?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  removeItemra(idx: number): void {
    (this.fra['arr'] as FormArray).removeAt(idx);
  }
  removeItemvalidatorra(nume){
    this.fra?.arr['controls'][nume]?.controls?.fracc?.clearValidators();        
    this.fra?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.fra?.arr['controls'][nume]?.controls?.articu?.clearValidators();        
    this.fra?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  onSubmitra() {
    this.formGroupra.markAllAsTouched();
    console.log(this.formGroupra.value);
  }


  //rlegal

  addrl(user,ind){
    this.formGrouprl.markAllAsTouched();
    console.log(this.formGrouprl.value.arr[ind]);
}

get frl() {
    return this.formGrouprl.controls;
  }

  createItemrl() {
    if(this._data.acuerdo){
        return this.fb.group({
            fracc: [''],
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            inci: [''],
        });
    }else{
            let tyse:any='';
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            return this.fb.group({
                fracc: [''],
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                inci: [''],
            });
        }
   
  }

  addItemrl(nume,user) {
    console.log('this.nume',nume);
    this.arr = this.frl['arr'] as FormArray;
      this.arr.push(this.createItem(user));
    console.log('this.arr',this.arr);
  }
  addItemvalidatorrl(nume){
    this.frl?.arr['controls'][nume]?.controls?.fracc?.setValidators([Validators.required]);
    this.frl?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.frl?.arr['controls'][nume]?.controls?.articu?.setValidators([Validators.required]);
    this.frl?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  removeItemrl(idx: number): void {
    (this.frl['arr'] as FormArray).removeAt(idx);
  }
  removeItemvalidatorrl(nume){
    this.frl?.arr['controls'][nume]?.controls?.fracc?.clearValidators();        
    this.frl?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.frl?.arr['controls'][nume]?.controls?.articu?.clearValidators();        
    this.frl?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  onSubmitrl() {
    this.formGrouprl.markAllAsTouched();
    console.log(this.formGrouprl.value);
  }

  //tercero

  addtercero(user,ind){
    this.formGrouptercero.markAllAsTouched();
    console.log(this.formGrouptercero.value.arr[ind]);
}

get ftercero() {
    return this.formGrouptercero.controls;
  }
  validarformsdin(){
    let ban=false;
        const controls = this.ftercero.arr['controls'];
        const controls2 = this.frl.arr['controls'];
        const controls3 = this.fra.arr['controls'];
        const controls4 = this.f.arr['controls'];
        const controls5 = this.factor.arr['controls'];
        const controls6 = this.fautoridad.arr['controls'];
        const controls7 = this.fasesor.arr['controls'];

    if(controls!=null){
        for (const name in controls) {
            if (controls[name].invalid) {
        ban=true;         
        }
        }
    }
        
    if(controls2!=null){
        for (const name in controls2) {
            if (controls2[name].invalid) {
        ban=true;         
        }
        }
    }
    if(controls3!=null){
        for (const name in controls3) {
            if (controls3[name].invalid) {
        ban=true;         
        }
        }
    }
    if(controls4!=null){
        for (const name in controls4) {
            if (controls4[name].invalid) {
        ban=true;         
        }
        }
    }
    if(controls5!=null){
        for (const name in controls5) {
            if (controls5[name].invalid) {
        ban=true;         
        }
        }
    }if(controls6!=null){
        for (const name in controls6) {
            if (controls6[name].invalid) {
        ban=true;         
        }
        }
    }if(controls7!=null){
        for (const name in controls7) {
            if (controls7[name].invalid) {
        ban=true;         
        }
        }
    }
    
                return ban;
}
  createItemtercero() {
    if(this._data.acuerdo){
        return this.fb.group({
            fracc: [''],
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            inci: [''],
        });
    }else{
            let tyse:any='';
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            return this.fb.group({
                fracc: [''],
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                inci: [''],
            });
        }
   
  }

  addItemtercero(nume,user) {
    this.arr = this.ftercero['arr'] as FormArray;
    const form=this.createItem(user);
    console.log('form',form);
      this.arr.insert(nume,form);
    console.log('this.arr',this.arr);
  }
  addItemvalidatortercero(nume){
    this.ftercero?.arr['controls'][nume]?.controls?.fracc?.setValidators([Validators.required]);
    this.ftercero?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.ftercero?.arr['controls'][nume]?.controls?.articu?.setValidators([Validators.required]);
    this.ftercero?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity();
    
  }
  removeItemtercero(idx: number): void {
    (this.ftercero['arr'] as FormArray).removeAt(idx);
    console.log('this.arr',this.ftercero);

  }
  removeItemvalidatortercero(nume){
    this.ftercero?.arr['controls'][nume]?.controls?.fracc?.clearValidators();        
    this.ftercero?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.ftercero?.arr['controls'][nume]?.controls?.articu?.clearValidators();        
    this.ftercero?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
  }
  onSubmittercero() {
    this.formGrouptercero.markAllAsTouched();
    console.log(this.formGrouptercero.value);
  }


  //actor

  addactor(user,ind){
    this.formGroupactor.markAllAsTouched();
    console.log(this.formGroupactor.value.arr[ind]);
}

get factor() {
    return this.formGroupactor.controls;
  }

  createItemactor() {
    if(this._data.acuerdo){
        return this.fb.group({
            fracc: [''],
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            inci: [''],
        });
    }else{
            let tyse:any='';
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            return this.fb.group({
                fracc: [''],
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                inci: [''],
            });
        }
   
  }

  addItemactor(nume,user) {
    console.log('this.nume',nume);
    this.arr = this.factor['arr'] as FormArray;
      this.arr.push(this.createItem(user));
    console.log('this.arr',this.arr);
  }
  addItemvalidatoractor(nume){
    this.factor?.arr['controls'][nume]?.controls?.fracc?.setValidators([Validators.required]);
    this.factor?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.factor?.arr['controls'][nume]?.controls?.articu?.setValidators([Validators.required]);
    this.factor?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  removeItemactor(idx: number): void {
    (this.factor['arr'] as FormArray).removeAt(idx);
  }
  removeItemvalidatoractor(nume){
    this.factor?.arr['controls'][nume]?.controls?.fracc?.clearValidators();        
    this.factor?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity();
    this.factor?.arr['controls'][nume]?.controls?.articu?.clearValidators();        
    this.factor?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  onSubmitactor() {
    this.formGroupactor.markAllAsTouched();
    console.log(this.formGroupactor.value);
  }
 //autoridad

 addautoridad(user,ind){
    this.formGroupautoridad.markAllAsTouched();
    console.log(this.formGroupautoridad.value.arr[ind]);
}

get fautoridad() {
    return this.formGroupautoridad.controls;
  }

  createItemautoridad() {
    if(this._data.acuerdo){
        return this.fb.group({
            fracc: [''],
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            inci: [''],
        });
    }else{
            let tyse:any='';
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            return this.fb.group({
                fracc: [''],
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                inci: [''],
            });
        }
   
  }

  addItemautoridad(nume,user) {
    console.log('this.nume autoridad',nume);
    this.arr = this.fautoridad['arr'] as FormArray;
      this.arr.push(this.createItem(user));
    console.log('this.arr autoridad',this.arr);
  }
  addItemvalidatorautoridad(nume){
    this.fautoridad?.arr['controls'][nume]?.controls?.fracc?.setValidators([Validators.required]);
    this.fautoridad?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.fautoridad?.arr['controls'][nume]?.controls?.articu?.setValidators([Validators.required]);
    this.fautoridad?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  removeItemautoridad(idx: number): void {
    (this.fautoridad['arr'] as FormArray).removeAt(idx);
  }
  removeItemvalidatorautoridad(nume){
    this.fautoridad?.arr['controls'][nume]?.controls?.fracc?.clearValidators();        
    this.fautoridad?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.fautoridad?.arr['controls'][nume]?.controls?.articu?.clearValidators();        
    this.fautoridad?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  onSubmitautoridad() {
    this.formGroupautoridad.markAllAsTouched();
    console.log(this.formGroupautoridad.value);
  }
  //asesor

 addasesor(user,ind){
    this.formGroupasesor.markAllAsTouched();
    console.log(this.formGroupasesor.value.arr[ind]);
}

get fasesor() {
    return this.formGroupasesor.controls;
  }

  createItemasesor() {
    if(this._data.acuerdo){
        return this.fb.group({
            fracc: [''],
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            inci: [''],
        });
    }else{
            let tyse:any='';
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            return this.fb.group({
                fracc: [''],
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                inci: [''],
            });
        }
   
  }

  addItemasesor(nume,user) {
    console.log('this.nume',nume);
    this.arr = this.fasesor['arr'] as FormArray;
      this.arr.push(this.createItem(user));
    console.log('this.arr',this.arr);
  }
  addItemvalidatorasesor(nume){
    this.fasesor?.arr['controls'][nume]?.controls?.fracc?.setValidators([Validators.required]);
    this.fasesor?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity();
    this.fasesor?.arr['controls'][nume]?.controls?.articu?.setValidators([Validators.required]);
    this.fasesor?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  removeItemasesor(idx: number): void {
    (this.fasesor['arr'] as FormArray).removeAt(idx);
  }
  removeItemvalidatorasesor(nume){
    this.fasesor?.arr['controls'][nume]?.controls?.fracc?.clearValidators();        
    this.fasesor?.arr['controls'][nume]?.controls?.fracc?.updateValueAndValidity(); 
    this.fasesor?.arr['controls'][nume]?.controls?.articu?.clearValidators();        
    this.fasesor?.arr['controls'][nume]?.controls?.articu?.updateValueAndValidity(); 
    
  }
  onSubmitasesor() {
    this.formGroupasesor.markAllAsTouched();
    console.log(this.formGroupasesor.value);
  }
    createExpedientForm() : FormGroup {
        let une = { value: '', disabled: false };
        if((this.agreementData && this.isAcuerdoNotification) || this.isFisicNotificationAcue){
            const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
            une = { value: expe, disabled: true }
        }
        let expeu = '';

        if(this.agreementData && this.isAcuerdoNotification){
            if(this.agreementData.expeu != null){
                expeu = this.agreementData.nume;
            }
        }

        let actor = '';
        console.log("agreement data", this.agreementData)
        if(this.agreementData && this.isAcuerdoNotification){
            if(this.agreementData.expeu != null){
                actor = this.agreementData.expeu.nombre_promovente;
            }
        }
        return this._formBuilder.group({
            une   : [une, Validators.required],// Validators.pattern('([TJA]|[TCA]){3}[/]([SS]|[SR]){2}([ACHIOTZ]{1,2}|)[/]((\b)(JRAG|I|II|EXCJUS|QUEJA|RREC|RINC|RAP|REV)(\b)[/]|)[0-9]{1,5}[/][0-9]{4}')]],
            depa  : ['', Validators.required],
            part  : ['',],
            dema  : ['',],
            terc  : [''],
            acue  : [une],
            expe_uni: [this.agreementData.nume],
        });
    }
    createExpedientForm2() : FormGroup {
        let une = { value: '', disabled: false };
        if((this.agreementData && this.isAcuerdoNotification) || this.isFisicNotificationAcue){
            const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
            une = { value: expe, disabled: true }
        }
        let expeu = '';

        if(this.agreementData && this.isAcuerdoNotification){
            if(this.agreementData.expe_uni != null){
                expeu = this.agreementData.expe_uni;
            }
        }

        let actor = '';
        console.log("agreement data", this.agreementData)
        if(this.agreementData && this.isAcuerdoNotification){
            if(this.agreementData.aut != null){
                actor = this.agreementData.aut;
            }
        }
        let terc = '';
        console.log("agreement data", this.agreementData)
        if(this.agreementData && this.isAcuerdoNotification){
            if(this.agreementData.terc != null){
                terc = this.agreementData.terc;
            }
        }
        
        return this._formBuilder.group({
            une   : [une, Validators.required],// Validators.pattern('([TJA]|[TCA]){3}[/]([SS]|[SR]){2}([ACHIOTZ]{1,2}|)[/]((\b)(JRAG|I|II|EXCJUS|QUEJA|RREC|RINC|RAP|REV)(\b)[/]|)[0-9]{1,5}[/][0-9]{4}')]],
            depa  : ['', Validators.required],
            part  : ['', ],
            dema  : ['',],
            terc  : [''],
            acue  : [une],
            expe_uni: [expeu],
        });
    }
    createBoletaForm(): FormGroup{
        return this._formBuilder.group({
            date_pub   : ['', [Validators.required]],// Validators.pattern('([TJA]|[TCA]){3}[/]([SS]|[SR]){2}([ACHIOTZ]{1,2}|)[/]((\b)(JRAG|I|II|EXCJUS|QUEJA|RREC|RINC|RAP|REV)(\b)[/]|)[0-9]{1,5}[/][0-9]{4}')]],
        });
    }

    createUsersForm (): FormGroup {

        return this._formBuilder.group({
            disp: [''],
            ciud: [[]],
            auth: [[]],
            usua: [[]],
            empl: [[]],
            acueusa: [[]],
            actor: [[]],
            tercero: [[]],
            autoridadDemandada: [[]],
            ra: [[]],
            rl: [[]],
            usnotify: ['']
        });
    }

    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void
    {
        this.showExtraToFields = !this.showExtraToFields;
    }

    _getAllDepartments() {
        this._resourcesService.getDepartments().subscribe(
            (result) => {
                this.departments = result['data'].filter( element => element.nume === this._settings.getDepaEmployee())
                if((this.isAcuerdoNotification || this.isFisicNotificationAcue) && this.agreementData) {
                    if(this._data.acuerdo){
                        this.expedientForm.patchValue({ depa: this.agreementData.empldata.data.depa.toString() })

                    }else{
                        console.log('this.agreementData',this.agreementData);
                        this.expedientForm.patchValue({ depa: this.employeeDepa.toString() })

                    }

                }else {
                    this.expedientForm.patchValue({ depa: this.employeeDepa.toString() })
                }
            },
            (error) => console.log('error get departaments', error)
        );
    }

    _getAgreementData(event){
        let searchText;
        if(event.target.value == ''){
            searchText = 'undefined';
        }else{
            searchText = event.target.value;
        }
        this._resourcesService.getAgreementByExpediente(searchText).subscribe(
            (response)=>{
                console.log("response get agreement data", response['data']);
                if(response['data'].length > 0){

                    this.agreementDataLoaded = true;
                    this.agreementData = this._transformAgreements(response['data'][0]);
                    this.reloadVariablesModal(this.agreementData);
                }else{
                    this.agreementDataLoaded = false;
                }
            },(error)=>{
                console.log("error get agreement data", error);
            }
        )
    }

    _transformAgreements(todo){
        return new Todo(todo);
    }

    _getUsers(e) {
        if(!this.isAcuerdoNotification && this.expedientForm.value.une && this.expedientForm.value.depa){
            const data = {
                folio: this.expedientForm.value.une ,
                depa: this.expedientForm.value.depa
            }
            this.users      = [];
            this.autorities = [];
            this.employees  = [];
            this._usersService.getUserByExpediente(data).subscribe(
                (result) => {
                    result['data'].forEach(user => {
                        if(user.ciud !== 0){
                           // this.users.push({ ...user.ciuddata.data.usuadata.data, type: user.tipo});
                        }else if(user.auto !== 0){
                            this.autorities.push({ ...user.autodata.data.usuadata.data, type: user.tipo});
                        }else if (Object.prototype.hasOwnProperty.call(user, 'empldata')) {
                            this.employees.push({ ...user.empldata.data.usuadata.data, type: user.tipo});
                        }
                        this.fillParts(user);
                    });
                },
                (error) => console.log('error get users', error)
            );
        }
    }

    consoleForm(){
        console.log(this.expedientForm);
    }
    _getUsersAgreement(id_agreement){
        //const id_agreement = idthis.expedientForm.value.acue;
        this.users = [];
        this.autorities = [];
        this._usersService.getUserByAgreements(id_agreement).subscribe(
            (result) => {
                result['data'].forEach(user => {
                   // this.users.push({ ...user.usuadata.data, type: user.tipo});
                    this.addItemactor(1,user.nume);

                   // this.fillParts(user);
                });
            },
            (error) => console.log('error get users', error)
        );
        if(this._data.typeNotification === 'electronicaenlinea'){
            //partes procesales dentro del expediente
            this.usersp = [];
            this.autoritiesp = [];
            this.employeesp=[];
            this.demandado=[];
            this.ra=[];
            this.rl=[];
            this.tercero=[];

            this._partsService.getByExp(this.agreementData.une).subscribe(
                (result)=>{

            let partes=[];
           
            if (this.resolutionForm.value.tipo==='Acuerdo') {
                partes=this.agreementData.partes || [];
            }else{
                partes=result['data'];
            }
           
            

            console.log('partes',partes,this.agreementData);

            const demandados=partes.filter(item=>item.caracter=='autoridadDemandada');
            demandados.forEach(user=>{
                this.demandado.push({nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                console.log('this.demandado.',this.demandado);
                this.addItem(1,user.id);
            });
            const tercero=partes.filter(item=>item.caracter=='tercero');
            tercero.forEach(user=>{
                this.tercero.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                console.log('this.demandado.',this.demandado);
                this.addItemtercero(1,user.id);
            });
            const actores=partes.filter(item=>item.caracter=='actor');
            actores.forEach(user=>{
                this.users.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                console.log('this.demandado.',this.demandado);
                this.addItemactor(1,user.id);
            });

            const rl=partes.filter(item=>item.caracter=='representanteLegal');
            rl.forEach(user=>{
                this.rl.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                console.log('this.RL.',this.rl);
                this.addItemrl(1,user.id);


            });

            const ra=partes.filter(item=>item.caracter=='representanteAutorizado');
            ra.forEach(user=>{
                this.ra.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                console.log('this.RL.',this.ra);
                this.addItemra(1,user.id);
            });

            const otros=partes.filter(item=>item.caracter=='otro');

            otros.forEach(user=>{

                const data={
                    id:[{value:`${user.id}`, disabled:true},{validators:[Validators.required]}],
                    firstName:[{value:`${user.name} ${user.apaterno} ${user.amaterno}`, disabled:true},{validators:[Validators.required]}],
                    tipoparte:[{value:user.fullname, disabled:true,},{validators:[Validators.required]}],
                    lastName: [{value:user.domicilio_procesal,disabled:true},{validators:[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]}],
                  }
                this.employees1().push(this.fb.group(data));

                
            });
             });
           /*  this._partsService.getByExp(this.agreementData.une).subscribe(
                (result)=>{
                    console.log('partes',result['data']);
                    const partes=result['data'];
                    const demandados=partes.filter(item=>item.caracter=='autoridadDemandada');
                    demandados.forEach(user=>{
                        this.demandado.push({nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                        console.log('this.demandado.',this.demandado);
                        this.addItem(1,user.id);
                    });
                    const tercero=partes.filter(item=>item.caracter=='tercero');
                    tercero.forEach(user=>{
                        this.tercero.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                        console.log('this.demandado.',this.demandado);
                        this.addItemtercero(1,user.id);
                    });
                    const actores=partes.filter(item=>item.caracter=='actor');
                    actores.forEach(user=>{
                        this.users.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                        console.log('this.demandado.',this.demandado);
                        this.addItemactor(1,user.id);
                    });

                    const rl=partes.filter(item=>item.caracter=='representanteLegal');
                    rl.forEach(user=>{
                        this.rl.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                        console.log('this.RL.',this.rl);
                        this.addItemrl(1,user.id);


                    });

                    const ra=partes.filter(item=>item.caracter=='representanteAutorizado');
                    ra.forEach(user=>{
                        this.ra.push({ nomb:user.fullname, pate:'',mate:'',tipo:user.caracter,mail:user.domicilio_procesal ,id:user.id});
                        console.log('this.RL.',this.ra);
                        this.addItemra(1,user.id);
                    });

                    const otros=partes.filter(item=>item.caracter=='otro');

                    otros.forEach(user=>{

                        const data={
                            firstName:[`${user.name} ${user.apaterno} ${user.amaterno}`, Validators.required],
                            tipoparte:[user.fullname, Validators.required],
                            lastName: [user.domicilio_procesal,[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
                          }
                        this.employees1().push(this.fb.group(data));

                        
                    });
                }
            ); */
        /*     this._usersService.getUserByAgreementsp(id_agreement).subscribe(
                (result) => {
                    if (result['demandado'].original.data.length>0 ) {
                        result['demandado'].original.data.forEach(user => {
                            this.demandado.push({ ...user.usuadata.data, type: user.tipo });
                            this.addItem(1,user.nume);
                            // this.fillParts(user);
                        });
                    }
                    if (result['ra'].original.data.length>0 ) {
                        result['ra'].original.data.forEach(user => {
                            this.ra.push({ ...user.usuadata.data, type: user.tipo });
                            this.addItemra(1,user.nume);

                            // this.fillParts(user);
                        });
                    }
                    if (result['rl'].original.data.length>0 ) {
                        result['rl'].original.data.forEach(user => {
                            this.rl.push({ ...user.usuadata.data, type: user.tipo });
                            this.addItemrl(1,user.nume);

                            // this.fillParts(user);
                        });
                    }
                    if (result['tercero'].original.data.length>0 ) {
                        result['tercero'].original.data.forEach(user => {
                            this.tercero.push({ ...user.usuadata.data, type: user.tipo});
                            this.addItemtercero(1,user.nume);

                            // this.fillParts(user);
                        });
                    }
                },
                (error) => console.log('error get users', error)
            );
            console.log("demandado",this.demandado);
            this._usersService.getAutoByAgreementsp(id_agreement).subscribe(
                (result) => {
                    if (result['demandado'].original.data.length>0 ) {
                        result['demandado'].original.data.forEach(user => {
                            this.demandado.push({...user.usuadata.data, type: user.tipo});
                            this.addItem(1,user.nume);
                            // this.fillParts(user);
                        });
                    }
                    if (result['ra'].original.data.length>0 ) {
                        result['ra'].original.data.forEach(user => {
                            this.ra.push({...user.usuadata.data, type: user.tipo });
                            this.addItemra(1,user.nume);
                            // this.fillParts(user);
                        });
                    }
                    if (result['rl'].original.data.length>0 ) {
                        result['rl'].original.data.forEach(user => {
                            this.rl.push({ ...user.usuadata.data, type: user.tipo});
                            this.addItemrl(1,user.nume);
                            // this.fillParts(user);
                        });
                    }
                    if (result['tercero'].original.data.length>0 ) {
                        result['tercero'].original.data.forEach(user => {
                            this.tercero.push({...user.usuadata.data, type: user.tipo});
                            this.addItemtercero(1,user.nume);
                            // this.fillParts(user);
                        });
                    }
                },
                (error) => console.log('error get users', error)
            );
            this._usersService.getemploByAgreementsp(id_agreement).subscribe(
                (result) => {
                    if (result['demandado'].original.data.length>0) {
                        result['demandado'].original.data.forEach(user => {
                            this.demandado.push({ ...user.usuadata.data, type: user.tipo});
                            this.addItem(1,user.nume);

                            // this.fillParts(user);
                        });
                    }
                    if (result['ra'].original.data.length>0 ) {
                        result['ra'].original.data.forEach(user => {
                            this.ra.push({ ...user.usuadata.data, type: user.tipo});
                            this.addItemra(1,user.nume);

                            // this.fillParts(user);
                        });
                    }
                    if (result['rl'].original.data.length>0 ) {
                        result['rl'].original.data.forEach(user => {
                            this.rl.push({...user.usuadata.data, type: user.tipo});
                            this.addItemrl(1,user.nume);

                            // this.fillParts(user);
                        });
                    }
                    if (result['tercero'].original.data.length>0 ) {
                        result['tercero'].original.data.forEach(user => {
                            this.tercero.push({...user.usuadata.data, type: user.tipo});
                            this.addItemtercero(1,user.nume);

                            // this.fillParts(user);
                        });
                    }
                },
                (error) => console.log('error get users', error)
            ); */
        }
        /* this._usersService.getAutoritiesByAgreements(id_agreement).subscribe(
            (result) => {
                result['data'].forEach(user => {
                    this.autorities.push({ ...user.usuadata.data, type: user.tipo});
                   // this.fillParts(user);
                   this.addItemautoridad(1,user.nume);

                });
            },
            (error) => console.log('error get users', error)
        ); */
       /*  this._usersService.getAsesorByAgreements(id_agreement).subscribe(
            (result) => {
                result['data'].forEach(user => {
                    this.employees.push({ ...user.usuadata.data, type: user.tipo});
                   // this.fillParts(user);
                   this.addItemasesor(1,user.nume);

                });
            },
            (error) => console.log('error get users', error)
        ); */
    }

    fillParts(reg){
        //TJA/SRA/II/JRAG/002/2020
        console.log("aqui se llenan las partes");
        if(this._data.acuerdo){

        this.expedientForm.patchValue({
            part: reg.part,
            dema: reg.dema,
            terc: reg.terc
        });}
    }

    setMinDateNoti(){
        this.minDateNoti = new Date( this.resolutionForm.get('fecd').value.year() ,  this.resolutionForm.get('fecd').value.month(),  this.resolutionForm.get('fecd').value.date());
    }

    setFile(inputFileName) {
        const reader = new FileReader();
        const fileUpload = this[`${ inputFileName }`].nativeElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
                const [file1] = fileUpload.files;
                if(!this.validTypeSizeFile(file1, inputFileName)) {
                    return
                }
                reader.readAsDataURL(file1);
                reader.onload = () => {
                this.resolutionForm.patchValue({
                    [`${ inputFileName }`]: file1
                });
                };
        
                this.cd.markForCheck();
            }
        };
        fileUpload.click();  
    }

    removeFile(inputFileName){
        this.resolutionForm.patchValue({
            [`${ inputFileName }`]: ''
        });
    }

    validTypeSizeFile(file, inputFileName) {
        if(inputFileName === 'adju'){
            this.txtErrorFileadju = this.txtErrorFile;
        }
        if(file.type !== 'application/pdf') {
            this[`txtErrorFile${ inputFileName }`] = 'El archivo debe ser en formato PDF';
            return false;
        }
        if(file.size > 10000000) {
            this[`txtErrorFile${ inputFileName }`] = 'El archivo debe ser de un tamaño no mayor a 10mb';
            return false;
        }
        return true;
    }

    setUser(user, event,ind,tipo) {
        if(event.checked) {
            switch(tipo){
                case 'demandado':
                 this.addItemvalidator(ind);
                    break;
                case 'tercero':
                    this.addItemvalidatortercero(ind);
                    break;
                case 'rl':
                     this.addItemvalidatorrl(ind);
                    break;
                case 'ra':
                   this.addItemvalidatorra(ind);
                    break;
                case 'actor':
                    this.addItemvalidatoractor(ind);
                    break;
                case 'autoridad':
                    this.addItemvalidatorautoridad(ind);
                    break;
                case 'asesor':
                    this.addItemvalidatorasesor(ind);
                    break;
             }
             
            if(user.tipo === 'autoridad'){
                const toAdd = [...this.usersForm.value.auth, user.auto]
                this.usersForm.patchValue({auth: toAdd })
            }else if(user.tipo === 'employee'){
                const toAdd = [...this.usersForm.value.empl, user.empe]
                this.usersForm.patchValue({empl: toAdd })
                console.log('usuario agregado',this.usersForm)
            }else if(user.tipo === 'empleado'){
                const toAdd = [...this.usersForm.value.empl, user.empe]
                this.usersForm.patchValue({empl: toAdd })
                console.log(this.usersForm.markAllTouched())
            }else { //ciudadano
                const toAdd = [...this.usersForm.value.ciud, user.ciud]
                this.usersForm.patchValue({ciud: toAdd })
            }
            console.log('tipo de usuario seleccionado',user.tipo);
             if(user.tipo === 'actor'){
                const toAdd = [...this.usersForm.value.actor, user.id]
                this.usersForm.patchValue({actor: toAdd })
             }else if(user.tipo=='autoridadDemandada'){
                const toAdd = [...this.usersForm.value.autoridadDemandada, user.id]
                this.usersForm.patchValue({autoridadDemandada: toAdd })
             }else if(user.tipo=='tercero'){
                const toAdd = [...this.usersForm.value.tercero, user.id]
                this.usersForm.patchValue({tercero: toAdd })

             }else if(user.tipo=='representanteAutorizado'){
                const toAdd = [...this.usersForm.value.ra, user.id]
                this.usersForm.patchValue({ra: toAdd })

             }else if(user.tipo=='representanteLegal'){
                const toAdd = [...this.usersForm.value.rl, user.id]
                this.usersForm.patchValue({rl: toAdd })

             }

            console.log('  this.usersForm.',  this.usersForm);
        }else {
             switch(tipo){
                 case 'demandado':
                     this.removeItemvalidator(ind);
                     break;
                 case 'tercero':
                     this.removeItemvalidatortercero(ind);
                     break;
                 case 'rl':
                   this.removeItemvalidatorrl(ind);
                     break;
                 case 'ra':
                    this.removeItemvalidatorra(ind);
                    break;
                 case 'actor':
                    this.removeItemvalidatoractor(ind);
                    break;
                case 'autoridad':
                    console.log("removeItemvalidatorautoridad", this.fautoridad?.arr['controls'][ind]);
                    this.removeItemvalidatorautoridad(ind);
                    break;
                    case 'asesor':
                        this.removeItemvalidatorasesor(ind);
                        break;
             }  
                    
            if(user.tipo === 'autoridad'){
                const withOutElement = this.usersForm.value.auth.filter(e => e !== user.auto)
                this.usersForm.patchValue({auth: withOutElement})
            }else if(user.tipo === 'empleado'){
                const withOutElement = this.usersForm.value.empl.filter(e => e !== user.empe)
                this.usersForm.patchValue({emple: withOutElement})
            }else if(user.tipo === 'employee'){
                const toAdd = [...this.usersForm.value.empl, user.empe]
                this.usersForm.patchValue({empl: toAdd })
                console.log('usuario agregado',this.usersForm)
            }else {
                const withOutElement = this.usersForm.value.ciud.filter(e => e !== user.ciud)
                this.usersForm.patchValue({ciud: withOutElement})
            }
            const withOutElementU = this.usersForm.value.acueusa.filter(e => e !== user.nume)
            this.usersForm.patchValue({acueusa: withOutElementU })
            console.log('tipo de usuario seleccionado',user.tipo);
            if(user.tipo === 'actor'){
               const withOutElement = this.usersForm.value.actor.filter(e => e !== user.id)
               this.usersForm.patchValue({actor: withOutElement})
            }else if(user.tipo=='autoridadDemandada'){
               const withOutElement = this.usersForm.value.autoridadDemandada.filter(e => e !== user.id)
               this.usersForm.patchValue({autoridadDemandada: withOutElement})
            }else if(user.tipo=='tercero'){
               const withOutElement = this.usersForm.value.tercero.filter(e => e !== user.id)
               this.usersForm.patchValue({tercero: withOutElement})

            }else if(user.tipo=='representanteAutorizado'){
                const withOutElement = this.usersForm.value.ra.filter(e => e !== user.id)
                this.usersForm.patchValue({ra: withOutElement})
 
            }else if(user.tipo=='representanteLegal'){
                const withOutElement = this.usersForm.value.rl.filter(e => e !== user.id)
                this.usersForm.patchValue({rl: withOutElement})
 
             }  
        }
    }
    setDocuExpe(docu, event){
        if(event.checked){
            const toAdd = [...this.resolutionForm.value.docuExpe, docu.nume]
            this.resolutionForm.patchValue({docuExpe: toAdd});
        }else{
            const withOutElement = this.resolutionForm.value.docuExpe.filter(e=>e !== docu.nume)
            this.resolutionForm.patchValue({docuExpe: withOutElement});
        }
    }

    setDocuOfic(docu, event){
        if(event.checked){
            this.resolutionForm.patchValue({docuOfic: docu});
        }else{
            this.resolutionForm.patchValue({docuOfic: ''});
        }
        console.log("datos del formulario documentos", this.resolutionForm.value);
    }
    sendMail() {
        console.log("guardado de los datos");
        this.isLoading = true;
        this.txtErrorNoti = '';
        if(this.isAcuerdoNotification || this.isFisicNotificationAcue) { //notificaciones de acuerdos
            const notificationForm = this.isFisicNotificationAcue ? this._getFormData() : this._getFormDataAgreement();
            notificationForm.forEach((x, i) => console.log(i ,'->', x))
            if(this._data.acuerdo){

            this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                (result) => {
                    //Firma el documento generado
                    console.log(result);
                    this._firmarMinuta(result);
                    
                    
                },
                (error) => {
                    this._requestError(error)
                }
            );
        }else{
            this._expedientServices.sendNotificationSentence(notificationForm, this.agreementData.id).subscribe(
                (result) => {
                    //Firma el documento generado
                    console.log(result);
                    this._firmarMinuta(result);
                    
                    
                },
                (error) => {
                    this._requestError(error)
                }
            );
            }
        }else if(this.isNotificationSala) { //notificacion a sala
            const notificationForm = this._getFormDataSala();
            notificationForm.forEach((x, i) => console.log(i ,'->', x))
            this._expedientServices.sendNotificationSala(notificationForm, this.agreementData.nume).subscribe(
                (result) => {
                    //Firma el documento generado
                    console.log(result);

                    this._requestSuccess(result);
                },
                (error) => {
                    this._requestError(error);
                }
            );
        }else { //notificacion normal o fisica
            const notificationForm = this._getFormData();
            notificationForm.forEach((x, i) => console.log(i ,'->', x))
            this._expedientServices.sendNotification(notificationForm).subscribe(
                (result) => {
                    //Firma el documento generado
                    console.log(result);
                    this._requestSuccess(result);
                },
                (error) => {
                    this._requestError(error);
                }
            );
        }
        
    
    }

    //@ALAN FILTRO DETERMINACION NOTIFICADA
    _startChangesFilterDeterminacionNotificadas(){
        this.deterNotifi = this.resolutionForm.controls.tipo.valueChanges.pipe(
            startWith(''),
            map((valueInput) => this._filterDeterminacionNotificadas(valueInput))
        );
    }

    private _filterDeterminacionNotificadas(name): string[]{
        const filterValue = name.toLowerCase();
        return this.deterNotifiArray.filter(element => element.name.toLowerCase().includes(filterValue));
    }
    //@END ALAN FILTRO DETERMINACION NOTIFICADA

    //@ALAN GET ID DETERMINACION NOTIFICADA
  _getDeterNotifiId(){
    let tempDeterNotifi = this.resolutionForm.value.tipo;
      let tempResult:string;

      this.deterNotifiArray.forEach(element => {
        let flaj = false;
        if(element.name == tempDeterNotifi && flaj == false){
          tempResult = element.nume
        }
      });

      return tempResult;
  }
  //@END ALAN GET ID DETERMINACION NOTIFICADA

    _requestSuccess(result) {
        this.txtErrorFileadju = this.txtErrorFile;
        this.isLoading = false;
        this.matDialogRef.close(['send', 'Revisa tu historial de notificaciones para consultar las constancias'])
        console.log(result);
    }
    _firmarMinuta(result) {
       //Firma minuta
       console.log('resultado de notificacion',result);
       for(let i=0;i<result.length;i++){
        let tempFile = result[i].original.data.docu.split("/");
        //let fileLaravel =  await fetch('http://guerrero.test//notificaciones/sentencias/VbAXwOnn4vjlM2NuI90nZ5UQVkCCePBrLkjtWXgV.pdf', {method: 'GET', headers: ({'Content-type':'application/json','Authorization':'Bearer'+ sessionStorage.getItem('tk')})});
        console.log("tempfile", tempFile);
        this._documentService.getPublicFiles(tempFile[4], tempFile[5]).subscribe(
            response=>{
                //let blob = new Blob([response], {type: 'application/pdf'} )
                this.fileLaravel = new File([response], "prueba.pdf",{type:'application/pdf'});
                SelectFileSign(this.fileLaravel).then((isValidFile)=>{
                 if(isValidFile){
                     this.message = message;
                     this.extArchivoCargado = extFileUpload;
                     this.activarBoton=SelectPfx(this.documentofirma);
                     if(this.activarBoton){
                         this.password.enable();
                         this.filePFXselct=this.documentofirma;
 //se ejecuta codigo para firmar
 console.log('resultado de la firma',this.filePFXselct);
 this.firmar2(result[i].original.data);
                     }
                 }
             })
 
 
             //    this._expedientServices.SignMinuta(minutaele).subscribe(
             //     (result) => {
             //         //Firma el documento generado
             //         console.log(result);
                   
                     
             //     },
             //     (error) => {
             //         this._requestError(error)
             //     }
             // );
               
              
            },error=>{ 
                console.log("erroor get file", error);
            }
        );
       }
       setTimeout(() => {
        this._requestSuccess(result);

    }, 1000);
    }
    firmarminutadocu(data){
        this._expedientServices.SignMinuta(data).subscribe(
            (result) => {
                //Firma el documento generado
                console.log(result);
              
                
            },
            (error) => {
                this._requestError(error)
            }
        );
    }
    _requestError(error) {
        this.isLoading = false;
        this.txtErrorNoti = this._settings._getMsjError(error);
        console.log(error)
    }

    setInfoAgreement(){
        const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
        this.expedientForm.patchValue({
            une   : expe,
            acue  : expe,
        });
        this.parttercero=[];
        this.partdema=[];
        this._topicService.getOfficialExpedient(expe).subscribe(
            (response)=>{
                if(response != null){
                    if(this._data.acuerdo){
                    // this.expedientForm.get('dema').setValue(response['data']['us_auth_name'][0]);
                    // this.expedientForm.get('terc').setValue(response['data']['us_terc_name'][0] != undefined ? response['data']['us_terc_name'][0] : '');
                    this.acotor=[];
                    if(response['data']['terceros']!=null){
                        this.parttercero=response['data']['terceros'];

                    }
                    if(response['data']['demandado']!=null){
                        this.partdema=response['data']['demandado'];

                    }

                    }else{
                        console.log('sentencia',response);
                       
                    //this.expedientForm.get('part').setValue(response['data']['us_act'] != undefined ? response['data']['us_act'] : '');
                    this.acotor[0]=response['data']['us_act'];

                    }
                }
                console.log("obtener los datos del expediente", response);
            },(error)=>{

            }
        )

        if(!this.isFisicNotificationAcue){
            this.adjun.setValidators(null);
            this.adjun.updateValueAndValidity();
        }
        if(this._data.acuerdo){

        this.resolutionForm.patchValue({
            tipo  : 'Acuerdo',
            fecd  : this.agreementData.date,
            adju  : this.agreementData.docu,
        });}else{
            let tyse:any='';
            if(this.agreementData.tipo=="def"){
                tyse='Sentencia definitiva';
            }else{
                tyse='Sentencia interlocutoria';
            }
            this.resolutionForm.patchValue({
                tipo  : tyse,
                fecd  : this.agreementData.crtd,
                adju  : this.agreementData.docu,
            });
        }
        
    }

    _getFormData() {
        const notificationForm = new FormData();
        notificationForm.append('depa', this.expedientForm.value.depa);
        notificationForm.append('part', this.expedientForm.value.part);
        notificationForm.append('dema', this.expedientForm.value.dema);
        notificationForm.append('terc', this.expedientForm.value.terc);
        //notificationForm.append('tipo', this.resolutionForm.value.tipo) //=> elegir texto: acuerdo o resolución,
        notificationForm.append('tipo', this._getDeterNotifiId());
        notificationForm.append('adju', this.resolutionForm.value.adju) //=> Documento emitido,
        notificationForm.append('empl', this.currentUser.empe) //=> 'employee_id' Obtener id de empleado,
        notificationForm.append(`fisi`, (this.isFisicNotification || this.isFisicNotificationAcue) ? '1':'0');
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append('fecd', this.resolutionForm.value.fecd) //=> fecha en que fue dictado,
        notificationForm.append('expe_uni', this.expedientForm.value.expe_uni);
        notificationForm.append('usnotify', this.usersForm.value.usnotify);
        //type notification
        notificationForm.append('nottype', this._data.typeNotification);
        let une = this.expedientForm.value.une;
        let kien = 'ambos';
        if(this.isFisicNotificationAcue){
            kien = 'ninguno';
            une = this.agreementData.expe;
        }
        notificationForm.append('une',  une) //=> 'folio',
        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if(item.file !== undefined && item.desc !== '') {
            notificationForm.append(`dcan[${ index }]`, item.file);
            notificationForm.append(`dsdc[${ index }]`, item.desc);
            notificationForm.append(`tpdc[${ index }]`, 'file');
            notificationForm.append(`dcpa[${ index }]`, item.numPages);
            }
        });

        this.anexosArrayPK.forEach((item, index)=>{
            if(item !== undefined){
                notificationForm.append(`dcanEncode[${index}]`, item, 'pdfdeprueba.pdf')
            }
        });
        
        if (this.isFisicNotification || this.isFisicNotificationAcue) {
            notificationForm.append(`doc`, this.usersForm.value.usua.join(','));
            notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
            let timeStr = this.resolutionForm.value.time.split(' ')[0],
                time    = moment(timeStr, 'hh:mm');

            const date = this.resolutionForm.value.fecn.set({
                hour:   time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second')
            });

            notificationForm.append('feno', date.format("YYYY-MM-DD HH:mm:ss"));
        }else {
            const ciudadanos = this.usersForm.value.ciud;
            const autoridades = this.usersForm.value.auth;
            const employees   = this.usersForm.value.empl;
            const actor   = this.usersForm.value.actor;
            const tercero   = this.usersForm.value.tercero;
            const autoridadDemandada   = this.usersForm.value.autoridadDemandada;
            const ra   = this.usersForm.value.ra;
            const rl   = this.usersForm.value.rl;


            if(actor.length > 0) {  
                kien = 'empleado'    
                for (const key in actor) {
                    if (actor.hasOwnProperty(key)) {
                        notificationForm.append(`actorpart[${key}]`, actor[key])
                    }
                }
            }
            if(tercero.length > 0) {  
                kien = 'empleado'    
                for (const key in tercero) {
                    if (tercero.hasOwnProperty(key)) {
                        notificationForm.append(`terceroparts[${key}]`, tercero[key])
                    }
                }
            }
            if(autoridadDemandada.length > 0) {  
                kien = 'empleado'    
                for (const key in autoridadDemandada) {
                    if (autoridadDemandada.hasOwnProperty(key)) {
                        notificationForm.append(`autoridadDemandadapart[${key}]`, autoridadDemandada[key])
                    }
                }
            }
            if(ra.length > 0) {  
                kien = 'empleado'    
                for (const key in ra) {
                    if (ra.hasOwnProperty(key)) {
                        notificationForm.append(`representanteAutorizadopart[${key}]`, ra[key])
                    }
                }
            }
            if(rl.length > 0) {  
                kien = 'empleado'    
                for (const key in rl) {
                    if (rl.hasOwnProperty(key)) {
                        notificationForm.append(`representanteLegalpart[${key}]`, rl[key])
                    }
                }
            }
            
            if(ciudadanos.length > 0) {
                kien = 'ciudadano'
                for (const key in ciudadanos) {
                    console.log('key ciudadano',key);
                    if (ciudadanos.hasOwnProperty(key)) {
                        if(ciudadanos[key]!=undefined){
                        notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                        }
                    }
                }
            }
            if(autoridades.length > 0) {  
                kien = 'autoridad'    
                for (const key in autoridades) {
                    if (autoridades.hasOwnProperty(key)&& key!=undefined) {
                        if(autoridades[key]!=undefined){
                        notificationForm.append(`auth[${key}]`, autoridades[key])
                        }
                    }
                }
            }

            if(employees.length > 0) {  
                kien = 'empleado'    
                for (const key in employees) {
                    if (autoridades.hasOwnProperty(key)&& key!=undefined) {
                        if(employees[key]!=undefined){
                        notificationForm.append(`ases[${key}]`, employees[key])
                        }
                    }
                }
            }
                    //aqui va otro
            const otros = this.empForm.value.employees;
            if (otros.length > 0) {
                for (const key in otros) {
                    if (otros.hasOwnProperty(key) && key != undefined) {
                        if (otros[key] != undefined) {
                            notificationForm.append(`otropart[${key}]`,  this.empForm.value.employees[key]['id']);
                            notificationForm.append(`otronum[${key}]`, key);
                            notificationForm.append(`otronomb[${key}]`, this.empForm.value.employees[key]['firstName']);
                            notificationForm.append(`otrocorreo[${key}]`, this.empForm.value.employees[key]['lastName']);
                            notificationForm.append(`otrotipoparte[${key}]`, this.empForm.value.employees[key]['tipoparte']);
                        }
                    }
                }
            }
                    console.log('aqui va otro', this.empForm.value);
                    console.log(kien)
            if(ciudadanos.length > 0 && autoridades.length > 0) {
                kien = 'ambos'  
            }


            if(this.formGroup.value.arr.length > 0) {  
                for (const key in this.formGroup.value.arr) { 
                    console.log("idu",this.formGroupactor.value.arr[key]['idu']);
                    console.log("inci", this.formGroupactor.value.arr[key]);     
                        notificationForm.append(`dinci[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['inci']);
                        notificationForm.append(`dfracc[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['fracc']);
                        notificationForm.append(`dfech[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['fecd']);
                        notificationForm.append(`dtip[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['tipo']);    
                        notificationForm.append(`dtpc[${this.formGroup.value.arr[key]['idu']}]`, 'AL DEMANDADO');      
                        notificationForm.append(`darti[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['articu']);    
    
                }
            }
            if(this.formGrouprl.value.arr.length > 0) {  
                for (const key in this.formGrouprl.value.arr) {  
                    console.log("idu",this.formGroupactor.value.arr[key]['idu']);
                    console.log("inci", this.formGroupactor.value.arr[key]);    
                    notificationForm.append(`dinci[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['inci']);
                    notificationForm.append(`dfracc[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['fracc']);
                    notificationForm.append(`dfech[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['fecd']);
                    notificationForm.append(`dtip[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['tipo']);  
                    notificationForm.append(`dtpc[${this.formGrouprl.value.arr[key]['idu']}]`, 'AL REPRESENTANTE LEGAL');      
                    notificationForm.append(`darti[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['articu']);    
    
                }
            }
            if(this.formGroupra.value.arr.length > 0) {  
                for (const key in this.formGroupra.value.arr) { 
                    console.log("idu",this.formGroupactor.value.arr[key]['idu']);
                    console.log("inci", this.formGroupactor.value.arr[key]);     
                    notificationForm.append(`dinci[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['inci']);
                    notificationForm.append(`dfracc[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['fracc']);
                    notificationForm.append(`dfech[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['fecd']);
                    notificationForm.append(`dtip[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['tipo']);  
                    notificationForm.append(`dtpc[${this.formGroupra.value.arr[key]['idu']}]`, 'AL REPRESENTANTE AUTORIZADO');      
                    notificationForm.append(`darti[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['articu']);    
    
                }
            }
            if(this.formGrouptercero.value.arr.length > 0) {  
                for (const key in this.formGrouptercero.value.arr) {  
                    console.log("idu",this.formGroupactor.value.arr[key]['idu']);
                    console.log("inci", this.formGroupactor.value.arr[key]);    
                        notificationForm.append(`dinci[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['inci']);
                        notificationForm.append(`dfracc[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['fracc']);
                        notificationForm.append(`dfech[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['fecd']);
                        notificationForm.append(`dtip[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['tipo']); 
                        notificationForm.append(`dtpc[${this.formGrouptercero.value.arr[key]['idu']}]`, 'AL TERCERO PERJUDICADO');      
                        notificationForm.append(`darti[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['articu']);    
    
                }
            }
            if(this.formGroupactor.value.arr.length > 0) {  
                for (const key in this.formGroupactor.value.arr) {      
                    console.log("idu",this.formGroupactor.value.arr[key]['idu']);
                    console.log("inci", this.formGroupactor.value.arr[key]);

                        notificationForm.append(`dinci[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['inci']);
                        notificationForm.append(`dfracc[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['fracc']);
                        notificationForm.append(`dfech[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['fecd']);
                        notificationForm.append(`dtip[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['tipo']); 
                        notificationForm.append(`dtpc[${this.formGroupactor.value.arr[key]['idu']}]`, 'AL ACTOR');      
                        notificationForm.append(`darti[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['articu']);    
    
                }
            }
            if(this.formGroupautoridad.value.arr.length > 0) {  
                for (const key in this.formGroupautoridad.value.arr) {    
                    console.log("idu",this.formGroupactor.value.arr[key]['idu']);
                    console.log("inci", this.formGroupactor.value.arr[key]);  
                        notificationForm.append(`dinci[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['inci']);
                        notificationForm.append(`dfracc[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['fracc']);
                        notificationForm.append(`dfech[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['fecd']);
                        notificationForm.append(`dtip[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['tipo']); 
                        notificationForm.append(`dtpc[${this.formGroupautoridad.value.arr[key]['idu']}]`, 'A LA AUTORIDAD');      
                        notificationForm.append(`darti[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['articu']);    
    
                }
            }
            if(this.formGroupasesor.value.arr.length > 0) {  
                for (const key in this.formGroupasesor.value.arr) {  
                    console.log("idu",this.formGroupactor.value.arr[key]['idu']);
                    console.log("inci", this.formGroupactor.value.arr[key]);    
                        notificationForm.append(`dinci[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['inci']);
                        notificationForm.append(`dfracc[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['fracc']);
                        notificationForm.append(`dfech[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['fecd']);
                        notificationForm.append(`dtip[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['tipo']); 
                        notificationForm.append(`dtpc[${this.formGroupasesor.value.arr[key]['idu']}]`, 'AL ASESOR');      
                        notificationForm.append(`darti[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['articu']);    
                }
            }
        }

        //ESTRADOS
        let como = 'electronica';
        if(this.isFisicNotificationAcue) {
            como = 'fisica';
        }
        notificationForm.append('como', como);
        notificationForm.append('kien', kien);

        return notificationForm;
    }

    _getFormDataSala(){
        console.log('form data sala');
        const notificationForm = new FormData();
        notificationForm.append('depa', this.transferForm.value.depa);
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
         //set files in form data
        this.anexosArray.forEach((item, index) => {
            if(item.file !== undefined && item.desc !== '') {
            notificationForm.append(`dcan[${ index }]`, item.file);
            notificationForm.append(`dsdc[${ index }]`, item.desc);
            notificationForm.append(`tpdc[${ index }]`, 'file');
            notificationForm.append(`dcpa[${ index }]`, item.numPages);
            }
        });

        return notificationForm;
    }

    _getFormDataAgreement(){
        console.log('form data agreemement', this.expedientForm.value)
        const notificationForm = new FormData();
        notificationForm.append('part', this.expedientForm.value.part);
        notificationForm.append('dema', this.expedientForm.value.dema);
        notificationForm.append('terc', this.expedientForm.value.terc);
        notificationForm.append('fracc', this.resolutionForm.value.fracc);
        if(this.isShown){
            notificationForm.append('inci', this.resolutionForm.value.inci);
        }

        notificationForm.append('tipo', this.resolutionForm.value.tipo);
        notificationForm.append('docuExpe', this.resolutionForm.value.docuExpe);
        notificationForm.append('docuOfic', this.resolutionForm.value.docuOfic);
        notificationForm.append('empl', this.currentUser.empe);
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append('expe_uni', this.expedientForm.value.expe_uni);
        if(this.boletaForm){
            notificationForm.append('date_pub', this.boletaForm.value.date_pub);
        }
        notificationForm.append('dcanEncodeNoti', this.anexoMinutaNoti.file);
        notificationForm.append('dcanEncodeNotiDesc', this.anexoMinutaNoti.desc);
        notificationForm.append('dcanEncodeNotiDescPag', this.anexoMinutaNoti.numPages);
        if(this.anexoMinutaList){
            notificationForm.append('dcanEncodeList', this.anexoMinutaList.file);
            notificationForm.append('dcanEncodeListDesc', this.anexoMinutaList.desc);
            notificationForm.append('dcanEncodeListDescPag', this.anexoMinutaList.numPages);
        }
        if(this.anexoBoletin){
            notificationForm.append('dcanBoletin', this.anexoBoletin.file);
            notificationForm.append('dcanBoletinDesc', this.anexoBoletin.desc);
            notificationForm.append('dcanBoletinDescPag', this.anexoBoletin.numPages);
        }
        
        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if(item.file !== undefined && item.desc !== '') {
            notificationForm.append(`dcan[${ index }]`, item.file);
            notificationForm.append(`dsdc[${ index }]`, item.desc);
            notificationForm.append(`tpdc[${ index }]`, 'file');
            notificationForm.append(`dcpa[${ index }]`, item.numPages);
            }
        });

        //type notification
        notificationForm.append('nottype', this._data.typeNotification);
        /*this.anexosArrayPK.forEach((item, index)=>{
            if(item !== undefined){
                notificationForm.append(`dcanEncode[${index}]`, item, 'pdfdeprueba.pdf')
            }
        });*/

        const ciudadanos = this.usersForm.value.ciud;
        const autoridades = this.usersForm.value.auth;
        const asesores=this.usersForm.value.empl;
        
        let kien = 'ninguno';
        const actor   = this.usersForm.value.actor;
        const tercero   = this.usersForm.value.tercero;
        const autoridadDemandada   = this.usersForm.value.autoridadDemandada;
        const ra   = this.usersForm.value.ra;
        const rl   = this.usersForm.value.rl;

        if(actor.length > 0) {  
            kien = 'empleado'    
            for (const key in actor) {
                if (actor.hasOwnProperty(key)) {
                    notificationForm.append(`actorpart[${key}]`, actor[key])
                }
            }
        }
        if(tercero.length > 0) {  
            kien = 'empleado'    
            for (const key in tercero) {
                if (tercero.hasOwnProperty(key)) {
                    notificationForm.append(`terceroparts[${key}]`, tercero[key])
                }
            }
        }
        if(autoridadDemandada.length > 0) {  
            kien = 'empleado'    
            for (const key in autoridadDemandada) {
                if (autoridadDemandada.hasOwnProperty(key)) {
                    notificationForm.append(`autoridadDemandadapart[${key}]`, autoridadDemandada[key])
                }
            }
        }

        if(ra.length > 0) {  
            kien = 'empleado'    
            for (const key in ra) {
                if (ra.hasOwnProperty(key)) {
                    notificationForm.append(`representanteAutorizadopart[${key}]`, ra[key])
                }
            }
        }
        if(rl.length > 0) {  
            kien = 'empleado'    
            for (const key in rl) {
                if (rl.hasOwnProperty(key)) {
                    notificationForm.append(`representanteLegalpart[${key}]`, rl[key])
                }
            }
        }
        if(ciudadanos.length > 0) {
            kien = 'ciudadano'  
            for (const key in ciudadanos) {

                if (ciudadanos.hasOwnProperty(key)) {
                    console.log('key ciudadano',ciudadanos[key]);
                    if(ciudadanos[key]!=undefined){
                        notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                    }
                }
            }
        }
        if(autoridades.length > 0) {
            kien = 'autoridad'
            for (const key in autoridades) {
                if (autoridades.hasOwnProperty(key)&& key!=undefined) {
                    if(autoridades[key]!=undefined){
                    notificationForm.append(`auth[${key}]`, autoridades[key])
                    }
                }
            }
        }
        if(asesores.length > 0) {
            kien = 'asesor'  
            for (const key in asesores) {
                if (asesores.hasOwnProperty(key)&& key!=undefined) {
                    if(asesores[key]!=undefined){
                    notificationForm.append(`ases[${key}]`, asesores[key])
                    }
                }
            }
        }
         //aqui va otro
         const otros = this.empForm.value.employees;
         if (otros.length > 0) {
             for (const key in otros) {
                 if (otros.hasOwnProperty(key) && key != undefined) {
                     if (otros[key] != undefined) {
                         notificationForm.append(`otronum[${key}]`, key);
                         notificationForm.append(`otropart[${key}]`,this.empForm.value.employees[key]['id']);
                         notificationForm.append(`otronomb[${key}]`, this.empForm.value.employees[key]['firstName']);
                         notificationForm.append(`otrocorreo[${key}]`, this.empForm.value.employees[key]['lastName']);
                         notificationForm.append(`otrotipoparte[${key}]`, this.empForm.value.employees[key]['tipoparte']);
                     }
                 }
             }
         }
                 console.log('aqui va otro', this.empForm.value);
        if(ciudadanos.length > 0 && autoridades.length > 0) {
            kien = 'ambos'
        }
        notificationForm.append('kien', kien)

        //ESTRADOS
        let como = 'electronica';
        if(this._data.typeNotification === 'lista') {
            como = 'estrados';
            notificationForm.append('publ', this.boletaForm.value.date_pub)
            notificationForm.append(`doc`, this.usersForm.value.usua.join(','));
           // notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
        }
        notificationForm.append('como', como)
        if(this.formGroup.value.arr.length > 0) {  
            for (const key in this.formGroup.value.arr) {      
                    notificationForm.append(`dinci[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['inci']);
                    notificationForm.append(`dfracc[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['fracc']);
                    notificationForm.append(`dfech[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['fecd']);
                    notificationForm.append(`dtip[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['tipo']);    
                    notificationForm.append(`dtpc[${this.formGroup.value.arr[key]['idu']}]`, 'AL DEMANDADO');      
                    notificationForm.append(`darti[${this.formGroup.value.arr[key]['idu']}]`, this.formGroup.value.arr[key]['articu']);    

            }
        }
        if(this.formGrouprl.value.arr.length > 0) {  
            for (const key in this.formGrouprl.value.arr) {      
                notificationForm.append(`dinci[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['inci']);
                notificationForm.append(`dfracc[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['fracc']);
                notificationForm.append(`dfech[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['fecd']);
                notificationForm.append(`dtip[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['tipo']);  
                notificationForm.append(`dtpc[${this.formGrouprl.value.arr[key]['idu']}]`, 'AL REPRESENTANTE LEGAL');      
                notificationForm.append(`darti[${this.formGrouprl.value.arr[key]['idu']}]`, this.formGrouprl.value.arr[key]['articu']);    

            }
        }
        if(this.formGroupra.value.arr.length > 0) {  
            for (const key in this.formGroupra.value.arr) {      
                notificationForm.append(`dinci[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['inci']);
                notificationForm.append(`dfracc[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['fracc']);
                notificationForm.append(`dfech[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['fecd']);
                notificationForm.append(`dtip[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['tipo']);  
                notificationForm.append(`dtpc[${this.formGroupra.value.arr[key]['idu']}]`, 'AL REPRESENTANTE AUTORIZADO');      
                notificationForm.append(`darti[${this.formGroupra.value.arr[key]['idu']}]`, this.formGroupra.value.arr[key]['articu']);    

            }
        }
        if(this.formGrouptercero.value.arr.length > 0) {  
            for (const key in this.formGrouptercero.value.arr) {      
                    notificationForm.append(`dinci[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['inci']);
                    notificationForm.append(`dfracc[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['fracc']);
                    notificationForm.append(`dfech[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['fecd']);
                    notificationForm.append(`dtip[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['tipo']); 
                    notificationForm.append(`dtpc[${this.formGrouptercero.value.arr[key]['idu']}]`, 'AL TERCERO PERJUDICADO');      
                    notificationForm.append(`darti[${this.formGrouptercero.value.arr[key]['idu']}]`, this.formGrouptercero.value.arr[key]['articu']);    

            }
        }
        if(this.formGroupactor.value.arr.length > 0) {  
            for (const key in this.formGroupactor.value.arr) {      
                    notificationForm.append(`dinci[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['inci']);
                    notificationForm.append(`dfracc[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['fracc']);
                    notificationForm.append(`dfech[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['fecd']);
                    notificationForm.append(`dtip[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['tipo']); 
                    notificationForm.append(`dtpc[${this.formGroupactor.value.arr[key]['idu']}]`, 'AL ACTOR');      
                    notificationForm.append(`darti[${this.formGroupactor.value.arr[key]['idu']}]`, this.formGroupactor.value.arr[key]['articu']);    

            }
        }
        if(this.formGroupautoridad.value.arr.length > 0) {  
            for (const key in this.formGroupautoridad.value.arr) {      
                    notificationForm.append(`dinci[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['inci']);
                    notificationForm.append(`dfracc[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['fracc']);
                    notificationForm.append(`dfech[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['fecd']);
                    notificationForm.append(`dtip[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['tipo']); 
                    notificationForm.append(`dtpc[${this.formGroupautoridad.value.arr[key]['idu']}]`, 'A LA AUTORIDAD');      
                    notificationForm.append(`darti[${this.formGroupautoridad.value.arr[key]['idu']}]`, this.formGroupautoridad.value.arr[key]['articu']);    

            }
        }
        if(this.formGroupasesor.value.arr.length > 0) {  
            for (const key in this.formGroupasesor.value.arr) {      
                    notificationForm.append(`dinci[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['inci']);
                    notificationForm.append(`dfracc[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['fracc']);
                    notificationForm.append(`dfech[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['fecd']);
                    notificationForm.append(`dtip[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['tipo']); 
                    notificationForm.append(`dtpc[${this.formGroupasesor.value.arr[key]['idu']}]`, 'AL ASESOR');      
                    notificationForm.append(`darti[${this.formGroupasesor.value.arr[key]['idu']}]`, this.formGroupasesor.value.arr[key]['articu']);    

            }
        }
        return notificationForm;
    }

    /** Methods Files */
    setFiler(inputFileName, index) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
            const file = fileUpload.files[0];
            if(!this.validTypeSizeFiler(file, inputFileName)) {
                return
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.currentFile = file;
                this.addFile(inputFileName, index);
            };
            }
        };
        fileUpload.click();  
    }
    onSelectedChange(tipo){
console.log("tipo",tipo);
if(this.resolutionForm.value.fracc=='III'){
    console.log("se pone validator");
    this.isShown=true;
    this.resolutionForm.controls["inci"].setValidators([Validators.required]);
}else{
    console.log("se quita validator");
    this.isShown=false;

    this.resolutionForm.get('inci').clearValidators();               
}

this.resolutionForm.get('inci').updateValueAndValidity(); 

    }
    onSelectedChangeasesor(tipo,ind){
        if(this.formGroupasesor.value.arr[ind]['fracc']=='III' && this.formGroupasesor.value.arr[ind]['articu']=='30'){
            console.log("se pone validator");
            this.fasesor?.arr['controls'][ind]?.controls?.inci?.setValidators([Validators.required]);
        }else{
            console.log("se quita validator");        
            this.fasesor?.arr['controls'][ind]?.controls?.inci?.clearValidators();        
        }
        
        this.fasesor?.arr['controls'][ind]?.controls?.inci?.updateValueAndValidity(); 
        
    }
    onSelectedChangeautoridad(tipo,ind){
        if(this.formGroupautoridad.value.arr[ind]['fracc']=='III' && this.formGroupautoridad.value.arr[ind]['articu']=='30'){
            console.log("se pone validator");
            this.fautoridad?.arr['controls'][ind]?.controls?.inci?.setValidators([Validators.required]);
        }else{
            console.log("se quita validator");        
            this.fautoridad?.arr['controls'][ind]?.controls?.inci?.clearValidators();        
        }
        
        this.fautoridad?.arr['controls'][ind]?.controls?.inci?.updateValueAndValidity(); 
        
    }
    onSelectedChangeactor(tipo,ind){
        if(this.formGroupactor.value.arr[ind]['fracc']=='III' && this.formGroupactor.value.arr[ind]['articu']=='30'){
            console.log("se pone validator");
            this.factor?.arr['controls'][ind]?.controls?.inci?.setValidators([Validators.required]);
        }else{
            console.log("se quita validator");        
            this.factor?.arr['controls'][ind]?.controls?.inci?.clearValidators();        
        }
        
        this.factor?.arr['controls'][ind]?.controls?.inci?.updateValueAndValidity(); 
        
    }
    onSelectedChangedemandado(tipo,ind){
        console.log("tipo",this.f);
        if(this.formGroup.value.arr[ind]['fracc']=='III' && this.formGroup.value.arr[ind]['articu']=='30'){
            console.log("se pone validator");
            this.f?.arr['controls'][ind]?.controls?.inci?.setValidators([Validators.required]);
        }else{
            console.log("se quita validator");        
            this.f?.arr['controls'][ind]?.controls?.inci?.clearValidators();        
        }
        
        this.f?.arr['controls'][ind]?.controls?.inci?.updateValueAndValidity(); 
        
    }onSelectedChangetercero(tipo,ind){
        console.log("tipo",this.ftercero);
        if(this.formGrouptercero.value.arr[ind]['fracc']=='III' && this.formGrouptercero.value.arr[ind]['articu']=='30'){
            console.log("se pone validator");
            this.ftercero?.arr['controls'][ind]?.controls?.inci?.setValidators([Validators.required]);
        }else{
            console.log("se quita validator");        
            this.ftercero?.arr['controls'][ind]?.controls?.inci?.clearValidators();        
        }
        
        this.ftercero?.arr['controls'][ind]?.controls?.inci?.updateValueAndValidity(); 
        
    }
    onSelectedChangerl(tipo,ind){
        console.log("tipo",this.frl);
        if(this.formGrouprl.value.arr[ind]['fracc']=='III' && this.formGrouprl.value.arr[ind]['articu']=='30'){
            console.log("se pone validator");
            this.frl?.arr['controls'][ind]?.controls?.inci?.setValidators([Validators.required]);
        }else{
            console.log("se quita validator");        
            this.frl?.arr['controls'][ind]?.controls?.inci?.clearValidators();        
        }
        
        this.frl?.arr['controls'][ind]?.controls?.inci?.updateValueAndValidity(); 
        
    }
    onSelectedChangera(tipo,ind){
        console.log("tipo",this.f);
        if(this.formGroupra.value.arr[ind]['fracc']=='III' && this.formGroupra.value.arr[ind]['articu']=='30'){
            console.log("se pone validator");
            this.fra?.arr['controls'][ind]?.controls?.inci?.setValidators([Validators.required]);
        }else{
            console.log("se quita validator");        
            this.fra?.arr['controls'][ind]?.controls?.inci?.clearValidators();        
        }
        
        this.fra?.arr['controls'][ind]?.controls?.inci?.updateValueAndValidity(); 
        
    }
    
    validTypeSizeFiler(file, inputFileName) {
        const input = this.anexosArray.find(anexo => anexo.name === inputFileName);
        input.error = true;
        input.txtError = '';
        if(file.type !== 'application/pdf') {
            input.txtError = 'El archivo debe ser en formato PDF';
            return false;
        }
        if(file.size > 10000000) {
            input.txtError = 'El archivo debe ser de un tamaño no mayor a 10mb';
            return false;
        }
        input.txtError = 'Debes ingresar una descripción de documento';
        return true;
    }

    addFile(inputFileName, index) {
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        let file = this.anexosArray.find( file => file.name === inputFileName)
        file.file= this.currentFile,
        file.desc= 'anexo',
        file.txtBtnUp= this.currentFile.name.substr(0,15),
        file.size= this._bytesToSize(this.currentFile.size)
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }

    addDescFile(inputFileName, index){
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        if( typeFile.value !== '') {
            this.anexosArray[index].desc = typeFile.value;
            this.anexosArray[index].upload = true;
        }else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexosArray[index].upload = false;
        }
        //this.validFieldsUpload();
    }

    /**
     * ANEXO MINUTA NOTIFICACION
     * @param valueSelected 
     * @param index 
     */

     addMinutaNotiFile(inputFileName){
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        console.log("type file anexo noti", typeFile);
        if( typeFile.value !== '') {
            this.anexoMinutaNoti.desc = typeFile.value;
            this.anexoMinutaNoti.upload = true;
        }else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexoMinutaNoti.upload = false;
        }
        //this.validFieldsUpload();
    }

    /** Methods Files */
    setFilerMinutaNoti(inputFileName) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
            const file = fileUpload.files[0];
            if(!this.validTypeSizeFileMinutaNoti(file)) {
                return
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.currentFile = file;
                this.addFileMinutaNoti(inputFileName);
            };
            }
        };
        fileUpload.click();  
    }

    addFileMinutaNoti(inputFileName) {
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        let file = this.anexoMinutaNoti;
        file.file= this.currentFile,
        file.desc= typeFile.value,
        file.txtBtnUp= this.currentFile.name.substr(0,15);
        file.size= this._bytesToSize(this.currentFile.size);
        file.type = 'minuta';
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }

    
    validTypeSizeFileMinutaNoti(file) {
        if(file.type !== 'application/pdf') {
        this.anexoMinutaNoti.txtError = 'El archivo debe ser en formato PDF';
        return false;
        }
        if(file.size > 10000000) {
        this.anexoMinutaNoti.txtError = 'El archivo debe ser de un tamaño no mayor a 10mb';
        return false;
        }
        return true;
    }

    /**
     * ANEXO MINUTA LISTA
     * @param valueSelected 
     * @param index 
     */
    addMinutaListFile(inputFileName){
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        console.log("entra para insertar boletin", typeFile);

        if( typeFile.value !== '') {
            this.anexoMinutaList.desc = typeFile.value;
            this.anexoMinutaList.upload = true;
        }else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexoMinutaList.upload = false;
        }
        //this.validFieldsUpload();
    }
    addBoletinFile(inputFileName){
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        console.log("entra para insertar boletin", typeFile);

        if( typeFile.value !== '') {
            this.anexoBoletin.desc = typeFile.value;
            this.anexoBoletin.upload = true;
        }else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexoBoletin.upload = false;
        }
        //this.validFieldsUpload();
    }
    /** Methods Files */
    setFilerMinutaList(inputFileName) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
            const file = fileUpload.files[0];
            if(!this.validTypeSizeFileMinutaList(file)) {
                return
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.currentFile = file;
                this.addFileMinutaList(inputFileName);
            };
            }
        };
        fileUpload.click();  
    }
    setFilerBoletin(inputFileName) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        fileUpload.onchange = () => {   
            if(fileUpload.files && fileUpload.files.length) {
            const file = fileUpload.files[0];
            if(!this.validTypeSizeFileMinutaList(file)) {
                return
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.currentFile = file;
                this.addFileBoletin(inputFileName);
            };
            }
        };
        fileUpload.click();  
    }
    addFileMinutaList(inputFileName) {
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        let file = this.anexoMinutaList;
        file.file= this.currentFile,
        file.desc= typeFile.value,
        file.txtBtnUp= this.currentFile.name.substr(0,15),
        file.size= this._bytesToSize(this.currentFile.size)
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }
    addFileBoletin(inputFileName) {
        const typeFile = document.querySelector(`#${ inputFileName }desc`) as HTMLInputElement;
        let file = this.anexoBoletin;
        file.file= this.currentFile,
        file.desc= typeFile.value,
        file.txtBtnUp= this.currentFile.name.substr(0,15),
        file.size= this._bytesToSize(this.currentFile.size)
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }

    
    validTypeSizeFileMinutaList(file) {
        if(file.type !== 'application/pdf') {
        this.anexoMinutaList.txtError = 'El archivo debe ser en formato PDF';
        return false;
        }
        if(file.size > 10000000) {
        this.anexoMinutaList.txtError = 'El archivo debe ser de un tamaño no mayor a 10mb';
        return false;
        }
        return true;
    }

    setTypeFile(valueSelected, index){
        this.anexosArray[index].type = valueSelected.value;
    }
    
    removeFiler(indexFile){
        if(this.anexosArray.length > 1 ) {
            this.anexosArray.splice(indexFile, 1);
            this.validFieldsUpload();
        }else {
            this.anexosArray[indexFile] = new FileAnexo(`anexoFile${ indexFile }`); 
            this.showMoreFileBtn = false;
            this.resolutionForm.patchValue({ anexos: false });
        }
    }

    validFieldsUpload() {
        const anexosValid = this.anexosArray.filter(f => f.file && f.desc !== '');
        const anexosNotValid = this.anexosArray.filter(f => f.file && f.desc === '');
        if(anexosValid.length > 0 && anexosNotValid.length === 0){
            this.resolutionForm.patchValue({ anexos: true });
            this.toUploadOk = true;
            anexosValid.forEach(anexo => { anexo.error = false })
            this.showMoreFileBtn = true;
        }else {
            this.toUploadOk = false;
            this.resolutionForm.patchValue({ anexos: false });
            anexosNotValid.forEach(anexo => { anexo.error = true })
        }
    }

    addOther(){
        this.showMoreFileBtn = false;
        const id = this.anexosArray.length;
        if(id < 5){
            this.anexosArray.push(new FileAnexo(`anexoFile${ id }`));
        }
    }

    resetInputFile(inputFileName){
        const file = document.querySelector(`#${ inputFileName }`) as HTMLInputElement;
        this.currentFile = undefined;
        file.value = '';
    }

    _bytesToSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    _getNumPages(file, fileObject) {
        //read num pages pdf
        let count = '';
        var read = new FileReader();
        read.readAsBinaryString(file);
        read.onload = function(){
            count = read.result.toString().match(/\/Type[\s]*\/Page[^s]/g).length.toString();
            fileObject.numPages = count;
        }
    }
      /**END FILES */

        /**
     * Add user
     *
     * @param {MatChipInputEvent} event
     */
    addUser(event: MatChipInputEvent): void
    {
        const input = event.input;
        const value = event.value;

        // Add user
        if ( value )
        {
            this.usersForm.value.usua.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     *  Filer Datepicker
     * @param date 
     */
    //datepicker filter
    myFilterDatePicker = (date: moment.Moment | null): boolean => {
        const day = date !== null ? date.day() : new Date().getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    };

    validAddMoreDays(date, tipo){
        const day = date.day();
        let addDays = 0;
        if(tipo == 'min') {
            if(day === 5){
                addDays = 3
            }
            if(day === 6){
                addDays = 2
            }
            addDays = 1
        }else {
            if(day > 1 && day < 6){
                addDays = 6
            }
            if(day === 6){
                addDays = 5
            }
            addDays = 4
        }
        //[matDatepickerFilter]="myFilterDatePicker"
        return date.date() + addDays;
    }

    validUsersForm() {
        const validCiud = (this.usersForm.value.ciud.length === 0);
        const validAuth = (this.usersForm.value.auth.length === 0);
        const validEmpl = (this.usersForm.value.empl.length === 0);
        const validUser = (this.usersForm.value.usua.length === 0);
        if(!this.isFisicNotificationEstrados && (validCiud && validAuth && validUser && validEmpl)){
            return true;
        }else if(this.isFisicNotificationEstrados && this.fepu.invalid){
            return true;
        }
        return false;
    }
    validInactiveSendBtn() {
        if(!this.isNotificationSala) {
            return (this.resolutionForm.invalid || this.expedientForm.invalid || (this.boletaForm && this.boletaForm.invalid) ||this.validUsersForm() || this.validarformsdin() || this.validatorotro());
        }else {
            return (this.transferForm.invalid);
        }
    }

    /**
     * Remove user
     *
     * @param user
     */
    removeUser(user): void
    {
        const index = this.usersForm.value.usua.indexOf(user);

        if ( index >= 0 )
        {
            this.usersForm.value.usua.splice(index, 1);
        }
    }

    get adjun() {
        return this.resolutionForm.get('adju') as FormControl;
    }

    get fepu() {
        return this.usersForm.get('disp') as FormControl;
    }

    //FIRMA ELECTRONICA
    SeleccionarPfx(filePFX: any) {
        this.documentofirma=filePFX;
        let tempFile;
        if(this._data.acuerdo){
         tempFile = this.agreementData.docu.split("/");

        }else{
            tempFile = this.agreementData.link.split("/");

        }
         //let fileLaravel =  await fetch('http://guerrero.test//notificaciones/sentencias/VbAXwOnn4vjlM2NuI90nZ5UQVkCCePBrLkjtWXgV.pdf', {method: 'GET', headers: ({'Content-type':'application/json','Authorization':'Bearer'+ sessionStorage.getItem('tk')})});
         console.log("tempfile", tempFile);
         this._documentService.getPublicFiles(tempFile[4], tempFile[5]).subscribe(
             response=>{
                 //let blob = new Blob([response], {type: 'application/pdf'} )
                 this.fileLaravel = new File([response], "prueba.pdf",{type:'application/pdf'});
                 SelectFileSign(this.fileLaravel).then((isValidFile)=>{
                     if(isValidFile){
                         this.message = message;
                         this.extArchivoCargado = extFileUpload;
                         this.activarBoton=SelectPfx(filePFX);
                         if(this.activarBoton){
                             this.password.enable();
                             this.filePFXselct=filePFX;
                         }
                     }
                 })
                 //let url = window.URL.createObjectURL(blob);
                 //let pwa = window.open(url);
             },error=>{ 
                 console.log("erroor get file", error);
             }
         );
    }
    SeleccionarPfxmal(filePFX: any){
        /*this.anexosArray.forEach(element => {
        if(element.file !== undefined){
            SelectFileSign(element.file).then((isValidFile)=>{
            if(isValidFile){
                this.message = message;
                this.extArchivoCargado = extFileUpload;
                this.activarBoton=SelectPfx(filePFX);
                if(this.activarBoton){
                    this.password.enable();
                    this.filePFXselct=filePFX;
                }
            }
            });
        }
        });*/
        this.password.enable();

        SelectFileSign(this.anexoMinutaNoti.file).then((isValidFile)=>{
            if(isValidFile){
                this.message = message;
                this.extArchivoCargado = extFileUpload;
                this.activarBoton=SelectPfx(filePFX);
                if(this.activarBoton){
                    this.password.enable();
                    this.filePFXselct=filePFX;
                }
            }
        })
    }

    firmar(){
        OpenPfx(this.filePFXselct, this.password.value).then((isCreatePKCS7)=>{
            if(isCreatePKCS7){ 
                this.pkcs7=null;                          
                this.pkcs7=PKCS7creado;
                this.saveTextAsFile(this.pkcs7);
            }
        },(error)=>{
            this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            duration        : 3000
            });
        });
    }
    firmar2(result){
        OpenPfx(this.filePFXselct, this.password.value).then((isCreatePKCS7)=>{
            if(isCreatePKCS7){ 
                this.pkcs7=PKCS7creado;
                console.log(' this.pkcs7', this.pkcs7[0]);
                this.saveTextAsFile2(result,this.pkcs7);
            }
        },(error)=>{
            this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            duration        : 3000
            });
        });
    }
    saveTextAsFile2(result,element:any)
        {         
            const minutaele = new FormData();
            let blobAsFile;
            this.anexosArrayPK = [];
            element.forEach(e => {
                console.log("file encripted sign", e);

            var fecha=new Date();
        
            let textToWrite = e;
            let textFileAsBlob = new Blob([textToWrite], {type:'text/plain'});
            //let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "."+this.extArchivoCargado;
            let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "."+this.extArchivoCargado;
            /*var byteCharacters = atob(e);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: "application/pdf"});
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');*/

            blobAsFile = new File([textFileAsBlob], fileNameToSaveAs);
          
            /*let tempItemAnexo = {
            blobAsFile,
            }*/
            //this.anexosArrayPK.push(blobAsFile);
                        
            //saveAs(textFileAsBlob, fileNameToSaveAs);
            });
           
            setTimeout(() => {
                minutaele.append('datos', result.nume);
                minutaele.append('docu', blobAsFile);
                        this._expedientServices.SignMinuta(minutaele).subscribe(
                            (result) => {
                                //Firma el documento generado
                                console.log(result);
                              
                                
                            },
                            (error) => {
                                this._requestError(error)
                            }
                        );
            }, 1000);
            //this.sendData();
        }
    //END FIRMA ELECTRONICA

    saveTextAsFile(element:any)
        {         
            this.anexosArrayPK = [];
            console.log("file encripted sign", element);
            element.forEach(e => {
            var fecha=new Date();
        
            let textToWrite = e;
            let textFileAsBlob = new Blob([textToWrite], {type:'text/plain'});
            //let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "."+this.extArchivoCargado;
            let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "."+this.extArchivoCargado;
            /*var byteCharacters = atob(e);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: "application/pdf"});
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');*/

            let blobAsFile = new File([textFileAsBlob], fileNameToSaveAs);
            /*let tempItemAnexo = {
            blobAsFile,
            }*/
            //this.anexosArrayPK.push(blobAsFile);
            this.anexoMinutaNoti.file = blobAsFile;
            //saveAs(textFileAsBlob, fileNameToSaveAs);
            });
            this.sendMail();
            //this.sendData();
        }
    //END FIRMA ELECTRONICA

    employees1(): FormArray {
        return this.empForm.get("employees") as FormArray
      }
     
     
      newEmployee(): FormGroup {
        return this.fb.group({
           id:[''],
          firstName:['', Validators.required],
          tipoparte:['', Validators.required],
          lastName: ['',[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        })
      }
     
     
      addEmployee() {
        console.log("Adding a part otro");
        this.employees1().push(this.newEmployee());
      }
     
     
      removeEmployee(empIndex:number) {
        this.employees1().removeAt(empIndex);
      }
     
      onSubmit1() {
        console.log(this.empForm.value);
      }
      validatorotro()
      {
        let ban=false;
        const controls = this.empForm.controls;
       

    if(controls!=null){
        for (const name in controls) {
            if (controls[name].invalid) {
        ban=true;         
        }
        }
    }
    return ban;
      }


      showPassword($event): void {
        $event.stopPropagation();
        this.typePassword = 'password';
        this.txt_tooltip_pass = 'mostrar contraseña';
        if(!this.showPass) {
            this.typePassword = 'text';
            this.txt_tooltip_pass = 'ocultar contraseña';
        }
        this.showPass = !this.showPass;
      }
}

const ArrayFiles = [
    new FileAnexo('anexoFile0'),
]; function _compare_item(a, b){
    // a should come before b in the sorted order
    if(a.name < b.name){
            return -1;
    // a should come after b in the sorted order
    }else if(a.name > b.name){
            return 1;
    // and and b are the same
    }else{
            return 0;
    }
}
