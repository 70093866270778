<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
            <span class="title dialog-title"> Envío de Actas/Constancias </span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">

        <form fxLayout="column" [formGroup]="expedientForm">
            <ng-template matStepLabel>EXPEDIENTE</ng-template>
            <div fxFlex="1 0 auto" fxLayout="column">
                <!--FORMULARIO NOTIFICACION ELECTRONICA EN JUICIO EN LINEA-->
                <!--numero de expediente automatico-->
                <div fxLayout="row" fxFlex="50" >
                    <mat-form-field appearance="outline">
                        <mat-label>Número de Expediente</mat-label>
                        <!-- <input matInput formControlName="acue" (blur)="_getAgreementData($event)" [disabled]="true"
                            required> -->
                            <input matInput formControlName="acue"  [disabled]="true"
                            required>
                            <mat-error *ngIf="expedientForm.get('acue').hasError('required')">Ingrese un número de expediente</mat-error>
                    </mat-form-field>
                    
             

                    <button mat-fab color="primary" aria-label="buscar expediente botton" (click)="buscarExpediente(expedientForm.get('acue').value)" [disabled]="expedientForm.get('acue').hasError('required')">
                        <mat-icon>search</mat-icon>
                      </button>
              
                </div>
           
               
                <mat-error *ngIf="!agreementDataLoaded && buscarFirstClicked">No existen registros con el número de expediente proporcionado</mat-error>

                <div *ngIf="agreementDataLoaded && acuerdo!=null">
                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Expediente
                            </mat-panel-title>
                            <mat-panel-description>
                              {{acuerdo.une}}
                              <mat-icon class="green-fg no-print">history</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                      
                          <div class="todo-content" fxLayout="column" fxLayoutAlign="space-evenly center">

                              <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                                  <div class="content p-24" fusePerfectScrollbar id="print-section">

                                      <div class="file-details">
                                          <table>
                                              <tr class="type"
                                                  *ngIf="acuerdo.stat.handle == 'turnado_actuario_secretario'">
                                                  <th>Observación:</th>
                                                  <td>{{acuerdo.observacion}}</td>
                                              </tr>

                                              <tr *ngIf="acuerdo.toca !== ''">
                                                  <th>Número de Toca:</th>
                                                  <td>{{ acuerdo.toca }}</td>
                                              </tr>

                                              <tr>
                                                  <th>Número de Expediente:</th>
                                                  <td>{{ acuerdo.une }}</td>
                                              </tr>
                                              <tr>
                                                  <th>Número Único:</th>
                                                  <td>{{ acuerdo.nume }}</td>
                                              </tr>
                                              <tr class="type">
                                                  <th>Folio electrónico:</th>
                                                  <td>{{ acuerdo.ref }}</td>
                                              </tr>

                                              <tr class="type" *ngIf="acuerdo.stat.handle == 'rechazado'">
                                                  <th>Expediente:</th>
                                                  <td>{{acuerdo.expe}}</td>
                                              </tr>

                                              <tr class="location">
                                                  <th>Sala Regional :</th>

                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Acapulco'">
                                                      Acapulco</td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Acapulco I'">
                                                      Acapulco I
                                                  </td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Acapulco II'">
                                                      Acapulco II
                                                  </td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Altamirano'">
                                                      Altamirano
                                                  </td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Chilpancingo'">
                                                      Chilpancingo
                                                  </td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Iguala'">
                                                      Iguala</td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Ometepec'">
                                                      Ometepec</td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Tlapa'">
                                                      Tlapa</td>
                                                  <td
                                                      *ngIf=" acuerdo.empldata.data.depadata.data.depa=='Sala Regional Zihuatanejo'">
                                                      Zihuatanejo
                                                  </td>
                                              </tr>

                                              <tr class="size">
                                                  <th>Estatus:</th>
                                                  <td>
                                                      <mat-icon class="green-fg no-print">history</mat-icon>
                                                      Notificado
                                                  </td>
                                              </tr>
                                              <tr class="" *ngIf="typeUser != 'Proyectista'">
                                                  <th>Acuerdo: </th>
                                                  <td>
                                                      <a mat-button color="primary" href="{{acuerdo.docu }}"
                                                          target="_blank">
                                                          Ver documento
                                                      </a>
                                                  </td>
                                              </tr>
                                              <tr class="size">
                                                  <th>Fecha del acuerdo:</th>
                                                  <td>{{getOnlyDate(acuerdo.date)}}</td>
                                              </tr>

                                              <tr class="size" *ngIf="acuerdo.fecha_turno_actuario">
                                                  <th>Fecha de turno al actuario:</th>
                                                  <td>{{acuerdo.fecha_turno_actuario}}</td>
                                              </tr>



                                              <tr class="owner" *ngIf="acuerdo.empldata != undefined">
                                                  <th>Servidor público TJAGRO: </th>
                                                  <td>{{ acuerdo.empldata.data.usuadata.data.nomb }}
                                                      {{ acuerdo.empldata.data.usuadata.data.pate }}
                                                      {{ acuerdo.empldata.data.usuadata.data.mate }}</td>
                                              </tr>

                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      
                        </mat-expansion-panel>
                      </mat-accordion>
                    <div fxLayout="column" fxFlex>
                        <input type="file" #docu id="docu" name="docu" style="display:none;" accept="application/pdf"
                            required />
                        <button mat-raised-button color="primary" (click)="setFileForm('docu')" class="upload-button">
                            <mat-icon class="button-icon">backup</mat-icon>
                            <span>Subir acta/constancia</span>
                        </button>
                    </div>

                    <div fxLayout="column" fxFlex="100">
                        <div class="attachment-list" *ngIf="expedientForm.value.docu.name !== undefined">
                            <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center">
                                <div>
                                    <span class="filename">{{ expedientForm.value.docu.name }}</span>
                                </div>
                                <div fxLayout="row" fxLayoutGap="10px">
                                    <button mat-icon-button aria-label="Preview attachment"
                                        (click)="previewFile('docu')" tooltip="Ver archivo" class="view-button">
                                        <mat-icon class="button-icon">visibility</mat-icon>
                                    </button>
                                    <button mat-icon-button aria-label="Delete attachment"
                                        (click)="removeFileForm('docu')" tooltip="Quitar archivo" class="delete-button">
                                        <mat-icon class="button-icon">close</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row" class="mt-12" fxLayoutAlign="center center">
                        <div class="col-sm-3">
                            Cargar archivo FIREL <i class="icono fas fa-upload"></i>
                            <input type="file" id="pfx" accept=".pfx" class="upload-input"
                                (change)="SeleccionarPfx($event.target)" />
                            <div id="divArchivo" class="textoArchivo"></div>
                            <div id="divCargaFirel" class="cargaFirel">
                            </div>
                            <br>
                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>Escriba su contraseña:</mat-label>
                                <input matInput [type]="typePassword" formControlName="password" />
                                <mat-icon matSuffix style="cursor: pointer;" (click)="showPassword($event)" [title]="txt_tooltip_pass">visibility</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" class="mt-12" fxLayoutAlign="center center">
                        <mat-form-field appearance="outline" fxFill>
                            <mat-label>Descripción del acta:</mat-label>
                            <input matInput #arti name="arti" formControlName="arti"
                                placeholder="Escribe una Descripción del acta" required />
                        </mat-form-field>
                    </div>
                    
                </div>
            </div>
            
        </form>
    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">


        <button mat-icon-button (click)="dialogRef.close()" aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
        <div fxLayout="row" fxLayoutAlign="center center"*ngIf="agreementDataLoaded">
            <button mat-raised-button type="button" color="accent" (click)="enviarActuarioSecretario()" [disabled]="(!expedientForm.valid) || isLoading">
                Continuar
                <div fxLayout="column" fxLayoutAlign="center center " *ngIf="isLoading">
                    <mat-progress-spinner diameter="25" mode="indeterminate" [value]="valueLoading">
                    </mat-progress-spinner>
                </div>
            </button>
        </div>
    </div>
</div>