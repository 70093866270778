import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { AppConfigService } from './data/app-config.service';
import { Router } from '@angular/router';
import {
    TipoSentido,
    AcuerdoSentidoOptions,
    AditionalOptions,
    SubsentidoOptions,
    TipoSentidoSecretarioGeneral,
    AcuerdoSentidoOptionsSecretarioGeneral,
    AditionalOptionsId,
} from "app/main/promotions/demanda-form/options.data.form";

import {SecretarioRecursoOptions} from "app/main/agreement-form/secretario-recuso.options";
import {SecretarioAmparoOptions} from "app/main/agreement-form/secretario-amparo.options";
import {SecretarioEjecucionOptions} from "app/main/agreement-form/secretario-ejecucion.options";
@Injectable({providedIn: 'root'})
export class ResourcesProvider {
    private urlApi = '';
    private ruta = '';
    constructor(private httpClient: HttpClient, private settings: AppConfigService, private _router: Router) {
        this.urlApi = this.settings.getUrlApi();
        this.ruta = this._router.url.split("/", 3)[2];
    }

    getJobs(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'jobs', httpOptions);
    }

    getAcuseRecibo(noti, citi){
        const httpOptions = this.settings.getHeadersMultipart();
        return this.httpClient.get(this.urlApi+'user-acuse/'+noti+'/'+citi,  {headers: new HttpHeaders()
            .set('Content-Type', 'application/json' )
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk')), responseType: 'arraybuffer'});
    }

    getEntities(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'entities', httpOptions);
    }

    getDirections(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'directions', httpOptions);
    }
    
    getDepartments(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'departments', httpOptions);
    }

    getMunicipalities(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'advisories/municipalities', httpOptions);
    }

    getMunicipalitiesRegister(){
        const tokenGeneric = 'eyJhdWQiOiIxIiwianRpIjoiNmE4YmQwZDUxNzViNmI4ZGJlZWM5NDk1ODllZjNmZWFmNDQ2OTNjMWRkNTA5ZTIwMDNlNzA1NDVmYmMxMWQ0ZTI3NjNjN2FmNDU3NzgwNWQiLCJpYXQiOjE2MDUwNjAzODYsIm5iZiI6MTYwNTA2MDM4NiwiZXhwIjoxNjM2NTk2Mzg2LCJzdWIiOiIxNDIiLCJzY29wZXMiOltdfQ';
        sessionStorage.setItem('tk', tokenGeneric);
        const httpOptions = this.settings.getHeadersJson();

        return this.httpClient.get(this.urlApi + 'advisories/municipalities', httpOptions);
    }

    getNamesRegister(id_municipio, tipo){
        const httpOptions = {
            headers: new HttpHeaders()
                        .set('Content-Type', 'application/json' )
        };
        return this.httpClient.get(this.urlApi + `entities?muni=${id_municipio}&tipo=${tipo}`, httpOptions);
    }

    getAuthoritieById(id_authoritie){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'authorities/' + id_authoritie, httpOptions);
    }

    getDesignersByDepartment(depa){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi+'employees/'+depa+'/designers', httpOptions);
    }

    getUserByExpediente(params){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + `registrations?stat=aceptado&folio=${params.folio}&depa=${params.depa}`)
    }

    getAgreementByExpediente(params){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'agreements?une='+params+'&tipo=normal', httpOptions);
    }
    getExpedientebyNumeroExpediente(params,departament){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'expedientsearch?expe='+params+'&depa='+departament, httpOptions);
    }
    getDocByUrl(url){
        const httpOptions = this.settings.getHeadersBlob(); 
        return this.httpClient.get(url, httpOptions)
    }

    getCaracterReconocido(type) {
        const types = [
                {value:"parte", label: "Actor"},
                {value:"tercero", label: "Tercero perjudicado"},
                //{value:"apoderado legal", label: "Apoderado legal"},
                {value:"autorizado", label: "Representante autorizado"},
                {value:"representante legal", label: "Representante legal"}, 
                {value:"autoridad", label: "Autoridad demandada"}        
            ]
        const typeFound = types.find(t => t.value === type);
        if(typeFound !== undefined) {
            return  typeFound.label;
        }

        return type;
    }

    getUrlDocuments(data){
        //set url production to get files to sign
        let production = false;
        let url = data.split("/");
        let docuObject = {
            folder:'',
            docu: ''
        }
        if(production){
            docuObject = {
                folder: url[4],
                docu: url[5]
            }
        }else{
            docuObject = {
                folder: url[5],
                docu: url[6]
            }
        }

        return docuObject;
    }

    getCaracterReconocidoByType(to = 'promotions') {
        let actor

        if(this.settings.getCurrentUser().tipo === 'perito'){
            let caracter = [
                {value:"perito actor", label: "Perito del Actor"},
                {value:"perito autoridad demandada", label: "Perito de la Autoridad Demandada"},
                {value:"perito tercero", label: "Perito del Tercero Perjudicado"},
                {value:"perito tercero discordia", label: "Perito Tercero en Discordia"},
                { value:"otro", label: "Otro"},

              
                ]
            return caracter;    
        }else if(this.settings.getTypeEmployee() == 'Asesor ciudadano'){
            let caracter = [
                {value: 'representante autorizado actora', label: 'Representante legal'},
                {value: 'representante autorizado tercero', label: 'Representante autorizado'},
                { value:"otro", label: "Otro"},

            ]
            return caracter;
        }else if(this.settings.getCurrentUser().tipo == 'autoridad'){
            let caracter = [
                {promo: 'nulidad', to_user:'', value:"demandado", label: "Demandado"},
                {promo: 'lesividad', to_user:'autoridad', value: 'actor', label: 'Actor'},
                {promo: 'lesividad administrativa grave',to_user:'', value: 'actor', label: 'Actor (Autoridad Substanciadora)'},

                {promo: 'nulidad lesividad administrativa grave', to_user:'autoridad', value:"tercero", label: "Tercero perjudicado"},
                {promo: 'administrativa nograbe', to_user:'', value:'recurrente', label: "Recurrente (Autoridad Investigadora)"},
                {promo: 'nulidad lesividad administrativa grave nograbe', to_user:'autoridad', value:"representante legal", label: "Representante legal"},
                {promo: 'nulidad lesividad administrativa grave nograbe', to_user:'autoridad', value:"representante autorizado", label: "Representante autorizado"},
                {promo: 'nulidad grave nograbe', value:"otro", label: "Otro"},

            ];
        
            return caracter;
        }else{
            let caracter = [
                // {value:"parte", label: "Actor"},
                {promo: 'nulidad', value:"parte", label: actor=(this.settings.getCurrentUser().tipo === 'autoridad')? "Actor (juicio de lesividad)":"Actor"},
                {promo: 'nograbe', value:"recurrente", label: "Recurrente (Denunciante)"},
                {promo: 'nulidad', value:"demandado", label: "Demandado (Juicio de Lesividad)"},
                {promo: 'grave', value:"demandado", label: "Demandado (Servidor Público Responsable o Particular Demandado)"},
                

                //{value:"apoderado legal", label: "Apoderado legal"},
                {promo: 'nulidad grave nograbe', value:"representante legal", label: "Representante legal"},
                {promo: 'nulidad grave nograbe', value:"representante autorizado", label: "Representante autorizado"},
                {promo: 'nulidad grave', value:"tercero", label: "Tercero perjudicado"},
                {promo: 'nograbe', value:"tercero", label: "Tercero perjudicado (Servidor Público Responsable o Particular Demandado)"},
                {promo: 'nulidad grave nograbe', value:"otro", label: "Otro"},
      

            ]
            if(this.settings.getCurrentUser().tipo === 'autoridad') {
                //caracter.splice(1,1);
                caracter = [...caracter, {promo: 'nulidad', value:"autoridad", label: "Autoridad demandada"}, ].reverse()
            }else if(to === 'solicitud'){
                caracter = [...caracter, {promo: 'nulidad', value:"autorizado", label: "Representante autorizado"} ]
            }
            return caracter;
        }
        
    }

    capitalize_Words(str){
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }
}

export const TypeCaracter = [
    //{value:"parte", to_user:"autoridad", label: "Actor (juicio de lesividad)"},
    {value: 'actor', label:'Actor'},
    {value:"parte", label: "Actor (juicio de lesividad)"},
    {value:"recurrente", label: "Recurrente (Denunciante)"},
    {value:"demandado", label: "Demandado"},
    {value:"tercero", label: "Tercero perjudicado"},
    //{value:"apoderado legal", label: "Apoderado legal"},
    {value:"representante legal", label: "Representante legal"},
    {value:"representante autorizado", label: "Representante autorizado"},
]

export const TypeFiltersAgreements = [
    {handle: 'pendiente', name:'En revisión', icon: 'history', class:'orange-fg'},
    {handle: 'corregir', name:'En corrección', icon: 'history', class:'red-fg'},
    {handle: 'firmado_magistrado', name:'Pendiente por firmar', icon: 'history', class:'purple-fg'},
    {handle: 'firmado_secretario', name:'Firmado/acordado', icon: 'history', class:'green-fg'},
    {handle: 'notificada', name:'Notificado', icon: 'check_circle', class:'blue-fg'},
    {handle: 'notificado', name:'Para Boletín', icon: 'check_circle', class:'green-fg'},
    {handle: 'turnado_actuario_secretario', name:'Devuelto por Actuario', icon: 'history', class:'indigo-fg'},

]

export const TypeFiltersAgreementsSecretarioGeneral = [
    {handle: 'atendido_adjunto', name:'Pendiente de revisión', icon: 'history', class:'purple-fg'},
    {handle: 'pendiente', name:'En revisión de presidencia', icon: 'history', class:'orange-fg'},
 /* {handle: 'pendiente', name:'En revisión presidencia', icon: 'history', class:'orange-fg'}, */
    {handle: 'asignado_pleno', name:'En firma del pleno', icon: 'history', class:'blue-fg'},
    {handle: 'corregir', name:'En corrección', icon: 'history', class:'red-fg'},
    {handle: 'corregir_adjunto', name:'Pendiente de corregir', icon: 'history', class:'brown-fg'},
    {handle: 'firmado_magistrado', name:'Pendiente por firmar', icon: 'history', class:'teal-fg'},
    {handle: 'firmado_secretario', name:'Firmado/acordado', icon: 'history', class:'green-fg'},
    {handle: 'notificada', name:'Notificado', icon: 'check_circle', class:'blue-fg'},
    {handle: 'notificado', name:'Para Boletín', icon: 'check_circle', class:'green-fg'},
    {handle: 'turnado_actuario_secretario', name:'Devuelto por Actuario', icon: 'history', class:'indigo-fg'},
]


export const TypeFiltersAgreementsSecretarioAdjunto = [
    {handle: 'atendido_adjunto', name:'Atendido', icon: 'history', class:'orange-fg'},
    {handle: 'returno', name:'Returno', icon: 'history', class:'purple-fg'},
    {handle: 'corregir_adjunto', name:'En corrección', icon: 'history', class:'red-fg'},
  
]


export const TypeFiltersAgreementsMag = [
    {handle: 'pendiente', name:'Pendiente', icon: 'history', class:'orange-fg'},
    {handle: 'corregir', name:'En corrección', icon: 'history', class:'red-fg'},
    {handle: 'corregido', name:'Corregido', icon: 'history', class:'purple-fg'},
    {handle: 'firmado_magistrado', name:'Firmado', icon: 'history', class:'green-fg'},
]


export const TypeFiltersAgreementsMagSalaSuperior = [
    {handle: 'pendiente', name:'Pendiente', icon: 'history', class:'orange-fg'},
    {handle: 'corregir', name:'En corrección', icon: 'history', class:'red-fg'},
/*     {handle: 'corregido', name:'Corregido', icon: 'history', class:'yellow-fg'},
    {handle: 'firmado_magistrado_otro', name:'En firma con otro magistrado', icon: 'history', class:'teal-fg'},
    {handle: 'firmado_magistrado_proceso', name:'En proceso de firma', icon: 'history', class:'blue-fg'}, */
    {handle: 'firmado_magistrado', name:'Firmado', icon: 'history', class:'green-fg'},
]

export const TypeFiltersAgreementsActua = [
    //SE COMENTAN FILTROS DE ESTATUS PARA ACTUARIO
    // {handle: 'firmado_secretario', name:'Pendiente por notificar', icon: 'history', class:'orange-fg'},
    // {handle: 'notificado', name:'Notificado', icon: 'history', class:'green-fg'},
]

export const TypeChildProceduresSentence = [
    {nume: 1, name: 'Juicio de nulidad/lesividad'},
    {nume: 2, name: 'Juicio de responsabilidad administrativa'},
    {nume: 3, name: 'Recursos'},
    {nume: 4, name: 'Incidentes'},
]

export const TypeChildResourceSentence = [
    {nume: 1, name: 'Recurso de queja'},
    {nume: 2, name: 'Recurso de reclamación'},
    {nume: 3, name: 'Recurso de revisión'},
    {nume: 4, name: 'Recurso de apelación'},
]

export const TypeChildIncidentSentence = [
    {nume: 1, name: 'Incidente de acumulación de autos'},
    {nume: 2, name: 'Incidente de nulidad de notificaciones'},
    {nume: 3, name: 'Incidente de interrupción del procedimiento por muerte o por disolución en el caso de las personas morales'},
    {nume: 4, name: 'Incidente de incompetencia'},
    {nume: 5, name: 'Incidente de aclaracion de sentencia'},
    {nume: 6, name: 'Incidente de liquidación'},
    {nume: 7, name: 'Incidente de cumplimiento sustituo'},
    {nume: 8, name: 'Incidente de falsedad de documentos'},
]



export const TypeChildProceduresSentenceSecretarioGeneral = [
    {nume: 3, name: 'Recursos'},
    {nume: 4, name: 'Incidentes'},
]

export const TypeChildResourceSentenceSecretarioGeneral = [
    {nume: 1, name: 'Recurso de queja'},
    {nume: 2, name: 'Recurso de reclamación'},
    {nume: 3, name: 'Recurso de revisión'},
    {nume: 4, name: 'Recurso de apelación'},
    {nume: 5, name: 'Recurso de inconformidad'},

]

export const TypeChildIncidentSentenceSecretarioGeneral = [
    {nume: 5, name: 'Incidente de aclaracion de sentencia'},
    {nume: 1, name: 'Incidente de acumulación de autos'},
    {nume: 7, name: 'Incidente de cumplimiento sustituo'},
    {nume: 8, name: 'Incidente de falsedad de documentos'},
    {nume: 4, name: 'Incidente de incompetencia'},
    {nume: 3, name: 'Incidente de interrupción del procedimiento por muerte o por disolución en el caso de las personas morales'},
    {nume: 6, name: 'Incidente de liquidación'},
    {nume: 2, name: 'Incidente de nulidad de notificaciones'},
    {nume: 9, name: 'Incidente de medidas cautelares'},

]

export const TypeJudgSentence = [
    {nume:1, handle: 'nulidad', name: 'Nulidad/lesividad'},
    {nume:2, handle: 'grave', name:'Responsabilidad administrativa grave'}
]

export const TypeSentence = [
    {handle: 'int', name: 'Interlocutoria'},
    {handle: 'def', name: 'Definitiva'}
]

export const TypeSentidoSentence = [
    
    //grave
    /* {nume: 1, to:'grave', type:'def' , name: 'Devolución falta administrativa no grave'}, */
    //end grave
        //interlocutorias
        {nume: 2, to:'nulidad lesividad grave', type:'int' , name: 'Confirma'},
        {nume: 3, to:'nulidad lesividad grave', type:'int' , name: 'Modifica'},
        {nume: 4, to:'nulidad lesividad grave', type:'int' , name: 'Revoca'},
        //end interlocutorias
    {nume: 5, to:'nulidad lesividad grave', type:'def int', name: 'Sobreseimiento'},
     //grave
    {nume: 6, to:'grave', type:'def' , name: 'Con responsabilidad'},
    {nume: 7, to:'grave', type:'def' , name: 'Sin responsabilidad'},
     //end grave
        //nulidad/lesividad
        {nume: 8, to:'nulidad lesividad' , type:'def' , name: 'Nulidad e invalidez'},
        {nume: 9, to:'nulidad lesividad' ,type:'def' , name: 'Validez'},
        //end nulidad/lesividad
    //interlocutorias
    {nume: 10, to:'nulidad lesividad grave', type:'int' , name: 'Procedente'},
    {nume: 11, to:'nulidad lesividad grave', type:'int' , name: 'Improcedente'},
    //end interlocutorias
]

export const TypoSentidoSentenceSuperior = [
    {nume: 5, to:'nulidad lesividad grave', type:'int def', name: 'Sobreseimiento'},
    {nume: 2, to:'nulidad lesividad grave', type:'int def' , name: 'Confirma'},
    {nume: 3, to:'nulidad lesividad grave', type:'int def' , name: 'Modifica'},
    {nume: 4, to:'nulidad lesividad grave', type:'int def' , name: 'Revoca'},
    {nume: 10, to:'nulidad lesividad grave', type:'int def' , name: 'Procedente'},
    {nume: 11, to:'nulidad lesividad grave', type:'int def' , name: 'Improcedente'},
    {nume: 12, to:'nulidad lesividad grave', type:'def' , name: 'Se ordena reponer el procedimiento'},
    {nume:13, to:'nulidad lesividad grave', type: 'int def', name: 'Deja sin efecto'}
]

export const TypeVotations = [
    {nume: 1, name: 'Unanimidad'},
    {nume: 2, name: 'Mayoría'},
    {nume: 3, name: 'Returno por no alcanzar la mayoría'},
    {nume: 4, name: 'Voto particular'}
]

export const TypeTrafficP  = [
    {
        'handle' : 'amarillo',
        'title' : 'En tiempo',
        'icon' : 'access_time',
        'class' : 'circuloentiempo'
    },
    {
        'handle' : 'naranja',
        'title' : 'Retraso',
        'icon' : 'access_time',
        'class' : 'circuloretraso'
    },
    {
        'handle' : 'rojo',
        'title' : 'Excedido',
        'icon' : 'access_time',
        'class' : 'circulofuerat'
    }
]
export const TypeTraffic  = [
    {
        'handle' : 'amarillo',
        'title' : 'En tiempo',
        'icon' : 'access_time',
        'class' : 'green-fg'
    },
    {
        'handle' : 'naranja',
        'title' : 'Rezago',
        'icon' : 'access_time',
        'class' : 'orange-fg'
    },
    {
        'handle' : 'rojo',
        'title' : 'Excedido',
        'icon' : 'access_time',
        'class' : 'red-fg'
    }
]
export const AgreementNextTram = [
    {
        nume: 1,
        name: 'NOTIFICAR CON EL ACTUARIO '
    },
    {
        nume: 2,
        name: 'ENVIAR A SALA SUPERIOR'
    },
    {
        nume: 3,
        name: 'ENVIAR A MAGISTRADO'
    },
    {
        nume: 4,
        name: 'INTEGRAR AL EXPEDIENTE'
    },
    {
        nume: 5,
        name: 'REMITIR AL CJF'
    },


]

export const AgreementNextTramSecretarioGeneral = [
    {
        nume: 1,
        name: 'NOTIFICAR CON EL ACTUARIO '
    },

    {
        nume: 4,
        name: 'ENVIAR A SALA REGIONAL'
    },
    {
        nume: 3,
        name: 'ENVIAR A MAGISTRADO'
    },
    {
        nume: 5,
        name: 'REMITIR AL CJF'
    },
]

export const UsersNotify = [
    {
        nume:1,
        name:'Particular'
    },
    {
        nume:2,
        name:'Autoridad'
    },
    {
        nume:3,
        name:'Tercero perjudicado'
    }
]

export const deterNotification = [
    {nume:"acuerdo", name:"Acuerdo"},
    {nume:"sentencia definitiva", name:"Sentencia definitiva"},
    {nume:"sentencia interlocutoria", name:"Sentencia interlocutoria"}
]

export const filterJudgOnline = [
    {nume: 1, name:'Electrónica', type:'enlinea', handle: 'electronicaenlinea', clase: 'sangria', color : '#858E3C', icono: 'fiber_manual_record'},
    {nume: 2, name:'Boletín electrónico', type:'enlinea', handle: 'boletinenlinea', clase: 'sangria', color : '#858E3C', icono: 'fiber_manual_record'},
]

export const filterJudgTraditional = [
    {nume: 1, name:'Electrónica', type:'tradicional', handle: 'electronicatradicional', clase: 'sangria', color : '#858E3C', icono: 'fiber_manual_record'},
    {nume: 2, name:'Boletín electrónico', type:'tradicional', handle: 'boletintradicional', clase: 'sangria', color : '#858E3C', icono: 'fiber_manual_record'},
    {nume: 3, name: 'Físicas', type: 'tradicional', handle: 'fisicatradicional', clase: 'sangria', color : '#858E3C', icono: 'fiber_manual_record'}
]



export function GetProfileDataOptions(depa,type){
      
        if (depa!='1') {
            return {sentidoTypes: TipoSentido,
               sentidoOptions:AcuerdoSentidoOptions,
               aditionalOptions:AditionalOptions,
               subSentidoOptions:SubsentidoOptions,
               AditionalOptionsId:AditionalOptionsId
            };
        }
        if (depa=='1') {
            if (type=='general') {
      
            return {sentidoTypes: TipoSentidoSecretarioGeneral,
               sentidoOptions:AcuerdoSentidoOptionsSecretarioGeneral,
               aditionalOptions:[],
               subSentidoOptions:[],
               AditionalOptionsId:AditionalOptionsId};
            }
            if (type=='recurso') {
            return {sentidoTypes: new SecretarioRecursoOptions().TipoSentido,
                    sentidoOptions:new SecretarioRecursoOptions().AcuerdoSentidoOptions, 
                    aditionalOptions:new SecretarioRecursoOptions().AditionalOptions,
                    subSentidoOptions:new SecretarioRecursoOptions().SubsentidoOptions,
                    AditionalOptionsId:new SecretarioRecursoOptions().AditionalOptionsId
                   };
            }
            if (type=='amparo') {
      
           return {sentidoTypes: new SecretarioAmparoOptions().TipoSentido,
                   sentidoOptions:new SecretarioAmparoOptions().AcuerdoSentidoOptions, 
                   aditionalOptions:new SecretarioAmparoOptions().AditionalOptions,
                   subSentidoOptions:new SecretarioAmparoOptions().SubsentidoOptions,
                   AditionalOptionsId:new SecretarioAmparoOptions().AditionalOptionsId
              };
            }
            if (type=='ejecucion') {
           return {sentidoTypes: new SecretarioEjecucionOptions().TipoSentido,
                   sentidoOptions:new SecretarioEjecucionOptions().AcuerdoSentidoOptions, 
                   aditionalOptions:new SecretarioEjecucionOptions().AditionalOptions,
                   subSentidoOptions:new SecretarioEjecucionOptions().SubsentidoOptions,
                   AditionalOptionsId:new SecretarioEjecucionOptions().AditionalOptionsId

              };
            
            }
        }
}