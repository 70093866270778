<div id="login" fxLayout="column" fxLayout.gt-sm="row" fusePerfectScrollbar>
    <section class="titles">
        <img class="logo Logo_wed_Class" src="/assets/images/logos/logogro.png" >
    </section>

    <section class="diagram">
        <div class="row" fxFlex >
            <h1 class="Tribunal_Electrnico_para_la_Ju_Class">
                <span class="name_title">e</span>.Justicia<br>
                <span>Administrativa </span>
            </h1>

            <!--h3 class="Iniciamos_una_nueva_era_en_la__Class">
                <span>Iniciamos una nueva era en la impartición de Justicia Administrativa, una era de innovación tecnológica, con la implementación del Tribunal Electrónico para la Justicia Administrativa.</span>
            </h3-->
            <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                <a class="link" matTooltip="INICIAR SESIÓN" (click)="toSignup()">Inicia Sesión</a>
            </h2>
            <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                <a class="link" matTooltip="CREAR CUENTA PARTE ACTORA" [routerLink]="'/pages/auth/register'">Crea una Cuenta</a>
            </h2>

            <!-- <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                <a class="link" matTooltip="SOLICITUD DE ASESORÍA" [routerLink]="'/pages/auth/adviser-citizen-form'">Solicita tu Asesoría</a>
            </h2>
            <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                <a class="link" matTooltip="ESTRADOS DIGITALES" href="https://acuerdos.tjagro.gob.mx/"  target="_blank">Estrados Digitales</a>
            </h2> -->
            <!--<h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                <a class="link" matTooltip="CREAR CUENTA AUTORIDAD" [routerLink]="'/pages/auth/register/authority'">Crea una Cuenta (autoridad)</a>
            </h2>
            <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                <a class="link" [routerLink]="'/pages/auth/adviser-citizen-form'" matTooltip="ASESORÍA CIUDADANA">Asesoría Ciudadana</a>
            </h2>-->
        </div>
    </section>
</div>