import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { adminMenu, actuaryMenu,peritoMenu, streamMenu, secretaryMenu, secretaryMenu2,citizenMenu, 
    authorityMenu, asesorMenu, magistradoMenu, proyectistaMenu,secretaryAjuntoMenu ,magistradoSalaSuperiorMenu,EstadisticaMenu,CompilacionMenu, TransparenciaMenu} from "./menu-data";

@Injectable({providedIn: 'root'})
export class MenuService {

    navigation: Array<any>;

    constructor(private _fuseNavigationService: FuseNavigationService) {
        this.navigation = [];
        
        // Get default navigation
        //this.navigation = navigation;

    }

    addMenu(newNavigation) {
        //set new navigation
        this.navigation = newNavigation;
         // Register the navigation to the service
        this._fuseNavigationService.register('principal', newNavigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('principal');
    }

    setMenu = (typeUser: String,isSalaSuperior:boolean) => {
        //const info = this.auth.getDecodedAccessToken(userToken).data;
        const menu = this._fuseNavigationService.getCurrentNavigation();
        console.log(typeUser);
        if( menu == undefined) {
            switch (typeUser) {
                case 'superadmin':
                    this.addMenu(adminMenu);
                    break;
                case 'Actuario':
                    this.addMenu(actuaryMenu);
                    break;
                case 'Secretario de acuerdos sala regional':
                    this.addMenu(secretaryMenu);
                    break;

                case 'Secretario de acuerdos general':
                    this.addMenu(secretaryMenu2);
                    break;

                case 'Secretario adjunto de recursos':
                    this.addMenu(secretaryAjuntoMenu);
                    break;

                case 'Secretario adjunto de amparos':
                    this.addMenu(secretaryAjuntoMenu);
                    break;

                case 'Secretario adjunto de ejecucion':
                    this.addMenu(secretaryAjuntoMenu);
                    break;
            
                case 'ciudadano':
                    this.addMenu(citizenMenu);
                    break;
                case 'perito':
                    this.addMenu(peritoMenu);
                    break;
                case 'autoridad':
                    this.addMenu(streamMenu);
                    break;
                case 'Asesor ciudadano':
                    this.addMenu(asesorMenu);
                    break;
                case 'Magistrado':
                    this.addMenu(isSalaSuperior?magistradoSalaSuperiorMenu:magistradoMenu);
                    break;
                case 'Secretario de estudio y cuenta':
                    this.addMenu(proyectistaMenu);
                    break;
                case 'estadistica':
                    this.addMenu(EstadisticaMenu);
                    break;
                case 'Compilacion':
                    this.addMenu(CompilacionMenu);
                    break;
                case 'Transparencia':
                    this.addMenu(TransparenciaMenu);
                    break;
            }    
        }else {
            this.delMenu();
            this.setMenu(typeUser,isSalaSuperior);
        }
    }

    delMenu() {
        //delete current navigation
        this._fuseNavigationService.unregister('principal'); 
    }

}
/* params Menu
id       : 'sample',
title    : 'Dashboard',
translate?: 'NAV.SAMPLE.TITLE',
type     : 'item',
icon     ?: 'email',
url      ?: '/sample',
badge    ?: {
    title    : '25',
    translate?: 'NAV.SAMPLE.BADGE',
    bg       : '#F44336',
    fg       : '#FFFFFF'
}
*/