import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Subject ,BehaviorSubject,Observable  } from 'rxjs';
import { Todo } from 'app/main/sentences-proyect/todo.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ElectronicExpedientService {
  todos: Todo[] = [];
  urlExpedient:string = '';
  urlExpedientRequest:string = '';
  getExpedientURL:string = '';
  urlDownload:string = '';
  urlMags:string = '';
  urlOfficExpe: string = '';
  onNewTopicForm: Subject<any>;
  onTopicSelected: Subject<any>;
  onExpedientViewSelected: Subject<any>;
  onFichaViewSelected: Subject<any>;

  urlGetFile:string = '';


  
  
  private dataSource: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private alertasExpedienteFisico: BehaviorSubject<[]> =new BehaviorSubject<[]>([]);

  searchParameter: Observable<string> = this.dataSource.asObservable();
  $alertas: Observable<[]> = this.alertasExpedienteFisico.asObservable();

  constructor(private _httpClient: HttpClient, private _settings: AppConfigService) { 
    this.urlExpedient = this._settings.getUrlApi() + 'electronic-expedient?per_page=50';
    this.urlExpedientRequest = this._settings.getUrlApi() + 'expedient-request';
    this.urlDownload = this._settings.getUrlApi() + 'electronic-expedient';
    this.urlMags = this._settings.getUrlApi() + 'employees/';
    this.urlOfficExpe = this._settings.getUrlApi() + 'officialdom-expedient?expe=';
    this.getExpedientURL = this._settings.getUrlApi() + 'officialdom-expedient?expe=';
    this.urlGetFile = this._settings.getUrlApi() + 'electronic-expedient/file';

    this.onNewTopicForm = new Subject();
    this.onTopicSelected = new Subject();
    this.onExpedientViewSelected = new Subject();
    this.onFichaViewSelected = new Subject();
    this.onFichaViewSelected = new Subject();


  }


  getExpedient(expediente){
    let optionHeaders = this._settings.getHeadersMultipart();
    //return this._httpClient.post(`${this.urlDownload}/${id}/download?id=${id}`,[],optionHeaders);
    return this._httpClient.get(`${this.urlDownload}/search?expediente=${expediente}`, optionHeaders);

  }


  getAll(){
    let optionHeaders = this._settings.getHeadersMultipart();
    return this._httpClient.get(this.urlExpedient, optionHeaders);
  }

  
  getTopicsByDepartment(nume){
    let optionHeaders = this._settings.getHeadersMultipart();
    return this._httpClient.get(this.urlExpedient+'/'+nume, optionHeaders);
  }

  getEmployeeMags(id){
    let optionHeaders = this._settings.getHeadersJson();
    return this._httpClient.get(this.urlMags+id+'/magistrates', optionHeaders);
  }

  getOfficialExpedient(data){
    let optionHeaders = this._settings.getHeadersJson();
    return this._httpClient.get(this.urlOfficExpe+data, optionHeaders);
  }

  saveExpedientRequest(data){
    let optionHeaders = this._settings.getHeadersMultipart();
    return this._httpClient.post(this.urlExpedientRequest, data,optionHeaders);
  }


  downloadZip(id){
    let optionHeaders = this._settings.getHeadersMultipart();
    //return this._httpClient.post(`${this.urlDownload}/${id}/download?id=${id}`,[],optionHeaders);

    return this._httpClient.get<Blob>(`${this.urlDownload}/download?id=${id}`, {headers:optionHeaders.headers, observe: 'response', responseType: 'blob' as 'json' })

  }


  
  getFile(id){
    let optionHeaders = this._settings.getHeadersMultipart();
    //return this._httpClient.post(`${this.urlDownload}/${id}/download?id=${id}`,[],optionHeaders);

  //  return this._httpClient.get<Blob>(`${this.urlGetFile}?id=${id}`, {headers:optionHeaders.headers, observe: 'response', responseType: 'blob' as 'json' })

  return  this._httpClient.get(`${this.urlGetFile}?id=${id}`, {headers:optionHeaders.headers,reportProgress: true, observe: 'events', responseType: 'blob' })
  .pipe(
      map((result: any) => {
          return result;
      })
  );

  }


  getFicha(id){
    let optionHeaders = this._settings.getHeadersMultipart();
    //return this._httpClient.post(`${this.urlDownload}/${id}/download?id=${id}`,[],optionHeaders);
    return this._httpClient.get(`${this.urlDownload}/ficha?id=${id}`, optionHeaders);

  }

    sendSearchParameter(searchString: string) 
    {
       
      this.dataSource.next(searchString);


    }
    
    setAlertasExpedienteFisico(alertas: []) 
    {
       
      this.alertasExpedienteFisico.next(alertas);


    }

}
