import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogData } from 'app/main/sample/sample.component';
import { MailComposeDialogComponentPdf } from 'app/main/pdfMod/pdf.component';
import { SelectFileSign, message, SelectPfx, OpenPfx, PKCS7creado, extFileUpload } from 'app/main/sign/common/functions/cliente';
import { ResourcesProvider } from 'app/providers/resources.provider';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Todo } from 'app/main/agreements/todo/todo.model';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentProvider } from 'app/providers/documents.register.provider';

@Component({
    selector   : 'fuse-acta-dialog',
    templateUrl: './acta-dialog.component.html',
    styleUrls  : ['./acta-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseActaDialogComponent
{
    public confirmMessage: string;
    public type: any;
    isLoading = false;
    pkcs7: string;
    currentUser = { empe: "", type: "" };
    fileLaravel: any;

    expedientForm: FormGroup;
    departments = [];
    acuerdo:Todo=null;
    dialogRef2: any;
    documentofirma: any;
    activarBoton: boolean = false;
    filePFXselct: any;
    password: FormControl;
    @ViewChild("docu") docu: ElementRef;
    agreementDataLoaded: boolean = false;
    employeeDepa: any;
    showInputsecretarioact: boolean = false;
    message: string;
    extArchivoCargado: string = "";
    buscarFirstClicked:boolean= false;

    //MOSTRAR CONTRASEÑA
    typePassword:String = 'password';
    txt_tooltip_pass:String = 'mostrar contraseña'
    showPass:boolean = false;
    errorCargaFirma:boolean = false;
    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseActaDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseActaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public typeNotify: DialogData,
        private _formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        public _matDialog: MatDialog,
        private _resourcesService: ResourcesProvider,
        private _settings: AppConfigService,
        private _matSnackBar: MatSnackBar,
        private _documentService: DocumentProvider,

    )
    {
        this.employeeDepa = this._settings.getDepaEmployee()
        this.currentUser = this._settings.getCurrentUser();

        this.type = typeNotify;
        this.expedientForm = this.createExpedientForm();
        this.password = new FormControl("");

    }
    createExpedientForm() : FormGroup {
        
        return this._formBuilder.group({
            acue  : ['',Validators.required],
            arti  : ['',Validators.required],
            docu  : ['',Validators.required],
            password  : ['',Validators.required],
        });
    }
    setFileForm(inputFileName) {
        const reader = new FileReader();
        const fileUpload = this[`${inputFileName}`].nativeElement;
        fileUpload.onchange = () => {
            if (fileUpload.files && fileUpload.files.length) {
                const [file1] = fileUpload.files;
                reader.readAsDataURL(file1);
                reader.onload = () => {
                    this.expedientForm.patchValue({
                        [`${inputFileName}`]: file1
                    });
                };
                this._getNumPages(file1);
                this.cd.markForCheck();
            }
        };
        fileUpload.click();
    }
    _getNumPages(file) {
        //read num pages pdf
        var read = new FileReader();
        read.readAsBinaryString(file);
        read.onload = function () {
            var count = read.result.toString().match(/\/Type[\s]*\/Page[^s]/g).length;
        }
    }
    removeFileForm(inputFileName) {
        this.expedientForm.patchValue({
            [`${inputFileName}`]: ''
        });
    }
    previewFile(inputFileName) {
        const type = 0; //Generar URL
        const fileUpload = this[`${inputFileName}`].nativeElement;
        const file1 = fileUpload.files[0];
        //console.log(file1);
        let pdfUrl = URL.createObjectURL(file1);

        this.composeDialogDocument(file1, type);
    }
    composeDialogDocument(file, type): void {
        this.dialogRef2 = this._matDialog.open(MailComposeDialogComponentPdf, {
            width: '1000px',
            height: '700px',
            panelClass: 'mail-compose-dialog',
            data: {
                file: file,
                type: type,
            }
        });
    }
    SeleccionarPfx(filePFX: any) {
        this.documentofirma = filePFX;
        console.log("selectPfx", this.activarBoton,filePFX);
        
        this.activarBoton = true;
        console.log("selectPfx", this.activarBoton);
        if (this.activarBoton) {
            console.log("activar boton");
            this.filePFXselct = filePFX;
            this.password.enable();
        }else{
            this.expedientForm.get('password').setValue('');
        }
    }
    _getAgreementData(event){
        let searchText;
        if(event.target.value == ''){
            searchText = 'undefined';
        }else{
            searchText = event.target.value;
        }
        this._resourcesService.getExpedientebyNumeroExpediente(searchText,this.employeeDepa).subscribe(
            (response)=>{
                console.log("response get agreement data", response['data']);
                if(response['data'].length > 0){
                    this.agreementDataLoaded = true;
                    this.acuerdo = (new Todo(response['data'][0]));
                    console.log("aucerdo",this.acuerdo);
                }else{
                    this.agreementDataLoaded = false;
                }
            },(error)=>{
                console.log("error get agreement data", error);
            }
        )
    }

    buscarExpediente(searchText){
     
        this._resourcesService.getExpedientebyNumeroExpediente(searchText,this.employeeDepa).subscribe(
            (response)=>{
                console.log("response get agreement data", response['data']);
                if(response['data'].length > 0){
                    this.agreementDataLoaded = true;
                    this.acuerdo = (new Todo(response['data'][0]));
                    console.log("aucerdo",this.acuerdo);
                }else{
                    this.buscarFirstClicked=true;
                    this.agreementDataLoaded = false;
                }
            },(error)=>{
                console.log("error get agreement data", error);
            }
        )
    }

    getOnlyDate(date) {
        moment.locale('es-Mx');
        return moment(date).format('DD-MM-YYYY');
    }
    enviarActuarioSecretario() {
        this.isLoading = true;
            OpenPfx(this.filePFXselct, this.expedientForm.get('password').value).then((isCreatePKCS7) => {
                if (isCreatePKCS7) {
                    this.pkcs7 = PKCS7creado;
                    this.saveTextAsFileActuarioForm2(this.pkcs7);
                }
            }, (error) => {
                this.isLoading = false;
                this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center',
                    duration: 3000
                });
            }); 
    }
    saveTextAsFileActuarioForm2(element: string) {
        const formData = new FormData();
        formData.append('sentencia', this.acuerdo.id);
        formData.append('usuario', this.currentUser.empe);
        formData.append('notifi', '1');
            formData.append('docu', this.expedientForm.get('docu').value);
            formData.append('arti', this.expedientForm.get('arti').value);
            
            this._documentService.EnviarSecretarioapi(formData).subscribe(
                response => {
                        this._firmaractaconstancia(response);        
                    
                    
                    
                    setTimeout(() => {
                        this.showInputsecretarioact = false;

                    }, 3000);
                }, error => {
                    this.isLoading = false;
                    this._matSnackBar.open('Error al enviar al secretario', 'OK', {
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center',
                        duration: 3000
                    });
                }
            );
        
    }
    _firmaractaconstancia(result) {
        //Firma minuta
        console.log('resultado de notificacion', result);
        let tempFile = result.documento.split("/");
        console.log("tempfile", tempFile);
        this._documentService.getPublicFiles(tempFile[0], tempFile[1]).subscribe(
            response => {
                this.fileLaravel = new File([response], "prueba.pdf", { type: 'application/pdf' });
                SelectFileSign(this.fileLaravel).then((isValidFile) => {
                    if (isValidFile) {
                        this.message = message;
                        this.extArchivoCargado = extFileUpload;
                        this.activarBoton = SelectPfx(this.documentofirma);
                        if (this.activarBoton) {
                            this.password.enable();
                            this.filePFXselct = this.documentofirma;
                            //se ejecuta codigo para firmar
                            console.log('resultado de la firma', this.filePFXselct);
                            this.firmaractaconstacia(result);
                        }
                    }
                })

            }, error => {
                console.log("erroor get file", error);
            }
        );
    }
    firmaractaconstacia(result) {
        OpenPfx(this.filePFXselct, this.expedientForm.get('password').value).then((isCreatePKCS7) => {
            if (isCreatePKCS7) {
                this.pkcs7 = PKCS7creado;
                console.log(' this.pkcs7', this.pkcs7[0]);
                this.saveTextAsFileacta(result, this.pkcs7);
            }
        }, (error) => {
            this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
                duration: 3000
            });
        });
    }
    saveTextAsFileacta(result, element: any) {
        const minutaele = new FormData();
        let blobAsFile;


        var fecha = new Date();
        let textToWrite = element;
        let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });
        let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "." + this.extArchivoCargado;
        blobAsFile = new File([textFileAsBlob], fileNameToSaveAs);


        setTimeout(() => {
            minutaele.append('id', result.id);
            minutaele.append('docu', blobAsFile);
            this._documentService.Signacatasecre(minutaele).subscribe(
                (result) => {
                    //Firma el documento generado
                    console.log(result);
                    this.isLoading = false;
                    this.showInputsecretarioact = true;
                    this._matSnackBar.open('Transferido a Secretario de acuerdos', 'OK', {
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center',
                        duration: 3000
                    });
                    this.dialogRef.close();
                    setTimeout(() => {
                        this.showInputsecretarioact = false;
                    }, 3000);
                },
                (error) => {
                }
            );
        }, 1000);
        //this.sendData();
    }


    showPassword($event): void {
        $event.stopPropagation();
        this.typePassword = 'password';
        this.txt_tooltip_pass = 'mostrar contraseña';
        if(!this.showPass) {
            this.typePassword = 'text';
            this.txt_tooltip_pass = 'ocultar contraseña';
        }
        this.showPass = !this.showPass;
      }
}
