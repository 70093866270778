import { Injectable } from '@angular/core';
import { AppConfig } from './app-config.model';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
//@Injectable()
@Injectable({providedIn: 'root'})
export class AppConfigService {
    private appConfig: AppConfig = new AppConfig();

    getUrlApi() {
        return this.appConfig.url;
    }
    getUrlNoApi() {
        return this.appConfig.Noapi;
    }
    getHeadersJson() {
        return {
            headers: new HttpHeaders()
                        .set('Content-Type', 'application/json' )
                        .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk'))
        };
    }
    getHeadersJsonEmp() {
        return {
            headers: new HttpHeaders()
                        .set('Content-Type', 'application/json' )
                        .set('Authorization', 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODNlNjIwMzBmZDYyYmUzMTY5ZjZhNjQzZTQ4Y2FhYmEzZGM1MGUwNDkwY2YwN2IyNWNhZGExMWExNDQzMzhlNmE1MjdmODBiMTc1Y2E4MmYiLCJpYXQiOjE2MDQwODcwMzcsIm5iZiI6MTYwNDA4NzAzNywiZXhwIjoxNjM1NjE5NDM3LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.XW3HyeOzbfgFJa3so2t1KDRYIfpOe9EXHQMcAwdFoGjcw3pQryKhj_sBKveCmHjQqcnSWUu3e1j3AS73juSTYghLBKEf79JVaP8Ws4RMQ4S8STCsMSmoHb5UqTSxoa9mEmHlYS6MgTUiHoRvWemqEcKyNbO5__Tv10GObJzU21bZ_hghNSASucFq-BTf-QcQFHtokuywNk9s-NR_8m7BAJE4DpjZVhd8wxhZIbOg2a04WbviJXx8akJY31L8U_jwE-Ekz8jj6_BuZqhalCB-pnoELZ3MwMfMcvi6hckyzU3QuhvtA2sUlKCTA2XEFxyKcpIVPsckIAZG-FMmaSm4z-CVpG-aKRw-Vl6ONo_l_qh11TqpUbWTCb6eajXyzNn5bXyLLeAx5VKDNojHDa1C8qx70Our1GGMDaJEizRLIPxhBpan8QNPTeeCNodnhpzht3xZOQ3fFcn9B-p35gGimnGm-qyiFQH8x-2K5lKgY-Yz9MLpyukOX9WlwfysfQnmE5DlUnKRNjuxHPkVYGbG3QeDpQVHJAfAJcuYwvJb2-PQ6HBSdf8IYZiScqNcPWVY1-ZORODAKiK1o2NWSS1NYTxipvhGOxx8c_3pGYNlxlJllKeF4oCtfTAo3VCtjIwZ8YF3L9v4QzxvV2zBdlz316HxoKNQyWbEE3U3KiI8OYQ')
        };
    }

    getHeadersBlob() {
        return {
            headers: new HttpHeaders()
            .set('Content-Type', 'application/json' )
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk')),
            // Ignore this part or  if you want full response you have 
            // to explicitly give as 'boby'as http client by default give res.json()
            observe:'response' as 'body',
           // have to explicitly give as 'blob' or 'json'
            responseType: 'blob' as 'blob'  
        };
    }

    getHeadersMultipart() {
        return {
            headers: new HttpHeaders({                       
                            'Authorization': 'Bearer ' + sessionStorage.getItem('tk')
                        })
        };
    }

    getTokenString() {
        return 'Bearer ' + sessionStorage.getItem('tk');
    }
    
    getCurrentUser(){
        if(sessionStorage.getItem('usr') !== null) {
            return this.getInfoEncrypt(sessionStorage.getItem('usr'));
        }
    }

    getInfoUserSystem(){
        const user = sessionStorage.getItem('tyus');
        if(user !== null) {
            return this.getInfoEncrypt(user);
        }
    }

    validIsEmployeAdmin() {
        const tipo = this.getCurrentUser().tipo;
        if(tipo === 'superadmin') {
            return true
        } else if (tipo === 'empleado') {
            const dataEmployee = this.getTypeEmployee();
            if (dataEmployee === 'Asesor ciudadano') {
                return false;
            }
            return true;
        }
        
        return false;
    }

    getNameTypeUser(){
        if(sessionStorage.getItem('usr') !== null) {
            const currentUser = this.getInfoEncrypt(sessionStorage.getItem('usr'));
            const depaUser = this.getDepaNameEmployee();
            if(currentUser.tipo === 'ciudadano' || currentUser.tipo === 'autoridad' || currentUser.tipo ==='perito') {
                return  currentUser.tipo;
            }else {
                return  this.getTypeEmployee();
            }
        }
        return 'typeUser';
    }

    showTypeUser() {
        return this.capitalize_Words(this.getNameTypeUser)
    }


    getTypeEmployee(){
        const typeEmployee = sessionStorage.getItem('tyem')
        console.log("tipo de empleado",typeEmployee);
        if( typeEmployee !== null) {
            if( typeEmployee === 'Secretario de acuerdos sala regional') {
                return typeEmployee;
            }else if(typeEmployee === 'Secretario de acuerdos general'){
                return typeEmployee;
            }else if(typeEmployee === 'Secretario adjunto de recursos'){
                return typeEmployee;
            } else if(typeEmployee === 'Secretario adjunto de amparos'){
                return typeEmployee;
            } else if(typeEmployee === 'Secretario adjunto de ejecucion'){
                return typeEmployee;
            }else if(typeEmployee === 'Actuario'){
                return typeEmployee;
            }else if(typeEmployee === 'Asesor ciudadano'){
                return typeEmployee;
            }else if(typeEmployee === 'Secretario de estudio y cuenta'){
                return typeEmployee;
            }else if(typeEmployee === 'Compilacion'){
                return typeEmployee;
            }else if(typeEmployee === 'Transparencia'){
                return typeEmployee;
            }


            return 'Magistrado';
        }
        return '';
    }

    getDepaEmployee(){
        if(sessionStorage.getItem('em') !== null) {
            const employee =  this.getInfoEncrypt(sessionStorage.getItem('em'));
            return employee.nume;
        }
    }

    getDepaNameEmployee(){
        if(sessionStorage.getItem('em') !== null) {
            const employee =  this.getInfoEncrypt(sessionStorage.getItem('em'));
            return employee.depa;
        }
        return '';
    }

    getDateFormat(date){
        moment.locale('es-Mx');
        return moment(date).format('DD-MM-YYYY h:mm:ss') //'MMMM Do YYYY, h:mm:ss a'
    }

    getInfoEncrypt(data){
        //const decr = CryptoJS.AES.decrypt(data,'aS35LwFU?}{0').toString(CryptoJS.enc.Utf8);
        //return JSON.parse(decr);
        return JSON.parse(data)
    }

    toEncrypt(data) {
        //return CryptoJS.AES.encrypt(data,'aS35LwFU?}{0');
        return data;
    }

    formatUneExpediente(identification) {
        let char_split = '';
        if(identification.includes('-')) {
            char_split = '-'
        }else if(identification.includes('/')) {
            char_split = '/'
        }else {
            return 'false'
        }

        const elements = identification.split(char_split);
        
        return elements[0].trim() + char_split + elements[1].trim(); 
    }

    _getMsjError(error){
        error = error.error.error;

        if(typeof error === 'object'){
            for (const field in error) {
                if (Object.prototype.hasOwnProperty.call(error, field)) {
                    console.log(field, error[field])
                    return error[field]; 
                }
            }
        }
        
        return error
    }

    capitalize_Words(str){
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0) == 'd' || txt.charAt(0) == 'y' ? txt : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    _getPaginatorEmpty() {
        return { 
            count: 50,
            current_page: 1,
            //links: {previous: "https://api.apilaravel.ml/api/promotions?per_page=10&page=1", next: "https://api.apilaravel.ml/api/promotions?per_page=10&page=3"}
            per_page: 50,
            total: 0,
            total_pages: 0 
        }
    }


    getOrganoOrigenCJF(){
        let department_id:number=undefined;
        let result:number=undefined;

        if(sessionStorage.getItem('em') === null) {
            return undefined;
        }

        const employee =  this.getInfoEncrypt(sessionStorage.getItem('em'));
        department_id=employee.depa;

        switch (department_id) {
            case 4329:
              result=11;   
                break;
             case 4330:
              result=1;   
                 break;
             case 4331:
               result=2;   
                 break;
             case 4332:
               result=3;   
                 break;
             case 4333:
               result=4;   
                 break;
             case 4334:
               result=5;   
                 break;
             case 4335:
               result=6;   
                 break;
             case 4336:
               result=7;   
                 break;
             case 4337:
               result=8;   
                 break;
             case 4338:
               result=9;   
                 break;
            default:
            result=-1;
                break;
        }
        
        return result;
    }

    getAlertasExpedienteFisico(){
        const alertas = sessionStorage.getItem('alertas');
        if(alertas !== null) {
            return this.getInfoEncrypt(alertas);
        }
    }
}
