import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AppConfigService } from './data/app-config.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ExpedientProvider {
    private urlExpedient: string;
    private asesorExpedienteSource: BehaviorSubject<string> = new BehaviorSubject<string>('na');
    asesorExpediente: Observable<string> = this.asesorExpedienteSource.asObservable();

    constructor(private http: HttpClient, private settings: AppConfigService) {
        this.urlExpedient = this.settings.getUrlApi() + 'registrations';
    }
    


    sendData(data: string) {
        this.asesorExpedienteSource.next(data);
      }

    registers(credentials) {
        const  haciendoalgo = (resolve, reject) => {
            if (credentials.une !== '') {
                setTimeout(() =>{
                    resolve("Stuff worked!");
                }, 5000);
            }
            else {
                reject(Error("It broke"));
            }
        }
        return new Promise( haciendoalgo );
    }

    registerExpedient(data) {
        let optionHeaders   = this.settings.getHeadersMultipart();
        const dataExpedient = this._toFormData(data);
        return this.http.post(this.urlExpedient, dataExpedient, optionHeaders);
    }

    sendNotification(data) {
        let optionHeaders = this.settings.getHeadersMultipart(); 
        return this.http.post(this.settings.getUrlApi() + 'notifications', data, optionHeaders);
    }
    
    sendNotificationSala(data, id_agreement) {
        const optionHeaders = this.settings.getHeadersMultipart(); 
        const urlApi = this.settings.getUrlApi() + `agreements/${ id_agreement}/to-sala`;
        return this.http.post(urlApi, data, optionHeaders);
    }
    
    sendNotificationAgreement(data, id_agreement){
        const optionHeaders = this.settings.getHeadersMultipart(); 
        const urlApi = this.settings.getUrlApi() + `agreements/${ id_agreement}/notifications`;
        return this.http.post(urlApi, data, optionHeaders);
    }
    sendNotificationSentence(data, id_sentence){
        const optionHeaders = this.settings.getHeadersMultipart(); 
        const urlApi = this.settings.getUrlApi() + `sentence/${ id_sentence}/notifications`;
        return this.http.post(urlApi, data, optionHeaders);
    }
    SignMinuta(data){
        const optionHeaders = this.settings.getHeadersMultipart(); 
        return this.http.post(this.settings.getUrlApi() + 'signminuta', data, optionHeaders);
    }
    _toFormData(data) {
        const formData = new FormData();  
        formData.append('une', data.une);
        formData.append('depa', data.depa);
/*         formData.append('part', data.part);
        formData.append('dema', data.dema);
        formData.append('terc', data.terc); */
        formData.append('repr', data.repr || '');
        formData.append('tipo', data.tipo);
        formData.append('kien', data.kien);
        formData.append('dom_pro', data.dom_pro);

        // formData.append('ine', data.ine);
        if(data.kien === 'ciudadano'){
            if(data.daut !== '') {
                formData.append('daut', data.daut);
            }
        }
        formData.append('ciud', data.ciud);

        for (let index = 0; index < data.parts.length; index++){
    
            formData.append(`parts[${index}]`,JSON.stringify(data.parts[index].toNewRequestData()));
          
          }
    
          
        
        return formData;
    }

}