export const TypeDoc = [
    //JUICIO DE NULIDAD
    /*{name:'ALEGATOS', handle:'alegato', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'AMPLIACIÓN DE DEMANDA', handle:'ampliacion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'APERSONAMIENTO', handle:'apersonamiento', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:''},
    {name:'COMPARECENCIA', handle:'comparecencia', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'CONTESTACIÓN A LA AMPLIACIÓN DE DEMANDA (LESIVIDAD) ', handle:'ampliacionlesividad', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'CONTESTACIÓN DE AGRAVIOS', handle:'agravios', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'CONTESTACIÓN DE AMPLIACIÓN DE DEMANDA', handle:'contestampliacion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:''},
    {name:'CONTESTACIÓN DE DEMANDA (LESIVIDAD) ', handle:'contestacionlesividad', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'CONTESTACIÓN DE LA DEMANDA', handle:'contestacion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:''},
    {name:'CONVENIO DE CUMPLIMIENTO DE SENTENCIA', handle:'cumplimiento', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'CONVENIO DE CUMPLIMIENTO SUSTITUTO DE SENTENCIA', handle:'sustituto', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'DEMANDA INICIAL', handle:'inicial', registry:'INICIAL', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'DESAHOGO DE VISTA', handle:'desahogo', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'DESIGNACIÓN DE NUEVO DOMICILIO ELECTRÓNICO', handle:'nuevodomicilio', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'DESIGNACIÓN DE REPRESENTANTE COMÚN', handle:'representante', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'DESISTIMIENTO', handle:'desistimiento', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'EJECUCIÓN DE CUMPLIMIENTO DE SENTENCIA', handle:'cumplimientoejecucion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'EXCITATIVA DE JUSTICIA', handle:'excitativa', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1', to_user:'citizen'},
    {name:'EXCUSA', handle:'excusas', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INACTIVIDAD PROCESAL', handle:'inactividadprocesal', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE ACLARACIÓN DE SENTENCIA', handle:'incidenteaclaracion', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE ACUMULACIÓN DE AUTOS', handle:'acumulacion', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE CUMPLIMIENTO SUSTITUTO', handle:'incidentesustituto', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE FALSEDAD DEL DOCUMENTO', handle:'falsedaddocumento', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE INCOMPETENCIA', handle:'incompetencia', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE INTERRUPCIÓN DEL PROCEDIMIENTO POR MUERTE O DISOLUCIÓN DE LAS PERSONAS MORALES', handle:'disolucion', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE LIQUIDACIÓN', handle:'liquidacion', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INCIDENTE DE NULIDAD DE NOTIFICACIONES', handle:'nulidad', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'INFORMA CUMPLIMIENTO', handle:'informacumplimiento', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:''},
    {name:'INSPECCIÓN OCULAR', handle:'ocular', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'NOMBRAMIENTO DE PERITO', handle:'perito', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'OBJECIÓN DE DOCUMENTOS', handle:'objecion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'OFRECIMIENTO DE PRUEBA', handle:'prueba', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'PRESENTACIÓN DE DEMANDA DE AMPARO DIRECTO', handle:'directo', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1', to_user:'citizen'},
    {name:'PROMOCIÓN', handle:'promocion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'PROMOCIÓN RELACIONADA CON SUSPENSIÓN', handle:'suspensionpromocion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'PRUEBA SUPERVENIENTE', handle:'superveniente', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'RECURSO DE QUEJA', handle:'queja', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'RECURSO DE RECLAMACIÓN', handle:'reclamacion', registry:'INICIAL', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'RECURSO DE REVISIÓN', handle:'recurso', registry:'INICIAL', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'REVOCACIÓN DE AUTORIZADOS Y DESIGNACIÓN DE NUEVOS AUTORIZADOS', handle:'autorizados', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'RINDE INFORME', handle:'informe', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:''},
    {name:'SOLICITUD DE APLICACIÓN DE MEDIOS ALTERNOS DE SOLUCIÓN DE CONTROVERSIAS', handle:'mediacion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE ARCHIVO', handle:'archivo', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE COPIAS', handle:'copias', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE DEVOLUCIÓN DE FIANZAS', handle:'devolucion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE EJECUTORIA', handle:'ejecutoria', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE PRORROGA', handle:'prorroga', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE REGULARIZACIÓN DE PROCEDIMIENTO', handle:'regularizacion', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE REQUERIMIENTO', handle:'solicitarequerimiento', registry:'PROMOCIÓN', judgment:'nulidad', sala:'1 2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SOLICITUD DE SUSPENSIÓN', handle:'solicitasuspension', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},
    {name:'SUSTITUCIÓN DE TESTIGOS', handle:'testigos', registry:'PROMOCIÓN', judgment:'nulidad', sala:'2 3 4 5 6 7 8 9 10', to_user:'citizen'},*/

    { id: 14, handle: 'alegato', registry: 'promocion', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'ALEGATOS', title: 'Alegatos', color : '#692414', customStyle: ''},
    { id: 1, handle: 'inicial', registry: 'inicial', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen adviser authority', name: 'DEMANDA INICIAL', title: 'Demanda inicial', color : '#692414', customStyle: ''},
    { id: 6, handle: 'apelacion', registry: 'inicial', sala:'2 3 4 5 6 7 8 9 A', judgment: 'grave recurso', to_user: 'citizen adviser', name: 'RECURSO DE APELACIÓN', title: 'Recurso de apelación', color : '#692414', customStyle: ''},
    { id: 8, handle: 'queja', registry: 'promocion', sala:'1', judgment: 'nulidad grave recurso', to_user: 'citizen adviser authority', name: 'RECURSO DE QUEJA', title: 'Recurso de queja', color : '#692414', customStyle: ''},
    { id: 9, handle: 'reclamacion', registry: '', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad grave lesividad nograbe recurso', to_user: 'citizen authority adviser', name: 'RECURSO DE RECLAMACIÓN', title: 'Recurso de reclamación', color : '#692414', customStyle: ''},
    { id: 10, handle: 'recurso', registry: '', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad recurso', to_user: 'citizen authority adviser', name: 'RECURSO DE REVISIÓN', title: 'Recurso de revisión', color : '#692414', customStyle: ''},
    { id: 13, handle: 'agravios', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe',  to_user: 'citizen authority adviser', name: 'CONTESTACIÓN DE AGRAVIOS', title: 'Contestación de agravios', color : '#692414', customStyle: ''},
    { id: 15, handle: 'ampliacion', registry: 'promocion', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen adviser authority', name: 'AMPLIACIÓN DE DEMANDA', title: 'Ampliación de demanda', color : '#692414', customStyle: ''},
    { id: 16, handle: 'apersonamiento', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'authority', name: 'APERSONAMIENTO', title: 'apersonamiento', color : '#692414', customStyle: ''},
    { id: 17, handle: 'ampliacionlesividad', registry: 'promocion', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad', to_user: 'citizen adviser', name: 'CONTESTACIÓN A LA AMPLIACIÓN DE DEMANDA (LESIVIDAD)', title: 'Contestación a la ampliación de demanda (lesividad)', color : '#692414', customStyle: ''},
    { id: 18, handle: 'comparecencia', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'COMPARECENCIA', title: 'Comparecencia', color : '#692414', customStyle: ''},
    { id: 20, handle: 'contestampliacion', registry: 'promocion', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad', to_user: 'authority', name: 'CONTESTACIÓN DE AMPLIACIÓN DE DEMANDA', title: 'contestación de ampliación de demanda', color : '#692414', customStyle: ''},
    { id: 21, handle: 'contestacion', registry: 'promocion', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad', to_user: 'authority', name: 'CONTESTACIÓN DE LA DEMANDA', title: 'contestación de la demanda', color : '#692414', customStyle: ''},
    { id: 22, handle: 'cumplimiento', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'CONVENIO DE CUMPLIMIENTO DE SENTENCIA', title: 'Convenio de cumplimiento de sentencia', color : '#692414', customStyle: ''},
    { id: 23, handle: 'sustituto', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'CONVENIO DE CUMPLIMIENTO SUSTITUTO DE SENTENCIA', title: 'Convenio de cumplimiento sustituto de sentencia', color : '#692414', customStyle: ''},
    { id: 28, handle: 'desahogo', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'DESAHOGO DE VISTA', title: 'desahogo de vista', color : '#692414', customStyle: ''},
    { id: 29, handle: 'nuevodomicilio', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'DESIGNACIÓN DE NUEVO DOMICILIO ELECTRÓNICO', title: 'Designación de nuevo domicilio electrónico', color : '#692414', customStyle: ''},
    { id: 30, handle: 'representante', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'DESIGNACIÓN DE REPRESENTANTE COMÚN', title: 'Designación de representante común', color : '#692414', customStyle: ''},
    { id: 31, handle: 'desistimiento', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'DESISTIMIENTO', title: 'Desistimiento', color : '#692414', customStyle: ''},
    { id: 35, handle: 'cumplimientoejecucion', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'EJECUCIÓN DE CUMPLIMIENTO DE SENTENCIA', title: 'Ejecución de cumplimiento de sentencia', color : '#692414', customStyle: ''},
    { id: 34, handle: 'inconformidad', registry: 'inicial', sala:'1', judgment: 'nograbe recurso', to_user: 'citizen authority', name: 'RECURSO DE INCONFORMIDAD', title: 'Recurso de inconformidad', color : '#692414', customStyle: ''},
    { id: 36, handle: 'excitativa', registry: 'promocion', sala:'1', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'EXCITATIVA DE JUSTICIA', title: 'Excitativa de justicia', color : '#692414', customStyle: ''},
    { id: 37, handle: 'excusas', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen adviser', name: 'EXCUSAS', title: 'Excusa', color : '#692414', customStyle: ''},
    { id: 39, handle: 'inactividadprocesal', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen adviser', name: 'INACTIVIDAD PROCESAL', title: 'Inactividad procesal', color : '#692414', customStyle: ''},
    { id: 40, handle: 'incidenteaclaracion', registry: 'inicial', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE ACLARACIÓN DE SENTENCIA', title: 'Incidente de aclaración de sentencia', color : '#692414', customStyle: ''},
    { id: 41, handle: 'acumulacion', registry: 'inicial', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE ACUMULACIÓN DE AUTOS', title: 'Incidente de acumulación de autos', color : '#692414', customStyle: ''},
    { id: 42, handle: 'incidentesustituto', registry: '', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE CUMPLIMIENTO SUSTITUTO', title: 'Incidente de cumplimiento sustituto', color : '#692414', customStyle: ''},
    { id: 43, handle: 'incompetencia', registry: 'inicial', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE INCOMPETENCIA', title: 'Incidente de incompetencia', color : '#692414', customStyle: ''},
    { id: 44, handle: 'disolucion', registry: 'inicial', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE INTERRUPCIÓN DEL PROCEDIMIENTO POR MUERTE O DISOLUCIÓN DE LAS PERSONAS MORALES', title: 'Incidente de interrupción del procedimiento por muerte o disolución de las personas morales', color : '#692414', customStyle: ''},
    { id: 53, handle: 'informeresponsabilidad', registry: '', sala:'2 3 4 5 6 7 8 9 A', judgment: 'grave', to_user: 'authority', name: 'INFORME DE PRESUNTA RESPONSABILIDAD ADMINISTRATIVA', title: 'Informe de presunta responsabilidad administrativa', color : '#692414', customStyle: ''},
    { id: 45, handle: 'liquidacion', registry: '', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE LIQUIDACIÓN', title: 'Incidente de liquidación', color : '#692414', customStyle: ''},
    { id: 46, handle: 'incidentecautelares', registry: '', sala:'1', judgment: 'grave incidente', to_user: 'citizen authority adviser incidente', name: 'INCIDENTE DE MEDIDAS CAUTELARES', title: 'Incidente de medidas cautelares', color : '#692414', customStyle: ''},
    { id: 47, handle: 'nulidad', registry: 'inicial', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE NULIDAD DE NOTIFICACIONES', title: 'Incidente de nulidad de notificaciones', color : '#692414', customStyle: ''},
    { id: 48, handle: 'informacumplimiento', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'authority', name: 'INFORMA CUMPLIMIENTO', title: 'informa cumplimiento', color : '#692414', customStyle: ''},
    { id: 49, handle: 'ocular', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad grave', to_user: 'citizen adviser', name: 'INSPECCIÓN OCULAR', title: 'inspección ocular', color : '#692414', customStyle: ''},
    { id: 50, handle: 'perito', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'NOMBRAMIENTO DE PERITO', title: 'nombramiento de perito', color : '#692414', customStyle: ''},
    { id: 51, handle: 'objecion', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'OBJECIÓN DE DOCUMENTOS', title: 'objeción de documentos', color : '#692414', customStyle: ''},
    { id: 52, handle: 'prueba', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'OFRECIMIENTO DE PRUEBA', title: 'ofrecimiento de prueba', color : '#692414', customStyle: ''},
    { id: 54, handle: 'directo', registry: 'promocion', sala:'1', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'PRESENTACIÓN DE DEMANDA DE AMPARO DIRECTO', title: 'presentación de demanda de amparo directo', color : '#692414', customStyle: ''},
    { id: 56, handle: 'promocion', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen adviser', name: 'PROMOCIÓN', title: 'Promoción', color : '#692414', customStyle: ''},
    { id: 57, handle: 'suspensionpromocion', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'PROMOCIÓN RELACIONADA CON SUSPENSIÓN', title: 'Promoción relacionada con suspensión', color : '#692414', customStyle: ''},
    { id: 58, handle: 'superveniente', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'PRUEBA SUPERVENIENTE', title: 'prueba superveniente', color : '#692414', customStyle: ''},
    { id: 60, handle: 'autorizados', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'REVOCACIÓN DE AUTORIZADOS Y DESIGNACIÓN DE NUEVOS AUTORIZADOS', title: 'Revocación de autorizados y designación de nuevos autorizados', color : '#692414', customStyle: ''},
    { id: 61, handle: 'informe', registry: 'promocion',  sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'authority', name: 'RINDE INFORME', title: 'rinde informe', color : '#692414', customStyle: ''},
    { id: 62, handle: 'mediacion', registry: 'promocion',  sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'SOLICITUD DE APLICACIÓN DE MEDIOS ALTERNOS DE SOLUCIÓN DE CONTROVERSIAS', title: 'solicitud de aplicación de medios alternos de solución de controversias', color : '#692414', customStyle: ''},
    { id: 63, handle: 'archivo', registry: 'promocion',  sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave',to_user: 'citizen authority adviser', name: 'SOLICITUD DE ARCHIVO', title: 'solicitud de archivo', color : '#692414', customStyle: ''},
    { id: 64, handle: 'falsedaddocumento', registry: 'inicial', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe incidente', to_user: 'citizen authority adviser', name: 'INCIDENTE DE FALSEDAD DEL DOCUMENTO', title: 'Incidente de falsedad del documento', color : '#692414', customStyle: ''},
    { id: 65, handle: 'copias', registry: 'promocion',  sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority adviser', name: 'SOLICITUD DE COPIAS', title: 'Solicitud de copias', color : '#692414', customStyle: ''},
    { id: 66, handle: 'ejecucionsanciones', registry: 'promocion',  sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'grave', to_user: 'citizen authority adviser', name: 'SOLICITUD DE CUMPLIMIENTO Y EJECUCIÓN DE SANCIONES POR FALTAS ADMINISTRATIVAS GRAVES', title: 'Solicitud de cumplimiento y ejecución de sanciones por faltas administrativas graves', color : '#692414', customStyle: ''},
    { id: 67, handle: 'devolucion', registry: 'promocion',  sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'SOLICITUD DE DEVOLUCIÓN DE FIANZAS', title: 'Solicitud de devolución de fianzas', color : '#692414', customStyle: ''},
    { id: 68, handle: 'ejecutoria', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen adviser', name: 'SOLICITUD DE EJECUTORIA', title: 'solicitud de ejecutoria', color : '#692414', customStyle: ''},
    { id: 69, handle: 'cautelares', registry: '', sala:'1', judgment: 'grave', to_user: 'citizen authority adviser', name: 'SOLICITUD DE MEDIDAS CAUTELARES', title: 'Solicitud de medidas cautelares', color : '#692414', customStyle: ''},
    { id: 70, handle: 'prorroga', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'SOLICITUD DE PRÓRROGA', title: 'solicitud de prórroga', color : '#692414', customStyle: ''},
    { id: 71, handle: 'regularizacion', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'SOLICITUD DE REGULARIZACIÓN DE PROCEDIMIENTO', title: 'solicitud de regularización de procedimiento', color : '#692414', customStyle: ''},
    { id: 72, handle: 'solicitarequerimiento', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad', to_user: 'citizen authority adviser', name: 'SOLICITUD DE REQUERIMIENTO', title: 'solicitud de requerimiento', color : '#692414', customStyle: ''},
    
    { id: 73, handle: 'solicitaprorroga', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 10 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'perito', name: 'SOLICITAR PRÓRROGA', title: 'solicitar prórroga', color : '#692414', customStyle: ''},
    { id: 74, handle: 'presentardictamen', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 10 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'perito', name: 'PRESENTAR DICTAMEN', title: 'presentar dictamen', color : '#692414', customStyle: ''},
    { id: 85, handle: 'solicituddefechapararatificardictamen', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 10 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'perito', name: 'SOLICITUD DE FECHA PARA RATIFICAR DICTAMEN', title: 'Solicitud de fecha para ratificar dictamen', color : '#692414', customStyle: ''},
    { id: 86, handle: 'presentaciondeexcusapararendirdictamen', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 10 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'perito', name: 'PRESENTACIÓN DE EXCUSA PARA RENDIR DICTAMEN', title: 'Presentación de excusa para rendir dictamen', color : '#692414', customStyle: ''},
    { id: 75, handle: 'otro', registry: 'promocion', sala:'1 2 3 4 5 6 7 8 9 10 A', judgment: 'nulidad lesividad grave nograbe', to_user: 'citizen authority perito adviser', name: 'OTRO', title: 'otro', color : '#692414', customStyle: ''},
    
    { id: 76, handle: 'solicitasuspension', registry: 'promocion', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad', to_user: 'citizen adviser', name: 'SOLICITUD DE SUSPENSIÓN', title: 'solicitud de suspensión', color : '#692414', customStyle: ''},
    { id: 77, handle: 'testigos', registry: 'promocion', sala:'2 3 4 5 6 7 8 9 A', judgment: 'nulidad lesividad grave', to_user: 'citizen authority adviser', name: 'SUSTITUCIÓN DE TESTIGOS ', title: 'Sustitución de testigos ', color : '#692414', customStyle: ''},
    {id: 78, handle: 'contestaciondemanda', registry: 'promocion', sala: '2 3 4 5 6 7 8 9 A', judgment: 'nulidad', to_user:'citizen adviser', name: 'CONTESTACIÓN DE DEMANDA (LESIVIDAD)', title: 'Contestación de demanda (lesividad)', color: '#692414', customStyle: ''},
    //DATOS ESPECIFICOS
    {id: 79, handle: 'suspensioninicial', registry: '', sala:'', judgment: '', to_user: '', title: 'suspención inicial' ,name: 'SUSPENCIÓN INICIAL'},
    ]

export const TypeFile = [
    { name: 'copia simple', uid:'simple'},
    { name: 'copia certificada', uid:'certificada'},
    { name: 'original con firma', uid:'firmada'},
    { name: 'original sin firma', uid:'original'},
]

export const TipoPromovente = [
    {  nume:'1', type:'promocion revision', name:'Actor'},
    {  nume:'2', type:'promocion revision', name:'Autoridad Demandada'},
    {  nume:'3', type:'promocion revision', name:'Tercero Perjudicado'},
    {  nume:'4', type:'promocion', name:'Recurrente'},
    {  nume:'5', type:'promocion revision', name:'Otro'},
    {  nume:'6', type:'especializada', name:'Presunto responsable'},
    {  nume:'7', type:'especializada', name:'Investigadora'},
    {  nume:'8', type:'especializada', name:'Substanciadora'},
    {  nume:'9', type:'especializada', name:'Resolutora'},
    {  nume:'10', type:'especializada', name:'Terceros'},
    {  nume:'11', type:'especializada', name:'Otra'},
]

export const TipoProcedimiento = [
    { nume: 1, name:'Juicio ordinario'},
    { nume: 2, name:'Juicio sumario'},
    { nume: 8, name:'Procedimiento de responsabilidades por falta administrativa grave'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 11, name:'Excitativa de justicia'},
    { nume: 12, name:'Excusa'},
];

export const Responsabilidad = [
    {  nume:'0', name:'Responsabilidad Administrativa'},
    {  nume:'0', name:'Recurso de Inconformidad'},
    {  nume:'0', name:'Recurso de Reclamación'},
    {  nume:'0', name:'Recurso de Apelación'},
]

export const OrganoJurisdiccional = [
    //A: 'acumulacion agravios apersonamiento archivo autorizados comparecencia copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prorroga prueba queja reclamacion regularizacion representante solicitarequerimiento superveniente suspencionpromocion sustituto',
    //SS: 'directo excitativa incidentecautelares cautelares inconformidad',
    //SR: 'alegato ampliacion ampliacionlesividad apelacion contestacion contestacionlesividad contestampliacion informeresponsabiliadad inicial recurso solicitasuspension testigos',
    {   nume:1, 
        depa:'Sala Superior',
        conf:'acumulacion agravios apersonamiento archivo autorizados cautelares comparecencia copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion directo ejecutoria excusas excitativa falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incidentecautelares incompetencia inconformidad informacumplimiento informe liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion regularizacion representante solicitarequerimiento superveniente suspencionpromocion sustituto', 
    },
    {
        nume: 10,
        depa: 'Sala Regional Acapulco',
        conf:'acumulacion agravios apersonamiento archivo autorizados cautelares comparecencia copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion directo ejecutoria excusas excitativa falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incidentecautelares incompetencia inconformidad informacumplimiento informe liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion regularizacion representante solicitarequerimiento superveniente suspencionpromocion sustituto', 

    },
    {   nume:2, 
        depa:'Sala Regional Acapulco I',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },
    {   nume:3, 
        depa:'Sala Regional Acapulco II',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },
    {   nume:4, 
        depa:'Sala Regional Altamirano',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },
    {   nume:5, 
        depa:'Sala Regional Chilpancingo',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },
    {   nume:6, 
        depa:'Sala Regional Iguala',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },
    {   nume:7, 
        depa:'Sala Regional Ometepec',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },
    {   nume:8, 
        depa:'Sala Regional Tlapa',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },
    {   nume:9, 
        depa:'Sala Regional Zihuatanejo',
        conf:'acumulacion agravios ampliacion ampliacionlesividad alegato apelacion apersonamiento archivo autorizados comparecencia contestacion contestacionlesividad contestampliacion copias cumplimiento cumplimientoejecucion desahogo desistimiento devolucion disolucion ejecutoria excusas falsedaddocumento inactividadprocesal incidentesustituto incidenteaclaracion incompetencia informacumplimiento informe informeresponsabiliadad inicial liquidacion mediacion nuevodomicilio nulidad objecion ocular perito promocion prórroga prueba queja reclamacion recurso regularizacion representante solicitarequerimiento solicitasuspension superveniente suspencionpromocion sustituto testigos',
    },   
]

export const OrganoJurisdiccionalCorrespondencia = [
    { 
        nume:1, 
        depa:'Sala Superior',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa excusas impedimentos inactividad incidenteaclaracion acumulacion sustituto incompetencia disolucion directo responsabilidad promocion suspensionpromocion apelacion inconformidad recurso autorizados copias ejecucionsanciones devolucion cautelares testigos', 
    },
    {   nume:2, 
        depa:'Sala Regional Acapulco I',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:3, 
        depa:'Sala Regional Acapulco II',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:4, 
        depa:'Sala Regional Altamirano',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:5, 
        depa:'Sala Regional Chilpancingo',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:6, 
        depa:'Sala Regional Iguala',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:7, 
        depa:'Sala Regional Ometepec',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:8, 
        depa:'Sala Regional Tlapa',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    },
    {   nume:9, 
        depa:'Sala Regional Zihuatanejo',
        conf:'aclaracion alegato ampliacion contestacion contestampliacion comparecencia cumplimiento incidentesustituto inicialsinsuspension nuevodomicilio representante desistimiento desistimientorecurso cumplimientoejecucion excitativa inactividad incidenteaclaracion acumulacion sustituto disolucion nulidad directo juicionulidad responsabilidad promocion suspensionpromocion queja reclamacion autorizados copias ejecucionsanciones devolucion cautelares testigos',
    }
]


export const TipoRecurso = [
    { nume: 1, name:'Juicio de nulidad'},
    /*{ nume: 2, name:'Juicio sumario'},*/
    { nume: 3, name:'Recurso de revisión'},
    { nume: 4, name:'Recurso de revocación'},
    { nume: 5, name:'Recurso de reclamación'},
    { nume: 6, name:'Recurso de inconformidad'},
    { nume: 7, name:'Recurso de apelación'},
    // { nume: 8, name:'Procedimiento de responsabilidades administrativas'},
    { nume: 8, name:'Juicio por responsabilidad administrativa grave'},
    { nume: 9, name:'Procedimiento de cumplimiento de sentencia'},
    { nume: 10, name:'Conflicto de competencia'},
    { nume: 11, name:'Excitativa de justicia'},
   // { nume: 12, name:'Excusa'},
];

export class FileAnexo {
    name:string;
    file: any;
    error: boolean;
    upload: boolean;
    size: string;
    type: string;
    desc: string;
    txtBtnUp: string;
    numPages: string;
    txtError: string;
    constructor(name: string){
        { 
            this.name = name; 
            this.file = undefined;
            this.error = false; 
            this.txtError = '';
            this.upload = false;
            this.size = '';
            this.type = '';
            this.desc = ''; 
            this.txtBtnUp = 'Agregar archivo';
            this.numPages = '0';
        }
    }
} 

export const TipoJuicios = [
    {nume:1, type: 'nulidad', name: 'Nulidad/Lesividad'},
    {nume:2, type: 'grave', name: 'Responsabilidad administrativa grave'},
    {nume:3, sala:'1',type: 'nograbe', name: 'Responsabilidad administrativa no grave'}
    /*{ nume: 1,  name: 'Juicio Administrativo'},
    { nume: 2,  name: 'Juicio Fiscal'},
    { nume: 15, name:  'Juicio de Lesividad'},
    { nume: 3,  name: 'Recurso de Queja'},
    { nume: 4,  name: 'Recurso de Revisión'},
    { nume: 5,  name: 'Cumplimiento de Sentencia en Sala Superior'},
    { nume: 6,  name: 'Cuadernillo de Amparo en Sala Superior'},
    { nume: 7,  name: 'Excitativas de Justicia'},
    { nume: 8,  name: 'Conflicto de Competencia entre Salas Regionales'},
    { nume: 9,  name: 'Procedimiento de Responsabilidad Administrativa'},
    { nume: 10, name:  'Recurso de Reclamación'},
    { nume: 11, name:  'Recurso de Apelación'},
    { nume: 12, name:  'Procedimiento de Responsabilidades Administrativas'},
    { nume: 16, name:  'Procedimiento de Responsabilidades Administrativas por Faltas Graves'},
    { nume: 13, name:  'Recurso de Inconformidad'},
    { nume: 14, name:  'Incidentes'},*/

   /* { nume: 15, name:  'Investigación'},*/
];

export const ListadoAcuerdos = [
    {nume: 0,  name:'Otro'},
    {nume: 1,  name:'Admisión'},
    {nume: 2,  name:'Desechamiento'},
    {nume: 3,  name:'Ampliación'},
    {nume: 4,  name:'Sentencia'},
    {nume: 5,  name:'Contestación de Demanda'},
    {nume: 6,  name:'Audiencia'},
    {nume: 7,  name:'Nulidad de Notificación'},
    {nume: 8,  name:'Acuerdo de recepción de expediente'},
    {nume: 9,  name:'Acuerdo de reclasificación'},
    {nume: 10, name: 'Acuerdo de devolución'},
    {nume: 11, name: 'Acuerdo de envío'},
    {nume: 12, name: 'Cierre de instrucción'},
    {nume: 13, name: 'Audiencia diferida'},
    {nume: 14, name: 'Contestación a la ampliación'},
    {nume: 15, name: 'Ampliación de demanda'},
    {nume: 16, name: 'Manifestaciones'},
    {nume: 17, name: 'Exhibe expediente'},
    {nume: 18, name: 'Ofrecimiento de pruebas'},
    {nume: 19, name: 'Aclaración sentencia'},
    {nume: 20, name: 'Desahogo'},
    {nume: 21, name: 'Reposición '},
    {nume: 22, name: 'Copias'},
    {nume: 23, name: 'Alegatos'},
    {nume: 24, name: 'Cumplimiento'},
    {nume: 25, name: 'Conciliación'},
    {nume: 26, name: 'Ejecutoria'},
    {nume: 27, name: 'Archivo'},
    {nume: 28, name: 'Incompetencia'},
    /*{nume: 29, name: 'Túrnese a proyectos'},*/
    {nume: 30, name: 'Acuse de recibo'},
    {nume: 31, name: 'Admisión de pruebas'},
    {nume: 32, name: 'Requerimiento'},
    {nume: 33, name: 'Desahogo de requerimiento'},
    {nume: 34, name: 'Presentación de alegatos'},
    {nume: 35, name: 'Remisión a Sala Superior'},
    {nume: 36, name: 'Acuerdo donde señala domicilio el presunto, la investigadora o substanciadora'},
    {nume: 37, name: 'Regularización'},
    {nume: 38, name: 'Reserva'},
    {nume: 39, name: 'Admisión de queja'},
    {nume: 40, name: 'Recepción de documentos'},
    {nume: 41, name: 'Acumulación'},
    {nume: 42, name: 'Conclusión'},
    {nume: 43, name: 'Remisión'},
    {nume: 44, name: 'Sala a la que se remite'},
];

export const TipoParte = [
    { nume: 1, name: 'Actor'},
    { nume: 2, name: 'Demandado'},
    { nume: 3, name: 'Tercero perjudicado'},
    { nume: 4, name: 'Todas las partes'},
   /* { nume: 4, name: 'Recurrente'},
    { nume: 5, name: 'Autoridad vinculada'},
    /*{ nume: 6, name: 'Delegado fiscal'},
    { nume: 7, name: 'Juzgado de distrito'},
    { nume: 8, name: 'Órgano jurisdiccional exhortante'},
    { nume: 45, name: 'Autoridad acusadora'},
    { nume: 9, name: 'Autoridad investigadora'},
    { nume: 10, name: 'Autoridad substanciadora'},
    { nume: 11, name: 'Presunto responsable'},
    { nume: 47, name: 'Presunto infractor'},
    { nume: 12, name: 'Denunciante'},
    { nume: 13, name: 'Notifíquese'},*/
    /*
    Recurso de apelación
    Recurso de inconformidad

    Alegatos
    Ampliacion
    Correspondencia
    Excitativa de justicia
    Incidente de nulidad de notificaciones
    Juicio de amparo (solicita informes previos)
    Promocion relacionada con suspension
    Promocion
    

    { nume: 14, name: 'Actor - Recurrente'},
    { nume: 15, name: 'Actor - Tercero perjudicado'},
    { nume: 16, name: 'Actor - Autoridad vinculada'},
    { nume: 17, name: 'Actor - Delegado fiscal'},
    { nume: 18, name: 'Actor - Juzgado de distrito'},
    { nume: 19, name: 'Actor - Órgano jurisdiccional exhortante'},
    { nume: 20, name: 'Actor - Autoridad investigadora'},
    { nume: 21, name: 'Actor - Autoridad substanciadora'},
    { nume: 22, name: 'Actor - Presunto responsable'},
    { nume: 23, name: 'Actor - Denunciante'},
    { nume: 24, name: 'Actor - Notifíquese'},
    { nume: 25, name: 'Actor - Autoridad Demandada'},
    { nume: 26, name: 'Actor - Autoridad Demandada - Recurrente'},
    { nume: 27, name: 'Actor - Autoridad Demandada - Tercero perjudicado'},
    { nume: 28, name: 'Actor - Autoridad Demandada - Autoridad vinculada'},
    { nume: 29, name: 'Actor - Autoridad Demandada - Delegado fiscal'},
    { nume: 30, name: 'Actor - Autoridad Demandada - Juzgado de distrito'},
    { nume: 31, name: 'Actor - Autoridad Demandada - Entidad exhortante'},
    { nume: 32, name: 'Actor - Autoridad Demandada - Autoridad investigadora'},
    { nume: 33, name: 'Actor - Autoridad Demandada - Autoridad substanciadora'},
    { nume: 34, name: 'Actor - Autoridad Demandada - Presunto responsable'},
    { nume: 35, name: 'Actor - Autoridad Demandada - Denunciante'},
    { nume: 36, name: 'Actor - Autoridad Demandada - Notifíquese'},
    { nume: 37, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable - Denunciante'},
    { nume: 38, name: 'Autoridad investigadora - Autoridad substanciadora - Presunto responsable'},
    { nume: 39, name: 'Autoridad investigadora - Autoridad substanciadora'},
    /*{ nume: 40, name: 'Cúmplase'},*/
    /*{ nume: 41, name: 'Tribunal Colegiado de Circuito'},
    { nume: 42, name: 'Auditoria Superior del Estado de Guerrero'},
    /*{ nume: 43, name: 'Interesados'},
    { nume: 44, name: 'Tribunal de Conciliación y Arbitraje del Estado de Guerrero'},
    { nume: 46, name: 'Tribunal Electoral del Estado de Guerrero'},*/
];

export const ListadoAcuerdosSalas = [
    { 
        nume:1, 
        name:'Sala Superior',
        conf:'copias autorizados nuevodomicilio comparecencia cumplimiento sutituto directo reclamacion aclaracion', 
    },
    {   nume:2, 
        name:'Sala Regional Acapulco I',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:3, 
        name:'Sala Regional Acapulco II',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:4, 
        name:'Sala Regional Altamirano',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:5, 
        name:'Sala Regional Chilpancingo',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:6, 
        name:'Sala Regional Iguala',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:7, 
        name:'Sala Regional Ometepec',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:8, 
        name:'Sala Regional Tlapa',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    },
    {   nume:9, 
        name:'Sala Regional Zihuatanejo',
        conf:'inicial inicialsinsuspension  responsabilidad copias autorizados nuevodomicilio comparecencia cumplimiento sutituto desistimiento directo aclaracion queja reclamacion acumulacion disolucion incompetencia',
    }
];
export const TipoMateria=[
    {nume: 1, type: 'nulidad lesividad', name:'Administrativa',handle:"administrativa"},
    {nume: 2, type: 'nulidad lesividad', name:'Fiscal',handle:"fiscal"},
    {nume: 3, type: 'nulidad lesividad', name:'Responsabilidades Administrativas',handle:"respadmin"},
    // {nume: 4, type: 'grave', name:'Servidores Públicos',handle:"responsadmin"},
    // {nume: 5, type: 'grave', name:'Particulares vinculados con faltas administrativas graves',handle:"partvincf"},
    {nume: 4, type: 'grave',name:'Responsabilidades Administrativas',handle:"responsadmin" },

];
export const Tipoactos=[
    {nume: 1, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Anuncios'},
    {nume: 2, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Contrato de obra pública'},
    {nume: 3, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Establecimiento mercantil'},
    {nume: 47, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Desarrollo urbano'},
    {nume: 4, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Licencias de funcionamiento'},
    {nume: 5, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Infracciones de tránsito'},
    {nume: 6, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Resolución administrativa'},
    {nume: 7, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Visitas de verificación'},
    {nume: 8, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Multas'},
    {nume: 9, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Transporte'},
    {nume: 10, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Registro público'},
    {nume: 11, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Seguridad pública'},
    {nume: 12, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Seguridad social'},
    {nume: 13, type: 'administrativa',typem: 'nulidad lesividad', pr:'', name:'Protección ecológica'},
    {nume: 14, type: 'administrativa fiscal', typem: 'nulidad lesividad', pr:'', name:'Negativa ficta'},
    {nume: 15, type: 'administrativa fiscal', typem: 'nulidad lesividad', pr:'', name:'Positiva /afirmativa ficta'},
    {nume: 16, type: 'administrativa fiscal', typem: 'nulidad lesividad', pr:'', name:'Silencio administrativo'},
    
    {nume: 18, type: 'fiscal', typem: 'nulidad lesividad', pr:'', name:'Agua'},
    {nume: 19, type: 'fiscal', typem: 'nulidad lesividad', pr:'', name:'Predial'},
    {nume: 20, type: 'fiscal', typem: 'nulidad lesividad', pr:'', name:'Adquisición de inmuebles/traslado de dominio'},
    {nume: 21, type: 'fiscal', typem: 'nulidad lesividad', pr:'', name:'Remuneración al trabajo personal'},
    {nume: 22, type: 'fiscal', typem: 'nulidad lesividad', pr:'', name:'Procedimiento administrativo de ejecución fiscal (aprovechamientos)'},
    {nume: 23, type: 'fiscal', typem: 'nulidad lesividad', pr:'', name:'Multas o créditos fiscales'},


    {nume: 24, type: 'respadmin', typem: 'nulidad lesividad', pr:'', name:'Responsabilidad patrimonial del estado'},
    {nume: 25, type: 'respadmin', typem: 'nulidad lesividad', pr:'', name:'Responsabilidad de servidores públicos'},

    {nume: 26, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Cohecho'},
    {nume: 27, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Peculado'},
    {nume: 28, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Desvío de recursos públicos'},
    {nume: 29, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Utilización indebida de información'},
    {nume: 30, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Abuso de funciones'},
    {nume: 31, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Actuación bajo conflicto de interés'},
    {nume: 32, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Contratación indebida'},
    {nume: 33, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Enriquecimiento oculto u ocultamiento de conflicto de interés'},
    {nume: 34, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Tráfico de influencias'},
    {nume: 35, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Encubrimiento'},
    {nume: 36, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Desacato'},
    {nume: 37, type: 'responsadmin',  typem:'grave' ,pr:'servidor_publico'  ,name:'Obstrucción de justicia'},
    {nume: 38, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Soborno'},
    {nume: 39, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Participación ilícita en procedimientos administrativos'},
    {nume: 40, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Tráfico de influencias para inducir a la autoridad'},
    {nume: 41, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Utilización de información falsa'},
    {nume: 42, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Obstrucción de facultades de investigación'},
    {nume: 43, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Colusión'},
    {nume: 44, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Uso indebido de recursos públicos'},
    {nume: 45, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Contratación indebida de ex servidores públicos'},
    {nume: 46, type: 'responsadmin',  typem:'grave' ,pr:'particular'        ,name:'Faltas de particulares en situación especial'},
    {nume: 17, type: 'administrativa fiscal', typem: 'nulidad lesividad', pr:'', name:'Otros'},


    // {nume: 38, type: 'partvincf', typem: 'grave',name:'Soborno'},
    // {nume: 39, type: 'partvincf', typem: 'grave',name:'Participación ilícita en procedimientos administrativos'},
    // {nume: 40, type: 'partvincf', typem: 'grave',name:'Tráfico de influencias para inducir a la autoridad'},
    // {nume: 41, type: 'partvincf', typem: 'grave',name:'Utilización de información falsa'},
    // {nume: 43, type: 'partvincf', typem: 'grave',name:'Obstrucción de facultades de investigación'},
    // {nume: 43, type: 'partvincf', typem: 'grave',name:'Colusión'},
    // {nume: 44, type: 'partvincf', typem: 'grave',name:'Uso indebido de recursos públicos'},
    // {nume: 45, type: 'partvincf', typem: 'grave',name:'Contratación indebida de ex servidores públicos'},
    // {nume: 46, type: 'partvincf', typem: 'grave',name:'Faltas de particulares en situación especial'},
];
export const TipocaracterF=[
    {nume: 1, type: 'responsadmin', name:'Servidores Públicos',handle:'servidor_publico'}, 
    {nume: 2, type: 'responsadmin', name:'Particulares vinculados con faltas administrativas graves',handle:'particular'}, 
];

export const PresuntoResponsable=[
    {nume: 1, type: 'responsadmin', name:'Particular (Física o Moral)',handle:'Particular (Física o Moral)'}, 
    {nume: 2, type: 'responsadmin', name:'Servidor Público',handle:'Servidor Público'}, 
    {nume: 3, type: 'responsadmin', name:'En Situación Especial',handle:'En Situación Especial'}, 
];

export const TipoFaltas=[
    {nume: 1, type: 'Servidores_Públicos',name:'Cohecho'},
    {nume: 2, type: 'Servidores_Públicos',name:'Peculado'},
    {nume: 3, type: 'Servidores_Públicos',name:'Desvío de recursos públicos'},
    {nume: 4, type: 'Servidores_Públicos',name:'Utilización indebida de información'},
    {nume: 5, type: 'Servidores_Públicos',name:'Abuso de funciones'},
    {nume: 6, type: 'Servidores_Públicos',name:'Actuación bajo conflicto de interés'},
    {nume: 7, type: 'Servidores_Públicos',name:'Contratación indebida'},
    {nume: 8, type: 'Servidores_Públicos',name:'Enriquecimiento oculto u ocultamiento de conflicto de interés'},
    {nume: 9, type: 'Servidores_Públicos',name:'Tráfico de influencias'},
    {nume: 10, type: 'Servidores_Públicos',name:'Encubrimiento'},
    {nume: 11, type: 'Servidores_Públicos',name:'Desacato'},
    {nume: 12, type: 'Servidores_Públicos',name:'Obstrucción de justicia'},
    {nume: 13, type: 'Particulares_vinculados',name:'Soborno'},
    {nume: 14, type: 'Particulares_vinculados',name:'Participación ilícita en procedimientos administrativos'},
    {nume: 15, type: 'Particulares_vinculados',name:'Tráfico de influencias para inducir a la autoridad'},
    {nume: 16, type: 'Particulares_vinculados',name:'Utilización de información falsa'},
    {nume: 17, type: 'Particulares_vinculados',name:'Obstrucción de facultades de investigación'},
    {nume: 18, type: 'Particulares_vinculados',name:'Colusión'},
    {nume: 19, type: 'Particulares_vinculados',name:'Uso indebido de recursos públicos'},
    {nume: 20, type: 'Particulares_vinculados',name:'Contratación indebida de ex servidores públicos'},
    {nume: 21, type: 'Particulares_vinculados',name:'Faltas de particulares en situación especial'},
];
export const TipoSentido = [
    {nume: 1, type: 'nulidad lesividad', name:'Demanda inicial'},
    {nume: 19, type: 'grave', name: 'Recepción de IPRA'},
    {nume: 2, type: 'nulidad lesividad', name:'Suspensión'},
    {nume: 3, type: 'nulidad lesividad', name:'Fianzas'},
    {nume: 4, type: 'nulidad lesividad', name:'Contestación de demanda'},
    {nume: 5, type: 'nulidad lesividad', name:'Ampliación y su contestación'},
    {nume: 6, type: 'nulidad lesividad grave', name:'Apersonamiento a juicio de terceros'},
    {nume: 7, type: 'nulidad lesividad grave', name:'Pruebas'},
    {nume: 8, type: 'nulidad lesividad grave', name:'Desistimiento'},
    {nume: 9, type: 'nulidad lesividad grave', name:'Audiencia'},
    {nume: 10, type: 'nulidad lesividad grave', name:'Recursos'},
    {nume: 11, type: 'nulidad lesividad grave', name:'Incidentes'},
    {nume: 12, type: 'nulidad lesividad grave', name:'Ejecución de sentencia'},
    {nume: 13, type: 'nulidad lesividad', name:'Medios alternos de solución de controversias'},
    {nume: 14, type: 'nulidad lesividad', name:'Convenios y comparecencias'},
    {nume: 15, type: 'nulidad lesividad grave', name:'Nueva autoridad y/o administración'},
    {nume: 16, type: 'nulidad lesividad grave', name:'Se recibe expediente de Sala Superior'},
    {nume: 17, type: 'nulidad lesividad grave', name:'Amparo indirecto'},
    {nume: 18, type: 'nulidad lesividad grave', name:'Acuerdos frecuentes'},


    //{nume: 1,  name:'Incidente de tacha de testigos'},
    //{nume: 2,  name:'Resolución de tacha de testigos'},
    /*{nume: 3,  name:'Apertura de periodos de alegatos '},*/
    /*{nume: 4,  name:'Turno de sentencia'},*/
    /*{nume: 5,  name:'Admisión y/o presentación de escrito inicial'},
    {nume: 6,  name:'Desahogo de vista'},
    {nume: 7,  name:'Certificación secretarial'},
    {nume: 10,  name:'Se recibe expediente'},
    {nume: 11,  name:'Acuerdo de regularización'},
    {nume: 12,  name:'Autorización de copias'},*/
    /*{nume: 13,  name:'Túrnese a proyecto'},*/
    /*{nume: 14,  name:'Acuerdo de arreglos conciliatorios por acuerdo de convenio'},
    {nume: 15,  name:'No se tiene por cumplida la sentencia y se requiere'},
    {nume: 16,  name:'Admisión de prueba superveniente'},
    //{nume: 17,  name:'Sentencia'},
    {nume: 18,  name:'Cause ejecutoria'},
    {nume: 19,  name:'Archivo'},
    {nume: 20,  name:'Vistas actor'},
    {nume: 21,  name:'Vistas particular'},
    {nume: 22,  name:'Desahogo requerimiento autoridad'},
    {nume: 23,  name:'Desahogo requerimiento actor'},
    {nume: 24,  name:'Desahogo requerimiento tercero'},
    //{nume: 25,  name:'Desahogo de expediente formado'},
    {nume: 27,  name:'Difiere audiencia'},
    {nume: 28,  name:'Se nombra autorizados'},
    {nume: 29,  name:'Se autoriza la expedición de copias simples'},
    {nume: 30,  name:'Se autoriza la expedición de copias certificadas'},
    {nume: 31,  name:'Vincula autoridad cumplimiento'},
    {nume: 32,  name:'Llamar nueva autoridad demandada'},
    {nume: 34,  name:'Cuantificación liquidación'},
    {nume: 35,  name:'Sentencia no cumplida'},
    {nume: 36,  name:'No ha lugar a acordar de conformidad por no ser parte'},
    {nume: 38,  name:'Requerimiento al actor'},
    {nume: 39,  name:'Requerimiento a la autoridad '},
    {nume: 40,  name:'Agréguese a los autos para los efectos legales a que haya lugar'},
    {nume: 41,  name:'Vías de cumplimiento'},
    {nume: 42,  name:'Se concede prórroga cumplimiento'},
    {nume: 43,  name:'Se concede prórroga  para desahogo requerimiento'},
    {nume: 44,  name:'Requerimiento no cumplido'},
    {nume: 45,  name:'Búsqueda exhaustiva'},
    {nume: 46,  name:'Estése a lo acordado '},
    {nume: 48,  name:'Autoridades confesas'},
    {nume: 49,  name:'Autoridad se allana'},
    {nume: 50,  name:'Requerimiento de Sala Superior'},
    {nume: 51,  name:'Tercero perjudicado se apersona'},
    {nume: 52,  name:'Juzgado de distrito solicita informe previo'},
    {nume: 53,  name:'Juzgado de distrito solicita informe justificado'},
    {nume: 54,  name:'Juzgado de distrito concede suspensión'},
    {nume: 55,  name:'Juzgado de distrito sin efectos suspensión'},
    {nume: 56,  name:'Juzgado de distrito notifica sentencia'},
    {nume: 57,  name:'Juzgado de distrito tiene por cumplida sentencia'},
    {nume: 58,  name:'Juzgado de distrito tiene por no cumplida sentencia'},
    {nume: 59,  name:'Requiere y sanciona con multa autoridad'},
    {nume: 60,  name:'Solicitud de datos personales para imposición de sanción autoridad'},
    {nume: 61,  name:'Remisión de datos para ejecutar sanción a autoridad'},
    {nume: 62,  name:'Cumplimiento parcial de sentencia y requiere cumplimiento'},
    {nume: 63,  name:'Remite juicio de diversa autoridad'},
    {nume: 64,  name:'Se admite el recurso interpuesto'},
    {nume: 65,  name:'Se desecha el recurso interpuesto'},
    {nume: 66,  name:'Se apercibe para señalar domicilio dentro de la sede de la Sala Superior o sala regional'},
    {nume: 67,  name:'Se hace efectivo el apercibimiento decretado de autos'},
    {nume: 68,  name:'Se tiene por señalado el domicilio para oír y recibir notificaciones y por autorizados a los profesionistas'},
    {nume: 69,  name:'Se ordena dar vista y se señala plazo para desahogo'},
    {nume: 70,  name:'Se tiene por desahogada la vista y por hechas las manifestaciones'},
    {nume: 71,  name:'Se tiene por autorizados a los profesionistas'},
    {nume: 72,  name:'No ha lugar a acordar de conformidad lo solicitado'},
    {nume: 74,  name:'Se turna a ponencia y se designa magistrado ponente para la formulación del proyecto de resolución'},
    {nume: 75,  name:'Se ordena devolver los autos a la Sala Regional de origen'},
    {nume: 76,  name:'Se previene'},
    {nume: 78,  name:'Se apercibe'},
    {nume: 79,  name:'Se declara preclusión'},
    {nume: 80,  name:'Se tiene por presentado y se ordena glosar a los autos'},
    {nume: 81,  name:'Se remite acuse de recibo correspondiente'},
    {nume: 83,  name:'Se ordena girar oficio'},
    {nume: 84,  name:'Se recibe expedientes de Sala Superior'},
    {nume: 85,  name:'Se presenta demanda de Amparo Directo'},
    {nume: 86,  name:'Se remite expediente al Tribunal Colegiado'},
    {nume: 87,  name:'No ha lugar de admitir prueba superveniente'},
    {nume: 88,  name:'Se recibe expediente de Tribunal Colegiado'},
    //{nume: 89,  name:'Validez'},
    //{nume: 90,  name:'Invalidez'},
    //{nume: 91,  name:'Sobresee'},
    /*{nume: 92,  name:'Confirma sentencia'},*/
    //{nume: 93,  name:'Modifica sentencia'},
    //{nume: 94,  name:'Revoca sentencia'},*/
    /*{nume: 95,  name:'Se concede suspensión en auxilio de autoridad de amparo'},
    {nume: 96,  name:'Otro'},
    {nume: 97,  name:'Desechamiento'},
    {nume: 98,  name:'Audiencia'},
    {nume: 99,  name:'Reclasificación'},
    {nume: 100, name: 'Devolución'},
    {nume: 101, name: 'Envío'},*/
    /*{nume: 102, name: 'Cierre de instrucción'},*/
    /*{nume: 103, name: 'Incompetencia'},
    {nume: 104, name: 'Admisión de pruebas'},
    {nume: 105, name: 'Presentación de alegatos'},
    {nume: 106, name: 'Señala domicilio el presunto'},
    {nume: 107, name: 'Reserva'},
    {nume: 108, name: 'Acumulación'},
    {nume: 109, name: 'Remisión'},*/

    //Recurso Options = {

];


export const AditionalOptions=[
    

    //Recurso Options
    { nume: 1,  acuerdo_id: 10, type:"nulidad lesividad", name: "Revisión" },
    { nume: 4,  acuerdo_id: 10, type:"grave", name: "Apelación" },
    { nume: 2,  acuerdo_id: 10, type:"nulidad lesividad grave", name: "Reclamación" },
    { nume: 5,  acuerdo_id: 10, type:"grave", name: "Reclamación (R.Grave)" },
    { nume: 3,  acuerdo_id: 10, type:"nulidad lesividad grave", name: "Queja" },
    
      //Incidente Options
    { nume: 6,  acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Acumulación de autos"},
    { nume: 7,  acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Nulidad de notificaciones"},
    { nume: 8, acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Interrupción del procedimiento por muerte o por disolución en el caso de las personas morales"},
    { nume: 9, acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Incompetencia por razón de territorio"},
    { nume: 10, acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Aclaración de sentencia"},
    { nume: 11, acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Liquidación"},
    //{ nume: 12, acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Cumplimiento sustituto"},
    { nume: 13, acuerdo_id: 11,type:"nulidad lesividad grave",  name:"Falsedad de documento"},
    { nume: 21, acuerdo_id: 11,type:"nulidad lesividad",  name:"Incidente de incompetencia por materia"},

    
    //AMPARO INDIRECTO
    {nume: 14, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se rinde informe"}, 
    {nume: 15, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Trámite de amparo"},
    {nume: 16, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Sentido de ejecutoria"},
    {nume: 17, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Cumplimiento de ejecutoria"},
    
    //Acuerdos frecuentes 
    
    {nume: 18, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Domicilio procesal y autorizados"}, 
    {nume: 19, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Representante común"},
    {nume: 20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Otros"},
  
  
  ];
  
export const AcuerdoSentidoOptions=[

    //Demanda Inicial 
    {nume: 1, acuerdo_id: 1,type:"nulidad lesividad", name:"Se admite demanda", inicial:true},                                                                          
    {nume: 2, acuerdo_id: 1,type:"nulidad lesividad", name:"Se previene en demanda obscura e irregular" , inicial:true},
    {nume: 3, acuerdo_id: 1, type:"nulidad lesividad", name:"Se recibe expediente de Sala Superior por declinatoria de competencia. Se previene para que adecue la demanda" , inicial:true},
    {nume: 4, acuerdo_id: 1,type:"nulidad lesividad", name:"No desahoga la prevención y se desecha la demanda"},
    {nume: 5, acuerdo_id: 1, type:"nulidad lesividad", name:"Se desecha demanda", inicial:true},
    //SUSPENSIÓN
    
    {nume: 6, acuerdo_id: 2,type:"nulidad lesividad", name:"Se concede la suspensión"},
    {nume: 7, acuerdo_id: 2,type:"nulidad lesividad", name:"Se concede la suspensión previa garantía"},
    {nume: 8, acuerdo_id: 2,type:"nulidad lesividad", name:"Parte actora no exhibe garantía y se deja sin efectos la suspensión"},
    {nume: 9, acuerdo_id: 2,type:"nulidad lesividad", name:"Se niega la suspensión"},
    {nume: 10, acuerdo_id: 2,type:"nulidad lesividad", name:"Se revoca la suspensión"},
    {nume: 11, acuerdo_id: 2,type:"nulidad lesividad", name:"Se da vista respecto de la suspensión"},
    {nume: 12, acuerdo_id: 2,type:"nulidad lesividad", name:"Se desahoga la vista sobre la suspensión y se tiene por cumplida"},
    {nume: 13, acuerdo_id: 2,type:"nulidad lesividad", name:"Se desahoga la vista sobre la suspensión y se tiene por no cumplida"},
    {nume: 14, acuerdo_id: 2,type:"nulidad lesividad", name:"Se requiere cumplimiento de la suspensión"},
    {nume: 15, acuerdo_id: 2,type:"nulidad lesividad", name:"Se tiene por informado el cumplimiento de la suspensión"},
    {nume: 16, acuerdo_id: 2,type:"nulidad lesividad", name:"Se tiene por cumplida la suspensión"},
    {nume: 17, acuerdo_id: 2,type:"nulidad lesividad", name:"Se tiene por no cumplida la suspensión y se impone multa"},
    {nume: 18, acuerdo_id: 2,type:"nulidad lesividad", name:"Se tiene por cumplida parcialmente la suspensión y se impone multa"},
    {nume: 19, acuerdo_id: 2,type:"nulidad lesividad", name:"Se tiene por no cumplida la suspensión y se envía a Sala Superior para continuar con el procedimiento de ejecución"},
    
    //FIANZAS
    
    {nume: 20, acuerdo_id: 3,type:"nulidad lesividad", name:"Se ordena fijar fianza o garantía"},
    {nume: 21, acuerdo_id: 3,type:"nulidad lesividad", name:"Se tiene por recibida la fianza o garantía para el otorgamiento de la suspensión"},
    {nume: 22, acuerdo_id: 3,type:"nulidad lesividad", name:"Se deja sin efecto la suspensión por no exhibir fianza o garantía"},
    {nume: 23, acuerdo_id: 3,type:"nulidad lesividad", name:"Se tiene por recibida la contragarantía del tercero perjudicado para dejar sin efecto la suspensión"},
    {nume: 24, acuerdo_id: 3,type:"nulidad lesividad", name:"Se señala fecha y hora para devolución de fianza o garantía"},
    {nume: 25, acuerdo_id: 3,type:"nulidad lesividad", name:"Comparecencia para devolución de fianza o garantía"},
    
    //Contestación de demanda
    
    {nume: 26, acuerdo_id: 4,type:"nulidad lesividad", name:"Se tiene por contestada la demanda"},
    {nume: 27, acuerdo_id: 4,type:"nulidad lesividad", name:"Se tiene por no contestada la demanda"},
    {nume: 28, acuerdo_id: 4,type:"nulidad lesividad", name:"Se previene respecto de la contestación de demanda"},
    {nume: 29, acuerdo_id: 4,type:"nulidad lesividad", name:"Se desahoga prevención y se tiene por contestada la demanda"},
    //Ampliación y su contestación
    {nume: 30, acuerdo_id: 5,type:"nulidad lesividad", name:"Se tiene por ampliada la demanda"},
    {nume: 31, acuerdo_id: 5,type:"nulidad lesividad", name:"Se tiene por no ampliada la demanda"},
    {nume: 32, acuerdo_id: 5,type:"nulidad lesividad", name:"Se previene respecto del escrito de ampliación de demanda"},
    {nume: 33, acuerdo_id: 5,type:"nulidad lesividad", name:"Se desahoga prevención y se tiene por ampliada la demanda"},
    {nume: 34, acuerdo_id: 5,type:"nulidad lesividad", name:"Se tiene por contestada la ampliación de demanda"},
    {nume: 35, acuerdo_id: 5,type:"nulidad lesividad", name:"Se tiene por no contestada la ampliación de la demanda"},
    {nume: 36, acuerdo_id: 5,type:"nulidad lesividad", name:"Se previene respecto de la contestación a la ampliación de demanda"},
    {nume: 37, acuerdo_id: 5,type:"nulidad lesividad", name:"Se desahoga prevención y se tiene por contestada la ampliación de la demanda"},
    //Apersonamiento a juicio de terceros
    {nume: 38, acuerdo_id: 6,type:"nulidad lesividad grave", name:"Se tiene por apersonado a juicio"},
    {nume: 39, acuerdo_id: 6,type:"nulidad lesividad grave", name:"Se previene respecto del apersonamiento a juicio"},
    {nume: 40, acuerdo_id: 6,type:"nulidad lesividad grave", name:"Se desahoga prevención y se le tiene por apersonado a juicio"},
    //PRUEBAS
    {nume: 41, acuerdo_id: 7,type:"nulidad lesividad grave", name:"Se tiene por ofrecida la prueba superveniente y se da vista"},
    {nume: 42, acuerdo_id: 7,type:"nulidad lesividad grave", name:"Se desahoga la vista respecto de la prueba superveniente"},
    {nume: 43, acuerdo_id: 7,type:"nulidad lesividad grave", name:"Trámite de informe de autoridad"},
    {nume: 44, acuerdo_id: 7,type:"nulidad lesividad grave", name:"Trámite de testimonial"},
    {nume: 45, acuerdo_id: 7,type:"nulidad lesividad grave", name:"Trámite de pericial"},
    {nume: 46, acuerdo_id: 7,type:"nulidad lesividad grave", name:"Trámite de la inspección"},
    //DESISTIMIENTO
    {nume: 47, acuerdo_id: 8,type:"nulidad lesividad", name:"Se desiste de la demanda y señala fecha para ratificación"},
    {nume: 48, acuerdo_id: 8,type:"nulidad lesividad grave", name:"Se desiste del incidente y señala fecha para ratificación"},
    {nume: 49, acuerdo_id: 8,type:"nulidad lesividad grave", name:"Se desiste del recurso y señala fecha para ratificación"},
    {nume: 50, acuerdo_id: 8,type:"nulidad lesividad grave", name:"Se desiste de las pruebas y señala fecha para ratificación"},
    {nume: 51, acuerdo_id: 8,type:"nulidad lesividad", name:"Se desiste del juicio y señala fecha para ratificación"},
    {nume: 52, acuerdo_id: 8,type:"nulidad lesividad grave", name:"Desistimiento por comparecencia"},
    {nume: 53, acuerdo_id: 8,type:"nulidad lesividad grave", name:"Comparecencia para ratificación del desistimiento"},
    {nume: 54, acuerdo_id: 8,type:"nulidad lesividad grave", name:"No comparece para desistimiento se ordena continuar con el procedimiento"},
    
    //AUDIENCIA
    
    {nume: 55, acuerdo_id: 9,type:"nulidad lesividad", name:"Se tienen por presentados los alegatos"},
    {nume: 56, acuerdo_id: 9,type:"nulidad lesividad", name:"Se difiere audiencia de ley y se señala nueva fecha"},
    {nume: 57, acuerdo_id: 9,type:"nulidad lesividad", name:"Acta de audiencia de ley"},
    {nume: 58, acuerdo_id: 9,type:"grave", name:"Se tienen por admitidas las pruebas"},
    {nume: 59, acuerdo_id: 9,type:"grave", name:"Se difiere el desahogo de las pruebas"},
    {nume: 60, acuerdo_id: 9,type:"grave", name:"Audiencia de desahogo de pruebas"},
    {nume: 61, acuerdo_id: 9,type:"grave", name:"Se declara abierto periodo de alegatos"},
    {nume: 62, acuerdo_id: 9,type:"grave", name:"Se tienen por formulados los alegatos y se cierra la instrucción"},
    {nume: 63, acuerdo_id: 9,type:"grave", name:"Se tienen por no formulados los alegatos y se cierra la instrucción"},
    {nume: 64, acuerdo_id: 9,type:"grave", name:"Se amplía término para emitir resolución"},
    
    
    //RECURSOS REVISIÓN
    {nume: 65, option_id:1, acuerdo_id: 10,type:"nulidad lesividad", name:"Se tiene por interpuesto en término el recurso de revisión y se da vista"},
    {nume: 66, option_id:1, acuerdo_id: 10,type:"nulidad lesividad", name:"Se tiene por interpuesto fuera del término el recurso de revisión y se da vista"},
    {nume: 67, option_id:1, acuerdo_id: 10,type:"nulidad lesividad", name:"Se tienen por contestados los agravios del recurso de revisión y se remite a Sala Superior"},
    {nume: 68, option_id:1, acuerdo_id: 10,type:"nulidad lesividad", name:"Se tienen por no contestados agravios del recurso de revisión y se remite a Sala Superior"},
    //RECURSOS RECLAMACIÓN
    {nume: 69, option_id:2, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se admite el recurso de reclamación y se da vista"},
    {nume: 70, option_id:2, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se previene en el recurso de reclamación"},
    {nume: 71, option_id:2, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se tiene por desahogada la prevención respecto del recurso de reclamación y se da vista"},
    {nume: 72, option_id:2, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se tienen por contestados los agravios del recurso de reclamación y se turna para dictar resolución"},
    {nume: 73, option_id:2, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se desecha recurso de reclamación"},
    //RECURSOS QUEJA
    {nume: 74, option_id:3, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se admite recurso de queja y se requiere a la autoridad para que rinda un informe con justificación"},
    //{nume: 73, option_id:3, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se tiene por interpuesto el recurso extemporáneo y se requiere a la autoridad para que rinda un informe con justificación"},
    {nume: 75, option_id:3, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se tiene por interpuesto el recurso extemporáneo y se desecha"},
    {nume: 76, option_id:3, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se tiene por rendido el informe con justificación y se turna para dictar resolución"},
    {nume: 77, option_id:3, acuerdo_id: 10,type:"nulidad lesividad grave", name:"Se tiene por no rendido el informe con justificación y se turna para dictar resolución"},
    
    
    //RECURSOS APELACIÓN
    {nume: 78, option_id:4, acuerdo_id: 10,type:"grave", name:"Se tiene por interpuesto en término el recurso de apelación y se da vista"},
    {nume: 79, option_id:4, acuerdo_id: 10,type:"grave", name:"Se tiene por interpuesto fuera del término el recurso de apelación y se da vista"},
    {nume: 80, option_id:4, acuerdo_id: 10,type:"grave", name:"Se tienen por contestados los agravios del recurso de apelación y se remite a Sala Superior"},
    {nume: 81, option_id:4, acuerdo_id: 10,type:"grave", name:"Se tienen por no contestados agravios del recurso de apelación y se remite a Sala Superior"},
    
    //RECURSOS RECLAMACIÓN (R.GRAVE)
    {nume: 82, option_id:5, acuerdo_id: 10,type:"grave", name:"Se tiene por interpuesto en término el recurso de reclamación y se da vista"},
    {nume: 83, option_id:5, acuerdo_id: 10,type:"grave", name:"Se tiene por interpuesto fuera del término el recurso de reclamación y se da vista"},
    {nume: 84, option_id:5, acuerdo_id: 10,type:"grave", name:"Se tienen por contestados los agravios del recurso de reclamación y se remite a Sala Superior"},
    {nume: 85, option_id:5, acuerdo_id: 10,type:"grave", name:"Se tienen por no contestados agravios del recurso de reclamación y se remite a Sala Superior"},
    
    
    //INCIDENTES
    
    //ACUMULACIÓN DE AUTOS
    {nume: 86, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"De oficio se ordena la acumulación de autos y se da vista a las partes"},
    {nume: 922, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"De oficio se ordena la acumulación de juicios entre salas y se da vista a las partes"},
    {nume: 87, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de acumulación y se da vista a las partes"},
    {nume: 923, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de acumulación entre salas y se da vista a las partes"},
    {nume: 88, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se previene en el incidente de acumulación de autos"},
    {nume: 89, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por desahogada la prevención respecto del incidente de acumulación de autos y se da vista"},
    {nume: 90, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la vista y se turna para dictar resolución"},
    {nume: 91, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por no desahogada la vista y se turna para dictar resolución"},
    {nume: 92, option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desecha incidente por notoriamente improcedente"},
    {nume: 921,option_id:6, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se ordena desacumular expedientes"},

    //NULIDAD DE NOTIFICACIONES
    {nume: 93, option_id:7, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de nulidad de notificaciones y se da vista a las partes"},
    {nume: 94, option_id:7, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se previene en el incidente de nulidad de notificaciones"},
    {nume: 95, option_id:7, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por desahogada la prevención respecto del incidente de nulidad de notificaciones y se da vista"},
    {nume: 96, option_id:7, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la vista y se turna para dictar resolución"},
    {nume: 97, option_id:7, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por no desahogada la vista y se turna para dictar resolución"},
    {nume: 98, option_id:7, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desecha incidente por notoriamente improcedente"},
    //INTERRUPCIÓN DEL PROCEDIMIENTO POR MUERTE O POR DISOLUCIÓN EN EL CASO DE LAS PERSONAS MORALES
    {nume: 99, option_id:8, acuerdo_id: 11,type:"nulidad lesividad grave", name:"De oficio se ordena la interrupción del procedimiento por muerte o por disolución de personas morales y se da vista"},
    {nume: 100, option_id:8, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de interrupción del procedimiento por muerte o por disolución de personas morales y se da vista"},
    {nume: 101, option_id:8, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se previene en el incidente de interrupción del procedimiento por muerte o por disolución de personas morales"},
    {nume: 102, option_id:8, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por desahogada la prevención respecto del incidente de interrupción del procedimiento por muerte o por disolución de personas morales y se da vista"},
    {nume: 103, option_id:8, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la vista y se turna para dictar resolución"},
    {nume: 104, option_id:8, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por no desahogada la vista y se turna para dictar resolución"},
    {nume: 105, option_id:8, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desecha incidente por notoriamente improcedente"},
    //INCOMPETENCIA POR RAZÓN DE TERRITORIO
    {nume: 106, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"De oficio la sala se declara incompetente por territorio y se remite a la sala competente"},
    {nume: 107, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"Se tiene por recibido expediente remitido por incompetencia en razón de territorio y se admite a trámite"},
    {nume: 108, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"Se tiene por recibido expediente por incompetencia en razón de territorio no se acepta competencia y se remite a Sala Superior"},
    {nume: 109, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"Se recibe expediente de Sala Superior para conocer del asunto y se admite a trámite"},
    {nume: 110, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"Se admite el incidente de incompetencia en razón de territorio y se remite a la sala competente"},
    {nume: 111, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"Se previene en el incidente de incompetencia en razón de territorio"},
    {nume: 112, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"Se tiene por desahogada la prevención respecto del incidente de incompetencia en razón de territorio y se remite a la sala competente"},
    {nume: 113, option_id:9, acuerdo_id: 11,type:"nulidad lesividad", name:"Se desecha incidente por notoriamente improcedente"},
    //grave
    {nume: 114, option_id:9, acuerdo_id: 11,type:"grave", name:"De oficio la sala se declara incompetente por territorio y se remite a la sala competente"},
    {nume: 115, option_id:9, acuerdo_id: 11,type:"grave", name:"Se tiene por recibido expediente remitido por incompetencia en razón de territorio y se admite a trámite"},
    {nume: 116, option_id:9, acuerdo_id: 11,type:"grave", name:"Se tiene por recibido expediente por incompetencia en razón de territorio no se acepta competencia y se remite a Sala Superior"},
    {nume: 117, option_id:9, acuerdo_id: 11,type:"grave", name:"Se recibe expediente de Sala Superior para conocer del asunto y se admite a trámite"},
    {nume: 118, option_id:9, acuerdo_id: 11,type:"grave", name:"Se admite el incidente de incompetencia en razón de territorio y se remite a la sala competente"},
    {nume: 119, option_id:9, acuerdo_id: 11,type:"grave", name:"Se previene en el incidente de incompetencia en razón de territorio"},
    {nume: 120, option_id:9, acuerdo_id: 11,type:"grave", name:"Se tiene por desahogada la prevención respecto del incidente de incompetencia en razón de territorio y se remite a la sala competente"},
    {nume: 121, option_id:9, acuerdo_id: 11,type:"grave", name:"Se desecha incidente por notoriamente improcedente"},
    
    //ACLARACIÓN DE SENTENCIA
    {nume: 122, option_id:10, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de aclaración de sentencia y se turna para dictar resolución"},
    {nume: 123, option_id:10, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desecha incidente por notoriamente improcedente"},
    //LIQUIDACIÓN
    {nume: 124,  option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"De oficio se inicia el trámite del incidente de liquidación y se da vista"},
    {nume: 125, option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de liquidación y se da vista a las partes"},
    {nume: 126, option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se previene respecto del incidente de liquidación"},
    {nume: 127, option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la prevención se admite el incidente y se da vista"},
    {nume: 128, option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"No desahoga la vista y se hace efectivo el apercibimiento"},
    {nume: 129, option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la vista y se turna para dictar resolución"},
    {nume: 130, option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por no desahogada la vista y se turna para dictar resolución"},
    {nume: 131, option_id:11, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desecha incidente por notoriamente improcedente"},
    //CUMPLIMIENTO SUSTITUTO
    //{nume: 132, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"De oficio se inicia el trámite del incidente de cumplimiento sustituto y se da vista"},
    //{nume: 133, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se previene respecto del incidente de cumplimiento sustituto"},
    //{nume: 134, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la prevención se admite el incidente y se da vista"},
    //{nume: 135, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"No desahoga la vista y se hace efectivo el apercibimiento"},
    //{nume: 136, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de cumplimiento sustituto y se da vista"},
    //{nume: 137, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la vista y se ordena dictar resolución"},
    //{nume: 138, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por no desahogada la vista y se ordena dictar resolución"},
    //{nume: 139, option_id:12, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desecha incidente por notoriamente improcedente"},
    //FALSEDAD DE DOCUMENTO
    {nume: 140, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de falsedad del documento y se da vista"},
    {nume: 141, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de falsedad del documento se da vista y se da trámite a la prueba pericial"},
    {nume: 142, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se previene respecto del incidente de falsedad de documento"},
    {nume: 143, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la prevención se admite el incidente y se da vista a las partes"},
    {nume: 144, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"No desahoga la vista y se hace efectivo el apercibimiento"},
    {nume: 145, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desahoga la vista y se ordena dictar resolución"},
    {nume: 146, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se tiene por no desahogada la vista y se ordena dictar resolución"},
    {nume: 147, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se desecha incidente por notoriamente improcedente"},
    {nume: 243, option_id:13, acuerdo_id: 11,type:"nulidad lesividad grave", name:"Se admite el incidente de falsedad del documento y se requiere la exhibición del documento (s) orginal (es) objetado (s)"},

    
    ////// incidentes fin
    
    //EJECUCIÓN DE SENTENCIA
    
    {nume: 148,  acuerdo_id: 12,type:"nulidad lesividad", name:"Ha causado ejecutoria la sentencia y se ordena el archivo"},
    {nume: 149,  acuerdo_id: 12,type:"nulidad lesividad", name:"Ha causado ejecutoria la sentencia se ordena cumplimiento y se apercibe con multa"},
    {nume: 150,  acuerdo_id: 12,type:"nulidad lesividad", name:"Se ordena dar fe del cumplimiento de un acto material y señala fecha"},
    {nume: 151,  acuerdo_id: 12,type:"nulidad lesividad", name:"Se difiere la diligencia para dar fe del cumplimiento de un acto material"},
    {nume: 152,  acuerdo_id: 12,type:"nulidad lesividad", name:"Acta en que se da fe del cumplimiento de un acto material"},
    {nume: 153,  acuerdo_id: 12,type:"nulidad lesividad", name:"Se tiene por cumplida la sentencia y se envía al archivo"},
    
    
    {nume: 154,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia y se devuelve a la autoridad substanciadora"},
    {nume: 155, acuerdo_id: 12, type:"gravedad", name:"Ha causado ejecutoria la sentencia, se comunica que inicia el procedimiento de ejecución"},
    {nume: 156, acuerdo_id: 12, type:"gravedad", name:"Se tiene por no cumplida la sentencia, se requiere cumplimiento a la autoridad vinculada a la ejecución de la sanción y se apercibe con multa"},
    {nume: 157, acuerdo_id: 12, type:"gravedad", name:"Se tiene por no cumplida la sentencia, se impone multa, se requiere cumplimiento a la autoridad vinculada a la ejecución de la sanción y se apercibe con duplicar la multa"},
    {nume: 158, acuerdo_id: 12, type:"gravedad", name:"Se tiene por cumplida parcialmente la sentencia, se impone multa, se requiere cumplimiento a la autoridad vinculada a la ejecución de la sanción y se apercibe con duplicar la multa"},
    {nume: 159, acuerdo_id: 12, type:"gravedad", name:"Se tiene por cumplida parcialmente la sentencia, se requiere cumplimiento a la autoridad vinculada a la ejecución de la sanción y se apercibe con multa"},
    /*{nume: 153,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere al superior jerárquico su cumplimiento y se previene para que informe suspensión y o destitución"},
    {nume: 154,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere al superior jerárquico su cumplimiento se ordena su publicación y se previene para que informen inhabilitación"},
    {nume: 155,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere a sefina su cumplimiento y se previene para que informe sanción económica"},
    {nume: 156,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere al superior jerárquico y a sefina su cumplimiento se previene para que informen suspensión destitución y o sanción económica"},
    {nume: 157,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere al superior jerárquico y a sefina su cumplimiento se ordena su publicación y se previene para que informen inhabilitación y sanción económica"},
    {nume: 158,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se ordena la publicación de la inhabilitación y se previene para que informe particulares p fisica"},
    {nume: 159,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere al sat para su cumplimiento y se previene para que informe particulares p fisica indemnización y o sanción económica"},
    {nume: 160,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere al sat para su cumplimiento y se ordena la publicación de la inhabilitación se previene para que informen particulares p fisica inhabilitación indemnización y o sanción económica"},
    {nume: 161,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia se requiere a la secretaría de economía y al sat su cumplimiento y se previene para que informen particulares moral suspensión de actividades"},
    {nume: 162,  acuerdo_id: 12,type:"gravedad", name:"Ha causado ejecutoria la sentencia y se procede a la disolución de la persona moral particulares moral"},*/
    {nume: 160, acuerdo_id: 12,type:"nulidad lesividad", name:"Se tiene por no cumplida la sentencia se impone multa se requiere cumplimiento y se apercibe con duplicar la multa"},
    {nume: 161, acuerdo_id: 12,type:"nulidad lesividad", name:"Se tiene por cumplida parcialmente la sentencia se impone multa se requiere cumplimiento y se apercibe con duplicar la multa"},
    {nume: 162, acuerdo_id: 12,type:"nulidad lesividad", name:"Se tiene por cumplida parcialmente la sentencia se requiere cumplimiento y se apercibe con multa"},
    {nume: 163, acuerdo_id: 12,type:"nulidad lesividad grave", name:"Se tiene por no cumplida la sentencia y se envía a Sala Superior para continuar con el procedimiento"},
    {nume: 164, acuerdo_id: 12,type:"gravedad", name:"Se tiene por cumplida la sentencia y se envía a la autoridad substanciadora"},
    
    
    //MEDIOS ALTERNOS DE SOLUCIÓN DE CONTROVERSIAS
    
    {nume: 165,  acuerdo_id: 13,type:"nulidad lesividad grave", name:"Se acuerda la solicitud de la aplicación de medios alternos de solución de controversias"},
    {nume: 166,  acuerdo_id: 13,type:"nulidad lesividad", name:"Se aplica medio alterno de solución de controversias"},
    {nume: 167,  acuerdo_id: 13,type:"grave", name:"Convenio con demanda (medios alternos de solución de controversias)"},
    
    //CONVENIOS Y COMPARECENCIAS
    
    {nume: 168,  acuerdo_id: 14,type:"nulidad lesividad grave", name:"Convenio de cumplimiento de sentencia"},
    {nume: 169,  acuerdo_id: 14,type:"nulidad lesividad", name:"Convenio de cumplimiento sustituto de sentencia"},
    {nume: 170,  acuerdo_id: 14,type:"nulidad lesividad grave", name:"Comparecencia de pago de cumplimiento de sentencia"},
    {nume: 171,  acuerdo_id: 14,type:"nulidad lesividad grave", name:"Comparencia de pago parcial de cumplimiento de sentencia"},
    {nume: 172,  acuerdo_id: 14,type:"grave", name:"Se tiene por recibida la solicitud del beneficio de la reducción de la sanción y se ordena el trámite correspondiente"},
    
    
    //NUEVA AUTORIDAD Y/O ADMINISTRACIÓN
    
    {nume: 173,  acuerdo_id: 15,type:"nulidad lesividad", name:"Se informa a autoridad demandada respecto del procedimiento de ejecución de sentencia"},
    {nume: 174,  acuerdo_id: 15,type:"nulidad lesividad", name:"Se informa a autoridad demandada respecto del procedimiento de ejecución de sentencia se requiere cumplimiento de sentencia y se apercibe"},
    {nume: 175,  acuerdo_id: 15,type:"nulidad lesividad", name:"Se requiere a la autoridad cumplimiento de sentencia y se apercibe"},
    {nume: 176,  acuerdo_id: 15,type:"grave", name:"Se informa a la autoridad responsable de la ejecución de la sanción respecto del procedimiento de ejecución de sentencia"},
    {nume: 177,  acuerdo_id: 15,type:"grave", name:"Se informa a la autoridad responsable de la ejecución de la sanción respecto del procedimiento de ejecución de sentencia se requiere cumplimiento de sentencia y se apercibe"},
    {nume: 178,  acuerdo_id: 15,type:"grave", name:"Se recibe expediente de Sala Superior y se informa a la autoridad responsable de la ejecución de la sanción respecto del procedimiento de ejecución de sentencia"},
    
    
    //SE RECIBE EXPEDIENTE DE Sala Superior
    
    {nume: 179,  acuerdo_id: 16,type:"nulidad lesividad grave", name:"Se tiene por recibido el expediente de Sala Superior y se provee lo conducente"},
    {nume: 180,  acuerdo_id: 16,type:"nulidad lesividad", name:"Se recibe expediente de Sala Superior y se ordena cumplimiento de sentencia"},
    {nume: 181,  acuerdo_id: 16,type:"nulidad lesividad", name:"Se recibe expediente de Sala Superior y se repone el procedimiento"},
    {nume: 182,  acuerdo_id: 16,type:"nulidad lesividad", name:"Se recibe expediente de Sala Superior y se ordena dictar una nueva resolución"},
    {nume: 183,  acuerdo_id: 16,type:"nulidad lesividad", name:"Se recibe expediente de Sala Superior y se envía al archivo"},
    {nume: 184,  acuerdo_id: 16,type:"grave", name:"Ha causado ejecutoria la sentencia y se ordena su cumplimiento"},
    {nume: 185,  acuerdo_id: 16,type:"grave", name:"Se recibe sentencia de Sala Superior que ordena dictar una nueva sentencia"},
    {nume: 186,  acuerdo_id: 16,type:"grave", name:"Se recibe sentencia de Sala Superior que ordena reponer el procedimiento"},
    
    
    //AMPARO INDIRECTO
    
    //SE RINDE INFORME
    {nume: 187, option_id:14, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibido el oficio con la demanda de amparo indirecto y se rinde el informe previo"},
    {nume: 188, option_id:14, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibido el oficio con la demanda de amparo indirecto y se rinde el informe justificado"},
    
    //TRÁMITE DE AMPARO
    {nume: 189, option_id:15, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibido el oficio que difiere la audiencia constitucional"},
    {nume: 190, option_id:15, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibido el oficio que concede la suspensión del acto reclamado"},
    {nume: 191, option_id:15, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibido el oficio que niega la suspensión del acto reclamado"},
    
    
    //SENTIDO DE EJECUTORIA
    {nume: 192, option_id:16, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibida la ejecutoria de amparo indirecto que ampara y protege al quejoso"},
    {nume: 193, option_id:16, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibida la ejecutoria de amparo indirecto que no ampara y protege al quejoso"},
    {nume: 194, option_id:16, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibida la ejecutoria de amparo indirecto que sobresee el juicio"},
    {nume: 195, option_id:16, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibida la ejecutoria de amparo indirecto que desecha la demanda de amparo"},
    
    //CUMPLIMIENTO DE EJECUTORIA
    {nume: 196, option_id:17, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por recibido el requerimiento de cumplimiento de ejecutoria de amparo indirecto y se provee lo conducente"},
    {nume: 197, option_id:17, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por cumplida la ejecutoria de amparo indirecto"},
    {nume: 198, option_id:17, acuerdo_id: 17,type:"nulidad lesividad grave", name:"Se tiene por no cumplida la ejecutoria y se provee lo conducente"},
    
    
    // FIN AMPARO INDIRECTO
    
    //ACUERDOS FRECUENTES
    
    //DOMICILIO PROCESAL Y AUTORIZADOS
    {nume: 199, option_id:18, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se revoca correo electrónico y se señala nuevo domicilio electrónico y/o autorizados"},
    {nume: 200, option_id:18, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se revoca domicilio procesal y se autoriza el nuevo domicilio procesal y/o autorizados"},
    {nume: 201, option_id:18, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se previene"},
    {nume: 202, option_id:18, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se desahoga prevención y se autoriza el nuevo domicilio electrónico y/o autorizados"},
    {nume: 203, option_id:18, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se desahoga prevención y se autoriza el nuevo domicilio procesal y/o autorizados"},
    {nume: 204, option_id:20, acuerdo_id: 18,type:"grave", name:"Se tiene por recibida la solicitud de excusa y se hace del conocimiento al pleno"},
    //ACUERDOS FRECUENTES OTROS
    {nume: 205, option_id: 20, acuerdo_id: 18, type:"nulidad lesividad", name:"Se tiene por recibida la solicitud de excusa y se ordena remitir al pleno de la Sala Superior para su calificación"},
    {nume: 206, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se remite al pleno de la Sala Superior la manifestación del magistrado de estar impedido para conocer de un asunto"},
    {nume: 207, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se tiene por realizada la objeción de documentos"},
    {nume: 208, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Agréguese a los autos"},
    {nume: 209, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"No ha lugar a acordar de conformidad lo solicitado"},
    {nume: 210, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Téngase por hechas sus manifestaciones"},
    {nume: 211, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se acordará lo conducente en el momento procesal oportuno"},
    {nume: 212, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Estése a lo acordado"},
    {nume: 213, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se previene"},
    {nume: 214, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se tiene por desahogada la prevención"},
    {nume: 215, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se autoriza la expedición de copias"},
    {nume: 216, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Comparecencia"},
    {nume: 217, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se envía expediente a la Sala Superior para su resguardo virtual y físico en el archivo general"},
    {nume: 218, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se recibe despacho para diligenciarlo"},
    {nume: 219, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se recibe exhorto para diligenciarlo"},
    {nume: 220, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se envía exhorto para diligenciarlo"},
    {nume: 221, option_id:20, acuerdo_id: 18,type:"grave", name:"Se solicita a sefina el embargo precautorio de los bienes del servidor público o particular presunto responsable de la falta administrativa grave"},
    {nume: 222, option_id:20, acuerdo_id: 18, type: "nulidad grave", name:"Se ordena notificacion por edictos"},
    {nume: 2220, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se ordena la integración correcta del expediente digital"},

    //REPRESENTANTE COMÚN
    {nume: 223, option_id:19, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Se revoca representante común y se designa nuevo"},
    //OTROS
    //{nume: 224, option_id:20, acuerdo_id: 18,type:"nulidad lesividad", name:"Se tiene por recibida la solicitud de excusa y se ordena remitir al pleno de la Sala Superior para su calificación"},
    {nume: 225, option_id:20, acuerdo_id: 18,type:"nulidad lesividad grave", name:"Otro"},
    // FIN ACUERDOS FRECUENTES
    
    //RECEPCIÓN DE IPRA
    {nume: 226, acuerdo_id: 19,type:"grave", name:"Se tiene por recibido el ipra con el expediente y se acepta competencia"},
    {nume: 227, acuerdo_id: 19,type:"grave", name:"Se devuelve el ipra con el expediente porque la conducta no es grave"},
    {nume: 228, acuerdo_id: 19,type:"grave", name:"Se devuelve el ipra con el expediente a la investigadora para que reclasifique"},
    {nume: 229, acuerdo_id: 19, type:"grave", name:"Se previene en el ipra por obscuro e irregular"},
    {nume: 230, acuerdo_id: 19, type:"grave", name:"No desahoga la prevención y se desecha el ipra"},
    {nume: 231, acuerdo_id: 19, type:"grave", name:"Se desecha el ipra"},

    //FIN DE IPRA

    
//solicitud de acceso al expediente

    {nume: 2320,  acuerdo_id: 20,type:"nulidad lesividad grave", name:"Se aprueba solicitud de notificación electrónica en juicio tradicional"},
    {nume: 2330,  acuerdo_id: 20,type:"nulidad lesividad grave", name:"Se niega solicitud de notificación electrónica en juicio tradicional"},

    
    ];

export enum AditionalOptionsId{
    RECURSO=10,
    INCIDENTE=11,
    AMPARO_DIRECTO=0,
    AMPARO_INDIRECTO=17,
    ACUERDOS_FRECUENTES=18,
}

export const SubsentidoOptions=[
    
{nume: 1,  sentido_id: 1,type:"nulidad lesividad", name:"Se admite demanda inicial"},
{nume: 2,  sentido_id: 1,type:"nulidad lesividad", name:"Se admite demanda inicial se concede suspensión"},
{nume: 3,  sentido_id: 1,type:"nulidad lesividad", name:"Se admite demanda inicial se concede suspensión previa garantía"},
{nume: 4,  sentido_id: 1,type:"nulidad lesividad", name:"Se admite demanda inicial se niega suspensión"},
//Pruebas Trámite de informe de autoridad
//tramite de informde de autoridad
{nume: 5, sentido_id: 43, type:"nulidad lesividad grave", name:"Se requiere informe de autoridad"},
{nume: 6, sentido_id: 43, type:"nulidad lesividad grave", name:"Se tiene por rendido el informe de autoridad"},
{nume: 7, sentido_id: 43, type:"nulidad lesividad grave", name:"Se tiene por no rendido el informe de autoridad y se impone multa"},
//tramite de testimonial
{nume: 8, sentido_id: 44,type:"nulidad lesividad grave", name:"Se sustituye testigo"},
{nume: 9, sentido_id: 44,type:"nulidad lesividad grave", name:"Se niega la solicitud de sustitución de testigo"},
{nume: 10, sentido_id: 44,type:"nulidad lesividad grave", name:"Se designa intérprete para que asista al testigo que no habla español"},
{nume: 11, sentido_id: 44,type:"nulidad lesividad grave", name:"No comparece el testigo se impone multa y se ordena la presentación por medio de la fuerza pública"},
{nume: 12, sentido_id: 44,type:"nulidad lesividad grave", name:"Se tiene por desahogada la testimonial de la autoridad"},
{nume: 13, sentido_id: 44,type:"nulidad lesividad grave", name:"Se designa perito tercero en discordia"},

//tramite de perical
{nume: 14, sentido_id: 45,type:"nulidad lesividad grave", name:"Se tiene por designado al perito y se ordena su preparación"},
{nume: 15, sentido_id: 45,type:"nulidad lesividad grave", name:"Se ordena de oficio el trámite de la prueba pericial"},
{nume: 16, sentido_id: 45,type:"nulidad lesividad grave", name:"Comparecencia de perito para protesta y aceptación de cargo"},
{nume: 17, sentido_id: 45,type:"nulidad lesividad grave", name:"Se tiene por rendido el dictamen"},
{nume: 18, sentido_id: 45,type:"nulidad lesividad grave", name:"Comparecencia de ratificación de dictamen"},
{nume: 19, sentido_id: 45,type:"nulidad lesividad grave", name:"Se tiene al perito por no ratificado el dictamen y se declara desierta la prueba"},
{nume: 20, sentido_id: 45,type:"nulidad lesividad grave", name:"Se tiene al perito por no ratificado el dictamen y se provee lo conducente"},
{nume: 21, sentido_id: 45,type:"nulidad lesividad grave", name:"Se sustituye perito"},
{nume: 22, sentido_id: 45,type:"nulidad lesividad grave", name:"Se acepta la excusa del perito y se designa nuevo"},
{nume: 23, sentido_id: 45,type:"nulidad lesividad grave", name:"No se acepta la excusa del perito"},
{nume: 24, sentido_id: 45,type:"nulidad lesividad grave", name:"Se impone multa a perito por incumplimiento"},
{nume: 25, sentido_id: 45,type:"nulidad lesividad grave", name:"Se concede prórroga al perito para rendir dictamen"},
{nume: 26, sentido_id: 45,type:"nulidad lesividad grave", name:"Se niega prórroga al perito para rendir dictamen"},
{nume: 27, sentido_id: 45,type:"nulidad lesividad grave", name:"Se precluye el derecho para rendir dictamen"},
{nume: 28, sentido_id: 45,type:"nulidad lesividad grave", name:"Se desahoga requerimiento adiciona cuestionario y se designa perito"},
{nume: 29, sentido_id: 45,type:"nulidad lesividad grave", name:"Se designa perito tercero en discordia"},
//tramite de la inspeccion
{nume: 30, sentido_id: 46,type:"nulidad lesividad grave", name:"Se señala fecha para que tenga verificativo la inspección ocular"},
{nume: 31, sentido_id: 46,type:"nulidad lesividad grave", name:"Se difiere la diligencia de inspección ocular"},
{nume: 32, sentido_id: 46,type:"nulidad lesividad grave", name:"Se agrega el Acta de Inspección Ocular"},

//Se admite el incidente de incompetencia en razón de territorio y se remite a la sala competente grave
{nume: 33,  sentido_id: 118,type:"nulidad lesividad", name:"Se tiene por designado al perito y se acuerda su preparación"},
{nume: 34,  sentido_id: 118,type:"nulidad lesividad", name:"Comparecencia de perito para protesta y aceptación de cargo"},
{nume: 35,  sentido_id: 118,type:"nulidad lesividad", name:"Comparecencia de toma de muestras de firma y huellas dactilares"},
{nume: 36,  sentido_id: 118,type:"nulidad lesividad", name:"No se tiene por rendido el dictamen y se hace efectivo el apercibimiento"},
{nume: 37,  sentido_id: 118,type:"nulidad lesividad", name:"Se tiene por rendido el dictamen"},
{nume: 38,  sentido_id: 118,type:"nulidad lesividad", name:"Comparecencia de ratificación de dictamen"},
{nume: 39,  sentido_id: 118,type:"nulidad lesividad", name:"Se tiene al perito por ratificado el dictamen"},
{nume: 40,  sentido_id: 118,type:"nulidad lesividad", name:"Se tiene al perito por no ratificado el dictamen y se declara desierta la prueba"},
{nume: 41,  sentido_id: 118,type:"nulidad lesividad", name:"Se sustituye perito"},
{nume: 42,  sentido_id: 118,type:"nulidad lesividad", name:"Se acepta la excusa del perito y se designa nuevo"},
{nume: 43,  sentido_id: 118,type:"nulidad lesividad", name:"No se acepta la excusa del perito"},
{nume: 44,  sentido_id: 118,type:"nulidad lesividad", name:"Se impone multa a perito por incumplimiento"},
{nume: 45,  sentido_id: 118,type:"nulidad lesividad", name:"Se precluye el derecho para rendir el dictamen"},
{nume: 46,  sentido_id: 118,type:"nulidad lesividad", name:"Se desahoga requerimiento adiciona cuestionario y se designa perito"},
{nume: 47,  sentido_id: 118,type:"nulidad lesividad", name:"Se designa perito tercero en discordia"},

//Se admite el incidente de falsedad del documento se da vista y se da trámite a la prueba pericial 
{nume: 33,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se tiene por designado al perito y se acuerda su preparación"},
{nume: 34,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Comparecencia de perito para protesta y aceptación de cargo"},
{nume: 35,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Comparecencia de toma de muestras de firma y huellas dactilares"},
{nume: 36,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"No se tiene por rendido el dictamen y se hace efectivo el apercibimiento"},
{nume: 37,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se tiene por rendido el dictamen"},
{nume: 38,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Comparecencia de ratificación de dictamen"},
{nume: 39,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se tiene al perito por ratificado el dictamen"},
{nume: 40,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se tiene al perito por no ratificado el dictamen y se declara desierta la prueba"},
{nume: 41,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se sustituye perito"},
{nume: 42,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se acepta la excusa del perito y se designa nuevo"},
{nume: 43,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"No se acepta la excusa del perito"},
{nume: 44,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se impone multa a perito por incumplimiento"},
{nume: 45,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se precluye el derecho para rendir el dictamen"},
{nume: 46,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se desahoga requerimiento adiciona cuestionario y se designa perito"},
{nume: 47,  sentido_id: 141,type:"nulidad lesividad gravedad", name:"Se designa perito tercero en discordia"},
];


export const TipoSentidoSecretarioGeneral = [
    {nume: 1, type: 'nulidad lesividad grave nograbe', name:'Excitativa de justicia'},
    {nume: 2, type: 'nulidad lesividad grave', name:'Fianza o garantía'},
    {nume: 3, type: 'nulidad lesividad grave nograbe', name:'Despachos'},
    {nume: 19, type: 'nulidad grave nograbe', name:'Amparo directo'},
    {nume: 17, type: 'nulidad grave nograbe', name:'Amparo indirecto'},
    

]

export const AcuerdoSentidoOptionsSecretarioGeneral=[

    {nume:232,option_id:0, acuerdo_id: 1,type:"nulidad lesividad grave nograbe", name:"Se tiene por recibida la excitativa y se solicita informe"},  
    {nume:233, acuerdo_id: 1,type:"nulidad lesividad grave nograbe", name:"Se tiene por rendido el informe de la excitativa"},  
    {nume:234, acuerdo_id: 1,type:"nulidad lesividad grave nograbe", name:"Se da cuenta al pleno de la excitativa para que la califique"},  
    {nume:235, acuerdo_id: 1,type:"nulidad lesividad grave",         name:"Se declara fundada la excitativa se requiere a Sala Regional dicte la resolución correspondiente"},
    {nume:236, acuerdo_id: 2,type:"nulidad lesividad grave",         name:"Se tiene por recibida la fianza o garantía"},
    {nume:237, acuerdo_id: 2,type:"nulidad lesividad grave",         name:"Se ordena la devolución de fianza o garantía"},
    {nume:238, acuerdo_id: 2,type:"nulidad lesividad grave",         name:"Se señala fecha y hora para devolución de fianza o garantía"},
    {nume:239, acuerdo_id: 2,type:"nulidad lesividad grave",         name:"Comparecencia para devolución de fianza o garantía"},
    {nume:240, acuerdo_id: 3,type:"nulidad lesividad grave nograbe", name:"Se ordena diligenciar despacho"},
    {nume:241, acuerdo_id: 3,type:"nulidad lesividad grave nograbe", name:"Se recibe despacho diligenciado"},
    {nume:242, acuerdo_id: 3,type:"nulidad lesividad grave nograbe", name:"Se recibe despacho no diligenciado y se provee lo conducente"},
    {nume: 251, option_id:23, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la demanda de amparo directo, se ordena emplazar a los terceros interesados"},
    {nume: 252, option_id:23, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se previene al quejoso para que exhiba copias suficientes para correr traslado a los terceros interesados"},
    {nume: 253, option_id:23, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por desahogada la prevención y se ordena correr traslado a los terceros interesados"},
    //Remite demanda
    {nume: 254, option_id:24, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por no desahogada la prevención y se remite la demanda al Tribunal Colegiado de Circuito"},
    {nume: 255, option_id:24, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se remite expediente, la demanda de amparo directo, las constancias de notificación y el informe con justificación al Tribunal Colegiado de Circuito"},
    //Se recibe de colegiado
    {nume: 256, option_id:25, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se reciben los autos y acuerdo que tiene por no presentada la demanda de amparo directo, se ordena remitir los autos a la sala de origen, se archivan el toca y el expediente auxiliar de amparo"},
    {nume: 257, option_id:25, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibido el acuerdo que admite la demanda de amparo, se ordena agregar Tercero Interesado auxiliar de amparo"},
    //Sentido de ejecutoria
    {nume: 258, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que ampara y protege al quejoso y se turna a ponencia para elaborar proyecto"},
    {nume: 259, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que no ampara y protege al quejoso, se ordena remitir los autos a la Sala Regional, se archivan el toca y el expediente auxiliar de amparo"},
    {nume: 260, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que sobresee el juicio, se ordena remitir los autos a la Sala Regional, se archivan el toca y el expediente auxiliar de amparo"},
    {nume: 261, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria que desecha la demanda de amparo, se ordena remitir los autos a la Sala Regional, se archivan el toca y el expediente auxiliar de amparo"},
    {nume: 262, option_id:26, acuerdo_id:19, type:"nulidad grave nograbe", name:"Se tiene por cumplida la ejecutoria, se ordena remitir los autos a la sala de origen, se archivan el toca y el expediente auxiliar de amparo"},
    //Amparo indirecto
    //Se rinde informe
    {nume: 263, option_id:16, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio con la demanda de amparo indirecto y se rinde el informe previo"},
    {nume: 264, option_id:16, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio con la demanda de amparo indirecto y se rinde el informe justificado"},
    //Trámite de amparo"},
    {nume: 265, option_id:17, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio que difiere la audiencia constitucional"},
    {nume: 266, option_id:17, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio que concede la suspensión del acto reclamado"},
    {nume: 267, option_id:17, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el oficio que niega la suspensión del acto reclamado"},
    //Sentido de ejecutoria"},
    {nume: 268, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que ampara y protege al quejoso"},
    {nume: 269, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que no ampara y protege al quejoso"},
    {nume: 270, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que sobresee el juicio"},
    {nume: 271, option_id:18, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibida la ejecutoria de amparo indirecto que desecha la demanda de amparo"},
    //Cumplimiento de ejecutoria"},
    {nume: 272, option_id:19, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por recibido el requerimiento de cumplimiento de ejecutoria de amparo indirecto y se provee lo conducente"},
    {nume: 273, option_id:19, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por cumplida la ejecutoria de amparo indirecto se archiva el expediente auxiliar"},
    {nume: 274, option_id:19, acuerdo_id:17, type:"nulidad grave nograbe", name:"Se tiene por no cumplida la ejecutoria y se provee lo conducente"},
    
]


export const actoResolutionOption = [
    {nume: 1, name: 'Agua'},
    {nume: 2, name: 'Anuncios'},
    {nume: 3, name: 'Licencias de funcionamiento'},
    {nume: 4, name: 'Infracciones de tránsito'},
    {nume: 5, name: 'Predial'},
    {nume: 6, name: 'Resolución administrativa'},
    {nume: 7, name: 'Visitas de verificación'},
    {nume: 8, name: 'Concesiones de transporte'},
    {nume: 9, name: 'Multas o créditos fiscales'},
    {nume: 10, name: 'Responsabilidad de servidores públicos'},
    {nume: 11, name: 'Seguridad pública'},
    {nume: 12, name: 'Pensiones elementos policiales'},
    {nume: 13, name: 'Otros de seguridad social'},
    {nume: 14, name: 'Negativa ficta'},
    {nume: 15, name: 'Positiva/afirmativa ficta'},
    {nume: 16, name: 'Silencio administrativo'},
    {nume: 17, name: 'Otro acto administrativo'},


]

//promos para asuntos resueltos sin previo juicio
export const TypePromo = [
    {nume:1 , name:'Se acuerda la solicitud de la aplicación de medios alternos de solución de controversias'},
    {nume:2, name: 'Convenio sin demanda (medios alternos de solución de controversias)'}
]

export const PeritoMaterias=[
    {nume:1,value:'1',name:'Dactiloscopía'},
    {nume:2,value:'2',name:'Documentoscopía'},
    {nume:3,value:'3',name:'Contabilidad'},
    {nume:4,value:'4',name:'Auditoría'},
    {nume:5,value:'5',name:'Grafología'},
    {nume:6,value:'6',name:'Grafoscopía'},
    {nume:8,value:'8',name:'Tránsito Terrestre'},
    {nume:7,value:'7',name:'Otro'},
  
]



























/***
 * 
 * contestacion de demanda
 * paso por sireproc 
 * nunca le aparecio al usuario
 * 
 * 
 * Promociones en el limbo no aparecen
 * 
 */