import { Orientation } from 'ngx-guided-tour';

export const tourCitizen = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las acciones que puedes realizar en el Sistema',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Juicio de Nulidad/Lesividad',
        content: 'Aquí puedes presentar cualquier promoción ya sea inicial o subsecuente.',
        selector: '.create-demand',
        orientation: Orientation.Left
    },
    {
        title: 'Juicio de Responsabilidad Administrativa',
        content: 'Aquí puedes presentar cualquier promoción ya sea inicial o subsecuente en los Juicios de Responsabilidad Administrativa.',
        selector: '.create-promotion',
        orientation: Orientation.Right
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
	},
    {
        title: 'Buzón de notificaciones',
        content: 'Bandeja de entrada donde puedes ver las notificaciones electrónicas.',
        selector: '.admin-notication',
        orientation: Orientation.Left
    },
    {
        title: 'Solicitud de notificación electrónica en juicio tradicional',
        content: 'En este apartado podrás solicitar que las notificaciones de los juicios iniciados de forma tradicional, se notifiquen de forma electrónica.',
        selector: '.create-expediente',
        orientation: Orientation.Top,
        scrollAdjustment: 560
    },
    {
        title: 'Historial de solicitud de notificaciones electrónicas en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.list-expediente',
        orientation: Orientation.Top
    },
	// {
    //     title: 'Asesoría ciudadana',
    //     content: 'Consulta la lista de solicitudes de asesoría ciudadana.',
    //     selector: '.adviser-list',
    //     orientation: Orientation.Top
    // },
    {
        title: 'Expediente Electrónico',
        content: 'En este apartado podrás visualizar el expediente electrónico en tres versiones: tabla, PDF y línea del tiempo.<br>Además, podrás ver la Ficha Técnica del Expediente que contiene los datos más relevantes del juicio.',
        selector: '.electronic-expedient-tray',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];
export const tourAuthority = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las acciones que puedes realizar en el Sistema.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Juicio de Nulidad',
        content: 'Aquí puedes presentar cualquier promoción subsecuente relacionada con los juicios de nulidad.',
        selector: '.create-demand',
        orientation: Orientation.Right
    },
    {
        title: 'Juicio de lesividad',
        content: 'Aquí puedes presentar cualquier promoción inicial o subsecuente relacionada con los juicios de lesividad.',
        selector: '.create-promotion-lesividad',
        orientation: Orientation.Top
    },
    {
        title: 'Juicio de Responsabilidad Administrativa',
        content: 'Aquí puedes presentar cualquier promoción, ya sea inicial o subsecuente en los Juicios de Responsabilidad Administrativa.',
        selector: '.create-promotion-responsabilidad',
        orientation: Orientation.Right
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
	},
    {
        title: 'Buzón de notificaciones',
        content: 'Bandeja de entrada donde puedes ver las notificaciones electrónicas.',
        selector: '.admin-notication',
        orientation: Orientation.Left
    },
    {
        title: 'Solicitud de notificación electrónica en juicio tradicional',
        content: 'En este apartado podrás solicitar que las notificaciones de los juicios iniciados de forma tradicional, se notifiquen de forma electrónica.',
        selector: '.create-expediente',
        orientation: Orientation.Top,
        scrollAdjustment: 560
    },
    {
        title: 'Historial de solicitud de notificaciones electrónicas en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.list-expediente',
        orientation: Orientation.Top
    },
    {
        title: 'Expediente Electrónico',
        content: 'En este apartado podrás visualizar el expediente electrónico en tres versiones: tabla, PDF y línea del tiempo.<br>Además, podrás ver la Ficha Técnica del Expediente que contiene los datos más relevantes del juicio.',
        selector: '.electronic-expedient-tray',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];
export const tourPerito = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las acciones que puedes realizar en el Sistema.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Registro de promociones',
        content: 'Aquí puedes presentar cualquier promoción respecto de los juicios en los que estés designado como Perito.',
        selector: '.promociones',
        orientation: Orientation.Right
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
	},
    {
        title: 'Buzón de notificaciones',
        content: 'Bandeja de entrada donde puedes ver las notificaciones electrónicas.',
        selector: '.admin-notication',
        orientation: Orientation.Left
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];
export const tourAsesor = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las acciones que puedes realizar en el Sistema.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Buzón de asesoría ciudadana',
        content: 'Consulta la lista de solicitudes de asesoría asignadas.',
        selector: '.adviser-list',
        orientation: Orientation.Top
    },
    {
        title: 'Juicio de Nulidad / Lesividad',
        content: 'Aquí puedes registrar distintas demandas de tipo inicial.',
        selector: '.create-demand',
        orientation: Orientation.Left
    },
    {
        title: 'Juicio de Responsabilidad Administrativa',
        content: 'Podrás continuar con el proceso jurisdiccional ingresando una promoción relativa a un asunto que ya se está atendiendo en el Tribunal de Justicia Administraiva del Estado de Guerrero.',
        selector: '.create-promotion',
        orientation: Orientation.Right
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
    },
    {
        title: 'Buzón de notificaciones',
        content: 'Esta es la bandeja de entrada donde puedes ver las notificaciones electrónicas recibidas.',
        selector: '.admin-notication',
        orientation: Orientation.Top
    },
    {
        title: 'Solicitud de notificación electrónica en juicio tradicional',
        content: 'Aquí puedes solicitar la recepción de notificaciones electrónicas.',
        selector: '.create-expediente',
        orientation: [Orientation.Right, Orientation.Top],
        scrollAdjustment: 560
    },
    {
        title: 'Historial de solicitudes de notificación electrónica en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.list-expediente',
        orientation: Orientation.Top
    },
    {
        title: 'Expediente Electrónico',
        content: 'En este apartado podrás visualizar el expediente electrónico en tres versiones: tabla, PDF y línea del tiempo.<br>Además, podrás ver la Ficha Técnica del Expediente que contiene los datos más relevantes del juicio.',
        selector: '.electronic-expedient-tray',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];

export const tourSecretary = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Solicitud de notificación electrónica en juicio tradicional',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.manager-expediente',
        orientation: Orientation.Top
    },
    {
        title: 'Administrar promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.manager-promotion',
        orientation: Orientation.Top
    },
    {
        title: 'Administrar acuerdos',
        content: 'Consulta la lista de acuerdos que has registrado, así como la información de cada uno de ellos.',
        selector: '.manager-agreement',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]

export const tourActuary = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Gestor de notificaciones',
        content: 'Consulta la lista de acuerdos que has recibido, así como la información de cada uno de ellos.',
        selector: '.manager-agreement',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de notificación electrónica',
        content: 'Crear una nueva notificación electrónica',
        selector: '.electronica',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de notificación física',
        content: 'Registrar una notificación física',
        selector: '.fisica',
        orientation: Orientation.Top
    },
    {
        title: 'Historial de notificaciones',
        content: 'Esta es la bandeja de entrada donde puedes ver las notificaciones electrónicas emitidas.',
        selector: '.admin-notication',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]

export const tourMagistrado = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en e.Justicia Administrativa Guerrero.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Sentencias',
        content: 'Esta es la bandeja donde podrás firmar las setencias que has dictado.',
        selector: '.manager-sentences',
        orientation: Orientation.Right
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]